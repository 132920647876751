import React, { useState } from "react";
import {
  PencilSquareIcon,
} from "@heroicons/react/24/outline";

import CommonCard from "components/CommonCard";
import DetailsButton from "components/DetailsButton";
import TruncatorText from "components/TruncateText";

import { convertTimestamp } from "lib/convertors";

import langL10n from "./lang-l10n";
import { DashboardNavigationMenuPrintIcon, DashboardServiceCommercialAutoIcon, DashboardServiceNewTabIcon } from "../Icons/index";

const VehicleSection = ({ vehicle, locale = "en-US" }) => {
  return (
    <>
      <div className="flex items-center py-2">
        <h3 className="text-lg text-primary">
          {langL10n.VehicleText[locale]} #{vehicle?.id}:
        </h3>
      </div>

      <div className="flex gap-x-14">
        <div className="grid grid-cols-12 w-[50%] gap-2">
          {/* Column 1 */}
          <div className="col-span-3">
            <div className="font-bold text-right mb-1 text-xs">
              {langL10n.MakeModelYearText[locale]}
            </div>
            <div className="font-bold text-right mb-1 text-xs">
              {langL10n.BodyTypeText[locale]}
            </div>
            <div className="font-bold text-right mb-6 text-xs">
              {langL10n.DriveText[locale]}
            </div>
            <div className="font-bold text-right mb-24 text-xs">
              {langL10n.SymbolText[locale]}
            </div>
          </div>
          <div className="col-span-3">
            <div className="text-left mb-1 text-xs">
              <TruncatorText
                fieldName={langL10n.MakeModelYearText[locale]}
                cutOff={12}
              >
                {vehicle?.makeModelYear}
              </TruncatorText>
            </div>
            <div className="text-left mb-1 text-xs">
              <TruncatorText
                fieldName={langL10n.BodyTypeText[locale]}
                cutOff={10}
              >
                {vehicle?.bodyType}
              </TruncatorText>
            </div>
            <div className="text-left mb-6 text-xs">
              <TruncatorText fieldName={langL10n.DriveText[locale]} cutOff={5}>
                {vehicle?.drive}
              </TruncatorText>
            </div>
            <div className="text-left mb-24 text-xs">
              <TruncatorText fieldName={langL10n.SymbolText[locale]} cutOff={4}>
                {vehicle?.symbol}
              </TruncatorText>
            </div>
          </div>

          {/* Column 2 */}
          <div className="col-span-3">
            <div className="font-bold text-right mb-1 text-xs">
              {langL10n.VinNumberText[locale]}
            </div>
            <div className="font-bold text-right mb-1 text-xs">
              {langL10n.CompVehNumberText[locale]}
            </div>
            <div className="font-bold text-right mb-1 text-xs">
              {langL10n.FleetIdText[locale]}
            </div>
            <div className="font-bold text-right mb-1 text-xs">
              {langL10n.AnnualMilesText[locale]}
            </div>
            <div className="font-bold text-right mb-1 text-xs">
              {langL10n.CommuteMilesText[locale]}
            </div>
            <div className="font-bold text-right mb-1 text-xs">
              {langL10n.UseText[locale]}
            </div>
            <div className="font-bold text-right mb-1 text-xs">
              {langL10n.OdometerDateText[locale]}
            </div>
            <div className="font-bold text-right mb-1 text-xs">
              {langL10n.ZipCodeText[locale]}
            </div>
          </div>
          <div className="col-span-3">
            <div className="text-left mb-1 text-xs">
              <TruncatorText
                fieldName={langL10n.VinNumberText[locale]}
                cutOff={17}
              >
                {vehicle?.vin}
              </TruncatorText>
            </div>
            <div className="text-left mb-1 text-xs">
              <TruncatorText
                fieldName={langL10n.CompVehNumberText[locale]}
                cutOff={2}
              >
                {vehicle?.compVehNumber}
              </TruncatorText>
            </div>
            <div className="text-left mb-1 text-xs">
              <TruncatorText
                fieldName={langL10n.FleetIdText[locale]}
                cutOff={4}
              >
                {vehicle?.fleetId}
              </TruncatorText>
            </div>
            <div className="text-left mb-1 text-xs">
              <TruncatorText
                fieldName={langL10n.AnnualMilesText[locale]}
                cutOff={5}
              >
                {vehicle?.annualMiles}
              </TruncatorText>
            </div>
            <div className="text-left mb-1 text-xs">
              <TruncatorText
                fieldName={langL10n.CommuteMilesText[locale]}
                cutOff={4}
              >
                {vehicle?.commuteMiles}
              </TruncatorText>
            </div>
            <div className="text-left mb-1 text-xs">
              <TruncatorText fieldName={langL10n.UseText[locale]} cutOff={4}>
                {vehicle?.use}
              </TruncatorText>
            </div>
            <div className="text-left mb-1 text-xs">
              {convertTimestamp(vehicle?.odometerDate)}
            </div>
            <div className="text-left mb-1 text-xs">
              <TruncatorText
                fieldName={langL10n.ZipCodeText[locale]}
                cutOff={5}
              >
                {vehicle?.zipCode}
              </TruncatorText>
            </div>
          </div>
          <div className="col-span-3">
            <div className="font-bold text-right mb-1 text-xs">
              {langL10n.AssignedDriverText[locale]}
            </div>
            <div className="font-bold text-right mb-1 text-xs">
              {langL10n.DiscountsText[locale]}
            </div>
            <div className="font-bold text-right mb-6 text-xs">
              {langL10n.AdditionalInterestsText[locale]}
            </div>
          </div>
          <div className="col-span-5">
            <div className="text-left mb-1 text-xs">
              <TruncatorText
                fieldName={langL10n.AssignedDriverText[locale]}
                cutOff={5}
              >
                {vehicle?.assignedDriver}
              </TruncatorText>
            </div>
            <div className="text-left mb-1 text-xs">
              <TruncatorText
                fieldName={langL10n.DiscountsText[locale]}
                cutOff={10}
              >
                {vehicle?.discounts}
              </TruncatorText>
            </div>
            <div className="text-left mb-6 text-xs">
              <TruncatorText
                fieldName={langL10n.AdditionalInterestsText[locale]}
                cutOff={5}
              >
                {vehicle?.additionalInterests}
              </TruncatorText>
            </div>
          </div>
        </div>

        {/* Description Section */}
        <div className="grid grid-cols-12 gap-4 w-[50%] pb-4 border-b">
          <div className="col-span-5">
            <div className="font-bold mb-2 text-xs">
              {langL10n.DescriptionText[locale]}
            </div>
            <div className="text-xs mb-1">
              {langL10n.BodilyInjuryText[locale]}
            </div>
            <div className="text-xs mb-1">
              {langL10n.PropertyDamageText[locale]}
            </div>
            <div className="text-xs mb-1">
              {langL10n.UninsuredMotoristLiabilityText[locale]}
            </div>
            <div className="text-xs mb-1">
              {langL10n.UnderInsuredMotoristLiabilityText[locale]}
            </div>
            <div className="text-xs mb-1">
              {langL10n.PersonalInjuryProtectionText[locale]}
            </div>
            <div className="text-xs mb-1">
              {langL10n.ComprehensiveFullGlassText[locale]}
            </div>
            <div className="text-xs mb-1">{langL10n.CollisionText[locale]}</div>
            <div className="text-xs mb-1">
              {langL10n.RentalReimbursementText[locale]}
            </div>
            <div className="text-xs mb-1">{langL10n.GlassText[locale]}</div>
            <div className="text-xs mb-1">
              {langL10n.RoadsideAssistanceCoverageText[locale]}
            </div>
            <div className="text-xs mb-1">
              {langL10n.MultiCarDiscountText[locale]}
            </div>
          </div>

          <div className="col-span-3 text-right">
            <div className="font-bold mb-2 text-xs">
              {langL10n.LimitsText[locale]}
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.BodilyInjuryText[locale]}
                cutOff={20}
              >
                {vehicle?.description.bodilyInjury.limits}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.PropertyDamageText[locale]}
                cutOff={20}
              >
                {vehicle?.description.propertyDamage.limits}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.UninsuredMotoristLiabilityText[locale]}
                cutOff={20}
              >
                {vehicle?.description.uninsuredMotoristLiability.limits}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.UnderInsuredMotoristLiabilityText[locale]}
                cutOff={20}
              >
                {vehicle?.description.underinsuredMotoristLiability.limits}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.PersonalInjuryProtectionText[locale]}
                cutOff={20}
              >
                {vehicle?.description.personalInjuryProtection.limits}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.ComprehensiveFullGlassText[locale]}
                cutOff={20}
              >
                {vehicle?.description.comprehensive.limits}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.CollisionText[locale]}
                cutOff={20}
              >
                {vehicle?.description.collision.limits}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.RentalReimbursementText[locale]}
                cutOff={20}
              >
                {vehicle?.description.rentalReimbursement.limits}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText fieldName={langL10n.GlassText[locale]} cutOff={5}>
                {vehicle?.description.glass.limits}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.RoadsideAssistanceCoverageText[locale]}
                cutOff={20}
              >
                {vehicle?.description.roadsideAssistanceCoverage.limits}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.MultiCarDiscountText[locale]}
                cutOff={20}
              >
                {vehicle?.description.multiCarDiscount.limits}
              </TruncatorText>
            </div>
          </div>

          <div className="col-span-2 text-right">
            <div className="font-bold mb-2 text-xs">
              {langL10n.DeductibleText[locale]}
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.BodilyInjuryText[locale]}
                cutOff={5}
              >
                {vehicle?.description.bodilyInjury.deductible}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.PropertyDamageText[locale]}
                cutOff={5}
              >
                {vehicle?.description.propertyDamage.deductible}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.UninsuredMotoristLiabilityText[locale]}
                cutOff={5}
              >
                {vehicle?.description.uninsuredMotoristLiability.deductible}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.UnderInsuredMotoristLiabilityText[locale]}
                cutOff={5}
              >
                {vehicle?.description.underinsuredMotoristLiability.deductible}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.PersonalInjuryProtectionText[locale]}
                cutOff={5}
              >
                {vehicle?.description.personalInjuryProtection.deductible}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.ComprehensiveFullGlassText[locale]}
                cutOff={5}
              >
                {vehicle?.description.comprehensive.deductible}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.CollisionText[locale]}
                cutOff={5}
              >
                {vehicle?.description.collision.deductible}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.RentalReimbursementText[locale]}
                cutOff={5}
              >
                {vehicle?.description.rentalReimbursement.deductible}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText fieldName={langL10n.GlassText[locale]} cutOff={15}>
                {vehicle?.description.glass.deductible}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.RoadsideAssistanceCoverageText[locale]}
                cutOff={5}
              >
                {vehicle?.description.roadsideAssistanceCoverage.deductible}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.MultiCarDiscountText[locale]}
                cutOff={5}
              >
                {vehicle?.description.multiCarDiscount.deductible}
              </TruncatorText>
            </div>
          </div>

          <div className="col-span-2 text-right">
            <div className="font-bold mb-2 text-xs">
              {langL10n.PremiumText[locale]}
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.BodilyInjuryText[locale]}
                cutOff={5}
              >
                {vehicle?.description.bodilyInjury.premium}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.PropertyDamageText[locale]}
                cutOff={5}
              >
                {vehicle?.description.propertyDamage.premium}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.UninsuredMotoristLiabilityText[locale]}
                cutOff={5}
              >
                {vehicle?.description.uninsuredMotoristLiability.premium}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.UnderInsuredMotoristLiabilityText[locale]}
                cutOff={15}
              >
                {vehicle?.description.underinsuredMotoristLiability.premium}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.PersonalInjuryProtectionText[locale]}
                cutOff={5}
              >
                {vehicle?.description.personalInjuryProtection.premium}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.ComprehensiveFullGlassText[locale]}
                cutOff={5}
              >
                {vehicle?.description.comprehensive.premium}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.CollisionText[locale]}
                cutOff={5}
              >
                {vehicle?.description.collision.premium}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.RentalReimbursementText[locale]}
                cutOff={5}
              >
                {vehicle?.description.rentalReimbursement.premium}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText fieldName={langL10n.GlassText[locale]} cutOff={15}>
                {vehicle?.description.glass.premium}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.RoadsideAssistanceCoverageText[locale]}
                cutOff={5}
              >
                {vehicle?.description.roadsideAssistanceCoverage.premium}
              </TruncatorText>
            </div>
            <div className="text-xs mb-1 flex items-center justify-end">
              <TruncatorText
                fieldName={langL10n.MultiCarDiscountText[locale]}
                cutOff={5}
              >
                {vehicle?.description.multiCarDiscount.premium}
              </TruncatorText>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-2 mr-4 text-right text-xs font-bold flex gap-6 justify-end">
        <span>{langL10n.TotalText[locale]}: </span>{" "}
        {vehicle?.totalPremium?.toFixed(2)}
      </div>
    </>
  );
};

const DriverSection = ({ driver, locale = "en-US" }) => {
  return (
    <div className="flex flex-col w-[50%]">
      {/* Column 1 */}
      <div className="grid grid-cols-12 gap-2 py-2">
        <div className="col-span-9">
          <h3 className="text-lg text-primary">
            {langL10n.DriverText[locale]} {driver?.id}: {driver?.name}
          </h3>
        </div>
        <div className="col-span-3">
          <button className="w-6 h-6relative right-1">
            <PencilSquareIcon className="w-6 h-6 text-lightGray hover:text-primary mr-2" />
          </button>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-3">
          <div className="text-right mb-1 text-xs">
            {langL10n.CompanyDriverText[locale]}
          </div>
          <div className="text-right mb-1 text-xs">
            {langL10n.MaritalStatusText[locale]}
          </div>
          <div className="text-right mb-1 text-xs">
            {langL10n.GenderText[locale]}
          </div>
          <div className="text-right mb-1 text-xs">
            {langL10n.RelationshipStatusText[locale]}
          </div>
          <div className="text-right mb-1 text-xs">
            {langL10n.DateOfBirthText[locale]}
          </div>
          <div className="text-right mb-1 text-xs">
            {langL10n.AgeText[locale]}
          </div>
          <div className="text-right mb-1 text-xs">
            {langL10n.SSNText[locale]}
          </div>
          <div className="text-right mb-1 text-xs">
            {langL10n.DriversLicenseText[locale]}
          </div>
          <div className="text-right mb-1 text-xs">
            {langL10n.LicensedStateText[locale]}
          </div>
          <div className="text-right mb-1 text-xs">
            {langL10n.OccupationText[locale]}
          </div>
          <div className="text-right mb-1 text-xs">
            {langL10n.EducationText[locale]}
          </div>
        </div>
        <div className="col-span-2">
          <div className="font-bold text-left mb-1 text-xs">
            <TruncatorText
              fieldName={langL10n.CompanyDriverText[locale]}
              cutOff={1}
            >
              {driver?.companyDriver}
            </TruncatorText>
          </div>
          <div className="font-bold text-left mb-1 text-xs">
            <TruncatorText
              fieldName={langL10n.MaritalStatusText[locale]}
              cutOff={10}
            >
              {driver?.maritalStatus}
            </TruncatorText>
          </div>
          <div className="font-bold text-left mb-1 text-xs">
            <TruncatorText fieldName={langL10n.GenderText[locale]} cutOff={4}>
              {driver?.gender}
            </TruncatorText>
          </div>
          <div className="font-bold text-left mb-1 text-xs">
            <TruncatorText
              fieldName={langL10n.RelationshipStatusText[locale]}
              cutOff={8}
            >
              {driver?.relationshipStatus}
            </TruncatorText>
          </div>
          <div className="font-bold text-left mb-1 text-xs">
            {convertTimestamp(driver?.dateOfBirth)}
          </div>
          <div className="font-bold text-left mb-1 text-xs">{driver?.age}</div>
          <div className="font-bold text-left mb-1 text-xs">
            <TruncatorText fieldName={langL10n.SSNText[locale]} cutOff={11}>
              {driver?.ssn}
            </TruncatorText>
          </div>
          <div className="font-bold text-left mb-1 text-xs">
            <TruncatorText
              fieldName={langL10n.DriversLicenseText[locale]}
              cutOff={10}
            >
              {driver?.driversLicense}
            </TruncatorText>
          </div>
          <div className="font-bold text-left mb-1 text-xs">
            <TruncatorText
              fieldName={langL10n.LicensedStateText[locale]}
              cutOff={2}
            >
              {driver?.licensedState}
            </TruncatorText>
          </div>
          <div className="font-bold text-left mb-1 text-xs">
            <TruncatorText
              fieldName={langL10n.OccupationText[locale]}
              cutOff={5}
            >
              {driver?.occupation}
            </TruncatorText>
          </div>
          <div className="font-bold text-left mb-1 text-xs">
            <TruncatorText
              fieldName={langL10n.EducationText[locale]}
              cutOff={5}
            >
              {driver?.education}
            </TruncatorText>
          </div>
        </div>

        {/* Column 2 */}
        <div className="col-span-4">
          <div className="text-right mb-1 text-xs">
            {langL10n.DriverTrainingText[locale]}
          </div>
          <div className="text-right mb-1 text-xs">
            {langL10n.DefensiveDriversCourseText[locale]}
          </div>
          <div className="text-right mb-1 text-xs">
            {langL10n.GoodDriverText[locale]}
          </div>
          <div className="text-right mb-1 text-xs">
            {langL10n.GoodStudentText[locale]}
          </div>
          <div className="text-right mb-1 text-xs">
            {langL10n.StudentOver100MilesText[locale]}
          </div>
          <div className="text-right mb-1 text-xs">&nbsp;</div>
          <div className="text-right font-bold mb-1 text-xs">
            {langL10n.OperatorStatusText[locale]}
          </div>
          <div className="text-right mb-1 text-xs">
            {langL10n.SR22Text[locale]}
          </div>
          <div className="text-right mb-1 text-xs">
            {langL10n.StateText[locale]}
          </div>
          <div className="text-right mb-1 text-xs">
            {langL10n.ProofRequiredUntilText[locale]}
          </div>
          <div className="text-right mb-1 text-xs">
            {langL10n.ReasonText[locale]}
          </div>
        </div>
        <div className="col-span-3">
          <div className="font-bold text-left mb-1 text-xs">
            {driver?.driverTraining ? "Yes" : "No"}
          </div>
          <div className="font-bold text-left mb-1 text-xs">
            {driver?.defensiveDriversCourse ? "Yes" : "No"}
          </div>
          <div className="font-bold text-left mb-1 text-xs">
            {driver?.goodDriver ? "Yes" : "No"}
          </div>
          <div className="font-bold text-left mb-1 text-xs">
            {driver?.goodStudent ? "Yes" : "No"}
          </div>
          <div className="font-bold text-left mb-1 text-xs">
            {driver?.studentOver100Miles ? "Yes" : "No"}
          </div>
          <div className="font-bold text-left mb-1 text-xs">&nbsp;</div>
          <div className="font-bold text-left mb-1 text-xs">&nbsp;</div>
          <div className="font-bold text-left mb-1 text-xs">
            {driver?.sr22 ? "Yes" : "No"}
          </div>
          <div className="font-bold text-left mb-1 text-xs">
            <TruncatorText
              fieldName={langL10n.OperatorStatusText[locale]}
              cutOff={5}
            >
              {driver?.operatorStatus}
            </TruncatorText>
          </div>
          <div className="font-bold text-left mb-1 text-xs">
            <TruncatorText
              fieldName={langL10n.ProofRequiredUntilText[locale]}
              cutOff={5}
            >
              {driver?.proofRequiredUntil}
            </TruncatorText>
          </div>
          <div className="font-bold text-left mb-1 text-xs">
            <TruncatorText fieldName={langL10n.ReasonText[locale]} cutOff={5}>
              {driver?.reason}
            </TruncatorText>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function CommercialAutoPolicy({
  commercialAutoPolicy,
  locale = "en-US",
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className="flex flex-1 mr-2">
      <CommonCard
        icon={<DashboardServiceCommercialAutoIcon className="w-6 h-6 text-primary mr-2" />}
        title={
          <div>
            {langL10n.CommercialAutoText[locale]}
            <span className="mr-6"></span>
            {langL10n.PolicyNumberText[locale]}:{" "}
            {commercialAutoPolicy?.policyNumber}
          </div>
        }
        buttons={
          <div className="flex gap-2">
            <button className="w-6 h-6 mr-2 relative right-1">
              <DashboardNavigationMenuPrintIcon className="w-6 h-6 text-lightGray hover:text-primary mr-2" />
            </button>
            <button className="w-6 h-6 mr-2 relative right-1">
              <DashboardServiceNewTabIcon className="w-6 h-6 text-lightGray hover:text-primary mr-2" />
            </button>
            <button className="w-6 h-6 mr-2 relative right-1">
              <PencilSquareIcon className="w-6 h-6 text-lightGray hover:text-primary mr-2" />
            </button>
          </div>
        }
      >
        <div
          className={`flex flex-col pr-2 ${isExpanded ? "border-b border-gray-300" : ""}`}
        >
          <div className="flex gap-5">
            <div className="flex gap-2">
              <div className="flex flex-col">
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.CarrierText[locale]}
                </div>
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.AgentCodeText[locale]}
                </div>
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.AccountNumberText[locale]}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.CarrierText[locale]}
                    cutOff={10}
                  >
                    {commercialAutoPolicy?.carrier}
                  </TruncatorText>
                </div>
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.AgentCodeText[locale]}
                    cutOff={5}
                  >
                    {commercialAutoPolicy?.agentCode}
                  </TruncatorText>
                </div>
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.AccountNumberText[locale]}
                    cutOff={10}
                  >
                    {commercialAutoPolicy?.accountNumber}
                  </TruncatorText>
                </div>
              </div>
            </div>
            <div className="flex gap-2">
              <div className="flex flex-col">
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.TermText[locale]}
                </div>
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.EffectiveDateText[locale]}
                </div>
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.ExpirationDateText[locale]}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.TermText[locale]}
                    cutOff={10}
                  >
                    {commercialAutoPolicy?.term}
                  </TruncatorText>
                </div>
                <div className="text-left mb-2 text-xs">
                  {convertTimestamp(commercialAutoPolicy?.effectiveDate)}
                </div>
                <div className="text-left mb-2 text-xs">
                  {convertTimestamp(commercialAutoPolicy?.expirationDate)}
                </div>
              </div>
            </div>
            <div className="flex gap-2">
              <div className="flex flex-col">
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.StatusText[locale]}
                </div>
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.StatusDateText[locale]}
                </div>
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.InceptionDateText[locale]}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.StatusText[locale]}
                    cutOff={6}
                  >
                    {commercialAutoPolicy?.status}
                  </TruncatorText>
                </div>
                <div className="text-left mb-2 text-xs">
                  {convertTimestamp(commercialAutoPolicy?.statusDate)}
                </div>
                <div className="text-left mb-2 text-xs">
                  {convertTimestamp(commercialAutoPolicy?.inceptionDate)}
                </div>
              </div>
            </div>
            <div className="flex gap-2">
              <div className="flex flex-col">
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.CurrentRateText[locale]}
                </div>
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.AnnualizedRateText[locale]}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.CurrentRateText[locale]}
                    cutOff={6}
                  >
                    {commercialAutoPolicy?.currentRate}
                  </TruncatorText>
                </div>
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.AnnualizedRateText[locale]}
                    cutOff={6}
                  >
                    {commercialAutoPolicy?.annualizedRate}
                  </TruncatorText>
                </div>
              </div>
            </div>
            <div className="flex gap-2">
              <div className="flex flex-col">
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.GaragingAddressText[locale]}
                </div>
                <div className="text-right mb-2 text-xs">
                  {langL10n.CityStateText[locale]}
                </div>
                <div className="text-right mb-2 text-xs">
                  {langL10n.ZipCodeText[locale]}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.GaragingAddressText[locale]}
                    cutOff={15}
                  >
                    {commercialAutoPolicy?.garagingAddress?.addressLine1}
                  </TruncatorText>
                </div>
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.CityStateText[locale]}
                    cutOff={15}
                  >
                    {commercialAutoPolicy?.garagingAddress?.cityAndState}
                  </TruncatorText>
                </div>
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.ZipCodeText[locale]}
                    cutOff={5}
                  >
                    {commercialAutoPolicy?.garagingAddress?.zipCode}
                  </TruncatorText>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-end py-4">
            <DetailsButton
              locale={locale}
              isExpanded={isExpanded}
              toggleExpand={toggleExpand}
            />
          </div>
        </div>

        {isExpanded && (
          <div className="px-2">
            {commercialAutoPolicy?.vehicles.map((vehicle) => {
              return <VehicleSection key={vehicle?.id} vehicle={vehicle} />;
            })}

            {/* General Policy Information */}
            <div className="flex flex-col mt-4 pt-2 border-t">
              <div className="flex gap-2 py-2">
                <h3 className="text-lg text-primary">
                  {langL10n.GeneralPolicyInformationText[locale]}
                </h3>
                <button className="w-6 h-6relative right-1">
                  <PencilSquareIcon className="w-6 h-6 text-lightGray hover:text-primary mr-2" />
                </button>
              </div>

              <div className="flex gap-x-14">
                <div className="grid grid-cols-12 w-[50%] gap-2">
                  {/* Column 1 */}
                  <div className="col-span-3">
                    <div className="font-bold text-right mb-2 text-xs">
                      {langL10n.CompanyText[locale]}
                    </div>
                    <div className="font-bold text-right mb-2 text-xs">
                      {langL10n.ProgramText[locale]}
                    </div>
                    <div className="font-bold text-right mb-2 text-xs">
                      {langL10n.AgentCodeText[locale]}
                    </div>
                    <div className="font-bold text-right mb-2 text-xs">
                      {langL10n.PolicyNumberText[locale]}
                    </div>
                    <div className="font-bold text-right mb-2 text-xs">
                      {langL10n.AccountNumberText[locale]}
                    </div>
                  </div>
                  <div className="col-span-3">
                    <div className="text-left mb-2 text-xs">
                      <TruncatorText
                        fieldName={langL10n.CompanyText[locale]}
                        cutOff={17}
                      >
                        {commercialAutoPolicy?.generalPolicyInfo?.company}
                      </TruncatorText>
                    </div>
                    <div className="text-left mb-2 text-xs">
                      <TruncatorText
                        fieldName={langL10n.ProgramText[locale]}
                        cutOff={7}
                      >
                        {commercialAutoPolicy?.generalPolicyInfo?.program}
                      </TruncatorText>
                    </div>
                    <div className="text-left mb-2 text-xs">
                      <TruncatorText
                        fieldName={langL10n.AgentCodeText[locale]}
                        cutOff={6}
                      >
                        {commercialAutoPolicy?.generalPolicyInfo?.agentCode}
                      </TruncatorText>
                    </div>
                    <div className="text-left mb-2 text-xs">
                      <TruncatorText
                        fieldName={langL10n.PolicyNumberText[locale]}
                        cutOff={13}
                      >
                        {commercialAutoPolicy?.generalPolicyInfo?.policyNumber}
                      </TruncatorText>
                    </div>
                    <div className="text-left mb-2 text-xs">
                      <TruncatorText
                        fieldName={langL10n.AccountNumberText[locale]}
                        cutOff={10}
                      >
                        {commercialAutoPolicy?.generalPolicyInfo?.accountNumber}
                      </TruncatorText>
                    </div>
                  </div>

                  {/* Column 2 */}
                  <div className="col-span-3">
                    <div className="font-bold text-right mb-2 text-xs">
                      {langL10n.TermText[locale]}
                    </div>
                    <div className="font-bold text-right mb-2 text-xs">
                      {langL10n.EffectiveDateText[locale]}
                    </div>
                    <div className="font-bold text-right mb-2 text-xs">
                      {langL10n.ExpirationDateText[locale]}
                    </div>
                    <div className="font-bold text-right mb-2 text-xs">
                      {langL10n.StatusText[locale]}
                    </div>
                    <div className="font-bold text-right mb-2 text-xs">
                      {langL10n.StatusDateText[locale]}
                    </div>
                    <div className="font-bold text-right mb-2 text-xs">
                      {langL10n.InceptionDateText[locale]}
                    </div>
                    <div className="font-bold text-right mb-2 text-xs">
                      {langL10n.CurrentRateText[locale]}
                    </div>
                    <div className="font-bold text-right mb-2 text-xs">
                      {langL10n.AnnualizedRateText[locale]}
                    </div>
                  </div>
                  <div className="col-span-3">
                    <div className="text-left mb-2 text-xs">
                      <TruncatorText
                        fieldName={langL10n.TermText[locale]}
                        cutOff={10}
                      >
                        {commercialAutoPolicy?.generalPolicyInfo?.term}
                      </TruncatorText>
                    </div>
                    <div className="text-left mb-2 text-xs">
                      <TruncatorText
                        fieldName={langL10n.EffectiveDateText[locale]}
                        cutOff={10}
                      >
                        {convertTimestamp(
                          commercialAutoPolicy?.generalPolicyInfo.effectiveDate
                        )}
                      </TruncatorText>
                    </div>
                    <div className="text-left mb-2 text-xs">
                      <TruncatorText
                        fieldName={langL10n.ExpirationDateText[locale]}
                        cutOff={10}
                      >
                        {convertTimestamp(
                          commercialAutoPolicy?.generalPolicyInfo.expirationDate
                        )}
                      </TruncatorText>
                    </div>
                    <div className="text-left mb-2 text-xs">
                      <TruncatorText
                        fieldName={langL10n.StatusText[locale]}
                        cutOff={7}
                      >
                        {commercialAutoPolicy?.generalPolicyInfo.status}
                      </TruncatorText>
                    </div>
                    <div className="text-left mb-2 text-xs">
                      <TruncatorText
                        fieldName={langL10n.StatusDateText[locale]}
                        cutOff={10}
                      >
                        {convertTimestamp(
                          commercialAutoPolicy?.generalPolicyInfo.statusDate
                        )}
                      </TruncatorText>
                    </div>
                    <div className="text-left mb-2 text-xs">
                      <TruncatorText
                        fieldName={langL10n.InceptionDateText[locale]}
                        cutOff={10}
                      >
                        {convertTimestamp(
                          commercialAutoPolicy?.generalPolicyInfo.inceptionDate
                        )}
                      </TruncatorText>
                    </div>
                    <div className="text-left mb-2 text-xs">
                      <TruncatorText
                        fieldName={langL10n.CurrentRateText[locale]}
                        cutOff={7}
                      >
                        {commercialAutoPolicy?.generalPolicyInfo.currentRate?.toFixed(
                          1
                        )}
                      </TruncatorText>
                    </div>
                    <div className="text-left mb-2 text-xs">
                      <TruncatorText
                        fieldName={langL10n.AnnualizedRateText[locale]}
                        cutOff={7}
                      >
                        {commercialAutoPolicy?.generalPolicyInfo.annualizedRate?.toFixed(
                          1
                        )}
                      </TruncatorText>
                    </div>
                  </div>
                </div>

                {/* Garaging Address Section */}
                <div className="grid grid-cols-12 w-[50%] gap-2">
                  {/* Column 1 */}
                  <div className="col-span-5">
                    <div className="font-bold text-right mb-2 text-xs">
                      {langL10n.GaragingAddressIfDifferentText[locale]}
                    </div>
                    <div className="text-right mb-2 text-xs">
                      {langL10n.StreetAddressText[locale]}
                    </div>
                    <div className="text-right mb-2 text-xs">
                      {langL10n.Address2Text[locale]}
                    </div>
                    <div className="text-right mb-2 text-xs">
                      {langL10n.CityText[locale]}
                    </div>
                    <div className="text-right mb-2 text-xs">
                      {langL10n.StateText[locale]}
                    </div>
                    <div className="text-right mb-2 text-xs">
                      {langL10n.ZipText[locale]}
                    </div>
                  </div>
                  <div className="col-span-4">
                    <div className="text-right mb-2 text-xs">&nbsp;</div>

                    <div className="font-bold text-left mb-2 text-xs">
                      <TruncatorText
                        fieldName={langL10n.StreetAddressText[locale]}
                        cutOff={17}
                      >
                        {
                          commercialAutoPolicy?.generalPolicyInfo?.garagingAddress.addressLine1
                        }
                      </TruncatorText>
                    </div>
                    <div className="font-bold text-left mb-2 text-xs">
                      <TruncatorText
                        fieldName={langL10n.Address2Text[locale]}
                        cutOff={10}
                      >
                        {
                          commercialAutoPolicy?.generalPolicyInfo?.garagingAddress.addressLine2
                        }
                      </TruncatorText>
                    </div>
                    <div className="font-bold text-left mb-2 text-xs">
                      <TruncatorText
                        fieldName={langL10n.CityText[locale]}
                        cutOff={10}
                      >
                        {
                          commercialAutoPolicy?.generalPolicyInfo
                            .garagingAddress.city
                        }
                      </TruncatorText>
                    </div>
                    <div className="font-bold text-left mb-2 text-xs">
                      <TruncatorText
                        fieldName={langL10n.StateText[locale]}
                        cutOff={2}
                      >
                        {
                          commercialAutoPolicy?.generalPolicyInfo
                            .garagingAddress.state
                        }
                      </TruncatorText>
                    </div>
                    <div className="font-bold text-left mb-2 text-xs">
                      <TruncatorText
                        fieldName={langL10n.ZipText[locale]}
                        cutOff={5}
                      >
                        {
                          commercialAutoPolicy?.generalPolicyInfo
                            .garagingAddress.zip
                        }
                      </TruncatorText>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Driver Information */}
            <div className="mt-4 border-t pt-2">
              <div className="flex flex-col">
                <div className="flex gap-x-14 w-full">
                  {commercialAutoPolicy.drivers.map((driver) => {
                    return <DriverSection key={driver?.id} driver={driver} />;
                  })}
                </div>
              </div>
            </div>

            <div className="mt-4 text-right">
              <button className="bg-primary text-white px-7 py-1 rounded">
                {langL10n.AddDriverText[locale]}
              </button>
            </div>

            <div className="flex flex-col mt-4 pt-2 border-t">
              <div className="flex flex-col w-[50%]">
                <div className="flex gap-2 py-2">
                  <h3 className="text-lg text-primary">
                    {langL10n.AdditionalInterestsText[locale]}
                  </h3>
                </div>

                <div className="grid grid-cols-12 pb-2">
                  <div className="col-span-2">
                    <span className="text-xs font-semibold">
                      {langL10n.TypeText[locale]}
                    </span>
                  </div>
                  <div className="col-span-5">
                    <span className="text-xs font-semibold">
                      {langL10n.NameText[locale]}
                    </span>
                  </div>
                  <div className="col-span-4">
                    <span className="text-xs font-semibold">
                      {langL10n.LoanNumberText[locale]}
                    </span>
                  </div>
                  <div className="col-span-2">
                    <span className="text-xs">
                      <TruncatorText
                        fieldName={langL10n.AdditionalInterestsText[locale]}
                        cutOff={5}
                      >
                        {commercialAutoPolicy?.additionalInterests.type}
                      </TruncatorText>
                    </span>
                  </div>
                  <div className="col-span-5">
                    <span className="text-xs">
                      <TruncatorText
                        fieldName={langL10n.NameText[locale]}
                        cutOff={15}
                      >
                        {commercialAutoPolicy?.additionalInterests.name}
                      </TruncatorText>
                    </span>
                  </div>
                  <div className="col-span-4">
                    <span className="text-xs">
                      <TruncatorText
                        fieldName={langL10n.LoanNumberText[locale]}
                        cutOff={15}
                      >
                        {commercialAutoPolicy?.additionalInterests.loanNumber}
                      </TruncatorText>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </CommonCard>
    </div>
  );
}
