import React from "react";

export default function DashboardNavigationMenuAnalyticsIcon({ className, viewBox, fill }) {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox={viewBox ? `${viewBox}` : "0 0 100 100"}
            className={className ? `${className}` : "w-6 h-6 text-primary mr-2"}
            fill={fill ? `${fill}` : "currentColor"}
        >
            <defs>
                <style>
                </style>
            </defs>
            <path d="M99.4,91.2h0c0-.4-.2-.6-.3-.7,0,0,0,0,0,0l-6.1-6.1c-.8-.8-2-.8-2.8,0s-.8,2,0,2.8l2.6,2.6H10.4v-8.9c.1,0,.2-.2.2-.3l21.3-20c1.3.8,2.7,1.2,4,1.2s4-.9,5.4-2.3l10.7,6.1c-.2.7-.3,1.3-.3,2.1,0,4.2,3.5,7.6,7.7,7.6s7.7-3.4,7.7-7.6-.3-3.4-1-3.9c0,0-.1-.1-.2-.2l17-18.3c1.2.6,2.4.9,3.6.9,4.2,0,7.7-3.5,7.7-7.6s-3.5-7.6-7.7-7.6-7.7,3.4-7.7,7.6.2,3.3.9,3.9c0,0,.2.1.2.2l-17,18.3c-1.2-.6-2.3-.9-3.5-.9-2,0-4,.8-5.4,2.2l-10.7-6.1c.2-.6.3-1.2.3-2,0-4.2-3.5-7.6-7.7-7.6s-7.7,3.4-7.7,7.6.2,3.2.8,3.6c0,0,0,0,.1,0l-18.8,17.7V7.2l2.6,2.6c.5.5,1.1.5,1.4.5.5,0,1.1-.2,1.4-.5.8-.8.8-2,0-2.8L9.9,1c-.2-.2-.4-.3-.6-.4h-.2c0,0-.1,0-.1,0-.2,0-.7-.2-1.1,0-.2,0-.3.2-.4.3,0,0,0,0,0,0L1,7c-.8.8-.8,2,0,2.8s2,.8,2.8,0l2.6-2.6v84.5c0,1.1.9,2,2,2h84.4l-2.6,2.6c-.8.8-.8,2,0,2.8.5.5,1.1.5,1.4.5.5,0,1.1-.2,1.4-.5l6-6c.2-.2.3-.4.4-.6v-.2c0,0,0-.1,0-.1,0,0,0-.2,0-.4s0-.4,0-.6ZM32.4,54.1c0-2,1.7-3.7,3.7-3.7s3.7,1.7,3.7,3.7-1.7,3.7-3.7,3.7-3.7-1.7-3.7-3.7ZM82.9,38.5c0-2,1.7-3.7,3.7-3.7s3.7,1.7,3.7,3.7-1.7,3.7-3.7,3.7-3.7-1.7-3.7-3.7ZM55.9,67.6c0-2,1.7-3.7,3.7-3.7s3.7,1.7,3.7,3.7-1.7,3.7-3.7,3.7-3.7-1.7-3.7-3.7Z" />
        </svg>
    );
}