import React from "react";

export default function DashboardNavigationMenuEngageIcon({ className, viewBox, fill }) {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox={viewBox ? `${viewBox}` : "0 0 100 100"}
            className={className ? `${className}` : "w-6 h-6 text-primary mr-2"}
            fill={fill ? `${fill}` : "currentColor"}
        >
            <defs>
                <style>
                </style>
            </defs>
            <path d="M98.1,14.4h-8.6l1.7-1.7c.8-.8.8-2.1,0-2.9-.8-.8-2.2-.8-2.9,0l-1.7,1.7V2.9c0-1.1-.9-2.1-2.1-2.1s-2.1.9-2.1,2.1v12.7l-3,3v-8.6c0-1.1-.9-2.1-2.1-2.1s-2.1.9-2.1,2.1v12.7l-3.6,3.6c-7.7-7-17.7-10.8-28-10.8C20.6,15.5,1.8,34.3,1.8,57.3s18.8,41.8,41.8,41.8,41.8-18.8,41.8-41.8-3.8-20.3-10.8-28l3.6-3.6h12.7c1.1,0,2.1-.9,2.1-2.1s-.9-2.1-2.1-2.1h-8.6l3-3h12.7c1.1,0,2.1-.9,2.1-2.1s-.9-2.1-2.1-2.1ZM81.3,57.3c0,20.8-16.9,37.7-37.7,37.7S6,78.1,6,57.3,22.9,19.7,43.6,19.7s18.2,3.4,25.1,9.6l-8,8c-4.8-4.1-10.8-6.3-17.1-6.3-14.5,0-26.3,11.8-26.3,26.3s11.8,26.3,26.3,26.3,26.3-11.8,26.3-26.3-2.2-12.3-6.3-17.1l8-8c6.2,6.9,9.6,15.8,9.6,25.1ZM42.2,58.8c.8.8,2.2.8,2.9,0l4.5-4.5c.5.9.7,2,.7,3,0,3.7-3,6.7-6.7,6.7s-6.7-3-6.7-6.7,3-6.7,6.7-6.7,2.1.2,3,.7l-4.5,4.5c-.8.8-.8,2.1,0,2.9ZM49.7,48.3c-1.8-1.2-3.9-1.9-6-1.9-6,0-10.8,4.9-10.8,10.8s4.9,10.8,10.8,10.8,10.8-4.9,10.8-10.8-.6-4.2-1.9-6l8.1-8.1c3.3,4,5.1,9,5.1,14.1,0,12.2-9.9,22.2-22.2,22.2s-22.2-9.9-22.2-22.2,9.9-22.2,22.2-22.2,10.1,1.8,14.1,5.1l-8.1,8.1Z" />
        </svg>
    );
}