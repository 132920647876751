import React from 'react';

export default function DashboardServiceUploadedDocumentsIcon({ className, viewBox, fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox={viewBox ? `${viewBox}` : "0 0 100 100"}
      className={className ? `${className}` : "w-6 h-6 text-primary mr-2"}
      fill={fill ? `${fill}` : "currentColor"}
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>
        </style>
      </defs>
      <path d="M45.3,78.2c0-.9-.7-1.7-1.7-1.7h-18c-.9,0-1.7.7-1.7,1.7s.7,1.7,1.7,1.7h18c.9,0,1.7-.7,1.7-1.7ZM43.2,72.4c0-.9-.7-1.7-1.7-1.7h-18c-.9,0-1.7.7-1.7,1.7s.7,1.7,1.7,1.7h18c.9,0,1.7-.7,1.7-1.7ZM45.4,82.3h-18c-.9,0-1.7.7-1.7,1.7s.7,1.7,1.7,1.7h18c.9,0,1.7-.7,1.7-1.7s-.7-1.7-1.7-1.7ZM96.4,16.3c-.9-1.1-2.3-1.8-3.7-1.8h-21.4c-.6-4-3.5-7.2-7.2-8-.7-.1-1.3-.2-2-.2h-16c-3.8,0-7.2,2.7-8.2,6.4l-.7,1.9h-.6c-2.9,0-5.4,2.1-5.9,5l-.4,2.2h-5.5c-1.8,0-3.2,1.4-3.3,3.2l-.2,3.5h-7.8c-1,0-1.9.4-2.5,1.1-.6.7-.9,1.7-.8,2.6l.4,2.9h-3.6c-1.4,0-2.7.7-3.6,1.8-.8,1.1-1.1,2.6-.7,3.9l14.2,46.9c1.1,3.7,4.4,6.1,8.3,6.1h49.8c1.3,0,2.6-.5,3.6-1.3,2.1-1.3,3.7-3.3,4.3-5.9l14.6-66.2c.3-1.4,0-2.9-.9-4ZM73.3,31.7l3,38.2-7.6-31.2c-.5-2.2-2.5-3.7-4.7-3.7H13.9l-.4-3.3h59.8ZM76.7,89.5c-.4.6-1.1.9-1.8.9H25.1c-2.4,0-4.4-1.5-5.1-3.8L5.9,39.7c-.1-.3,0-.7.2-1,.2-.3.5-.4.9-.4h57.1c.7,0,1.3.5,1.5,1.1l11.7,48.1c.2.7,0,1.4-.4,2ZM79.5,68.4l-2.9-37c-.1-1.7-1.6-3.1-3.3-3.1H24.6l.2-3.3h56.6l-1.9,43.3ZM94.1,19.6l-11.4,51.8,2-46.3c0-.9-.3-1.8-.9-2.4-.6-.7-1.5-1-2.4-1h-47.7l.3-1.6c.2-1.3,1.3-2.2,2.6-2.2h1.8c.7,0,1.3-.4,1.6-1.1l1.2-3.1c.6-2.4,2.7-4,5-4.1h16c.4,0,.9,0,1.3.1,2.7.6,4.6,3.1,4.7,6.1v.4c0,.9.8,1.6,1.7,1.6h23c.4,0,.9.2,1.1.5.2.2.4.6.3,1.2Z" />
    </svg>
  );
}