import React from 'react';

export default function DashboardServiceQuicSettingsIcon({ className, viewBox, fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox={viewBox ? `${viewBox}` : "0 0 100 100"}
      className={className ? `${className}` : "w-6 h-6 text-darkGray mr-2"}
      fill={fill ? `${fill}` : "currentColor"}
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>
        </style>
      </defs>
      <path d="M69.4,58.5c2-6,.4-12.7-4.1-17.2-5-5-12.7-6.4-19.1-3.4-.5.2-.9.7-1,1.3-.1.6,0,1.1.5,1.5l9.7,9.7-1.3,3.6-3.6,1.3-9.7-9.7c-.4-.4-1-.6-1.5-.5-.6,0-1,.5-1.3,1-3,6.4-1.6,14.1,3.4,19.1,4.5,4.5,11.2,6.1,17.2,4.1l20,20c1.5,1.5,3.5,2.3,5.5,2.3s4-.7,5.5-2.3c3-3,3-7.9,0-10.9l-20-20ZM86.9,87c-1.6,1.6-4.3,1.6-6,0l-20.8-20.8c-.3-.3-.8-.5-1.2-.5s-.4,0-.7.1c-5,2-10.7.9-14.6-3-3.4-3.4-4.7-8.2-3.6-12.8l8.5,8.5c.5.5,1.2.6,1.8.4l5.5-1.9c.5-.2.9-.6,1.1-1.1l1.9-5.5c.2-.6,0-1.3-.4-1.8l-8.5-8.5c4.5-1.1,9.4.2,12.8,3.6,3.8,3.8,5,9.5,3,14.6-.3.7-.1,1.4.4,1.9l20.8,20.8c1.6,1.7,1.6,4.3,0,6ZM35,34.9c2.1-2,4.4-3.6,7.1-4.7,2.6-1,5.4-1.5,8-1.5,2.8,0,5.5.5,8,1.6,2.6,1.1,5,2.7,7,4.7,2,2,3.6,4.4,4.7,7.1.4.9,1.4,1.3,2.3,1,.9-.4,1.3-1.4,1-2.3-1.2-3.1-3.1-5.9-5.4-8.2-2.4-2.4-5.1-4.2-8.2-5.5-3-1.2-6.1-1.8-9.3-1.9h0c-3.2,0-6.3.6-9.3,1.8-3.1,1.2-5.9,3.1-8.2,5.4-2.4,2.4-4.2,5.1-5.5,8.2-1.2,3-1.8,6.1-1.9,9.3,0,3.2.6,6.4,1.8,9.4,1.2,3.1,3.1,5.9,5.4,8.2,2.4,2.4,5.1,4.2,8.2,5.5.2,0,.4.1.7.1.7,0,1.3-.4,1.6-1.1.4-.9,0-1.9-.9-2.3-2.6-1.1-5-2.7-7-4.7-2-2-3.6-4.4-4.7-7.1-1-2.6-1.5-5.3-1.5-8,0-2.8.5-5.5,1.6-8,1.1-2.6,2.7-5,4.7-7ZM94.6,42c-.1-.8-.8-1.4-1.6-1.4l-9.2-.9c-.6-2-1.4-4-2.4-5.9l6-7.1c.5-.6.5-1.5.1-2.1-3.1-4.6-7.1-8.5-11.6-11.7-.6-.4-1.5-.4-2.1,0l-7.1,5.9c-1.9-1-3.9-1.8-5.9-2.5l-.8-9.2c0-.8-.6-1.4-1.4-1.6-5.4-1-11.1-1-16.5,0-.8.1-1.4.8-1.4,1.6l-.9,9.2c-2.1.6-4,1.4-5.9,2.4l-7.1-6c-.6-.5-1.5-.5-2.1-.1-4.6,3.1-8.5,7.1-11.7,11.6-.4.6-.4,1.5,0,2.1l5.9,7.1c-1,1.9-1.8,3.9-2.5,5.9l-9.2.8c-.8,0-1.4.6-1.6,1.4-.5,2.8-.8,5.6-.8,8.4s.2,5.4.7,8.1c.1.8.8,1.4,1.6,1.4l9.2.9c.6,2,1.4,4,2.4,5.9l-6,7.1c-.5.6-.5,1.5-.1,2.1,3.1,4.6,7.1,8.5,11.6,11.7.6.4,1.5.4,2.1,0l7.1-5.9c1.9,1,3.9,1.8,5.9,2.5l.8,9.2c0,.8.6,1.4,1.4,1.6,2.8.5,5.6.8,8.4.8s5.4-.2,8.1-.
      7c.8-.1,1.4-.8,1.4-1.6l.9-9.2c.3,0,.6-.2.9-.3l1-.4c.9-.3,1.4-1.3,1-2.2-.3-.9-1.3-1.4-2.2-1l-.9.3c-.6.2-1.3.4-1.9.6-.7.2-1.2.8-1.3,1.5l-.9,9.1c-4.2.6-8.5.6-12.7,0l-.8-9.1c0-.7-.6-1.3-1.3-1.5-2.6-.7-5-1.7-7.3-3.1-.6-.4-1.4-.3-2,.2l-7,5.8c-3.4-2.5-6.4-5.5-8.9-9l5.9-7c.5-.6.5-1.3.2-2-1.3-2.3-2.3-4.8-3-7.4-.2-.7-.8-1.2-1.5-1.3l-9.1-.9c-.3-2-.5-4.1-.5-6.2s.2-4.3.5-6.5l9.1-.8c.7,0,1.3-.6,1.5-1.3.7-2.6,1.7-5,3.1-7.3.4-.6.3-1.4-.2-2l-5.8-7c2.5-3.4,5.5-6.4,9-8.9l7,5.9c.6.5,1.3.5,2,.2,2.3-1.3,4.8-2.3,7.4-3,.7-.2,1.2-.8,1.3-1.5l.9-9.1c4.2-.6,8.5-.6,12.7,0l.8,9.1c0,.7.6,1.3,1.3,1.5,2.6.7,5,1.7,7.3,3.1.6.4,1.4.3,2-.2l7-5.8c3.4,2.5,6.4,5.5,8.9,9l-5.9,7c-.5.6-.5,1.3-.2,2,1.3,2.3,2.3,4.8,3,7.4.2.7.8,1.2,1.5,1.3l9.1.9c.3,2,.5,4.1.5,6.2s-.2,4.3-.5,6.5l-9.1.8c-.7,0-1.3.6-1.5,1.3-.2.6-.4,1.3-.6,1.9l-.3.9c-.3.9.1,1.9,1,2.2.9.3,1.9-.1,2.2-1l.3-1c.1-.3.2-.6.3-.9l9.2-.8c.8,0,1.4-.6,1.6-1.4.5-2.8.8-5.6.8-8.4s-.2-5.4-.7-8.1Z"/>
    </svg>
  );
}