/* eslint-disable max-len */
export default {
  MoreDetailsButtonText: {
    "en-US": "More Details",
    "es-US": "Más detalles",
  },
  CloseButtonText: {
    "en-US": "Close",
    "es-US": "Cerrar",
  },
};
