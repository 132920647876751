import React from "react";

const DynamicButton = (props) => {
  const { isActive, children, onClickHandler } = props;

  return (
    <button
      onClick={onClickHandler}
      className={`px-2 py-2 rounded-lg text-white font-semibold flex items-center justify-center gap-2 ${
        isActive ? "bg-primary" : "bg-secondary"
      }`}
    >
      {children}
    </button>
  );
};

export default DynamicButton;
