import React from "react";

import TextEditor from "components/TextEditor";

import langL10n from "./lang-l10n";

export default function AddNoteForm({ locale = "en-US" }) {
  return (
    <div className="p-4 bg-white rounded-lg flex flex-col gap-4 h-fit">
      <div className="flex border-b pb-4 gap-4">
        <div className="w-full flex flex-col gap-3">
          <div className="grid grid-cols-12">
            <div className="col-span-12 flex flex-col gap-1">
              <label className="text-sm text-primary">
                {langL10n.TitleText[locale]}
              </label>
              <input className="rounded-md bg-lightGray text-xs w-full h-6" />
            </div>
          </div>
          <div className="grid grid-cols-12">
            <div className="col-span-12 flex flex-col gap-1">
              <label className="text-sm text-primary">
                {langL10n.NoteText[locale]}
              </label>
              <TextEditor className="rounded-md text-xs w-full border-0 align-top h-fit" />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-6">
        {/* Buttons Section */}
        <div className="text-right flex justify-end gap-4">
          <button className="bg-primary text-white w-28 py-1 rounded">
            {langL10n.CancelButtonText[locale]}
          </button>
          <button className="bg-primary text-white w-28 py-1 rounded">
            {langL10n.SaveButtonText[locale]}
          </button>
        </div>
      </div>
    </div>
  );
}
