/* eslint-disable max-len */
export default {
  HeadingText: {
    "en-US": "Service Dashboard",
    "es-US": "Tablero de Servicio",
  },
  AccountSummaryText: {
    "en-US": "Account Summary",
    "es-US": "Resumen de la Cuenta",
  },
  ClientTypeText: {
    "en-US": "Client Type",
    "es-US": "Tipo de Cliente",
  },
  StatusText: {
    "en-US": "Status",
    "es-US": "Estado",
  },
  LinesOfBusinessText: {
    "en-US": "Lines of Business",
    "es-US": "Líneas de Negocio",
  },
  ProducerText: {
    "en-US": "Producer",
    "es-US": "Productor",
  },
  OfficeText: {
    "en-US": "Office",
    "es-US": "Oficina",
  },
  ClientTagsText: {
    "en-US": "Client Tags",
    "es-US": "Etiquetas de Cliente",
  },
  CSRText: {
    "en-US": "CSR",
    "es-US": "CSR",
  },
};
