import React, { useState } from "react";
import langL10n from "./lang-l10n";
import AgencyLogo from "..//AgencyLogo";

export default function AppBar({ username = "Ray", locale = "en-US" }) {
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showQuickLinks, setShowQuickLinks] = useState(false);

  return (
    <div className="bg-[#1A1A1A] w-full fixed top-0 z-50">
      <div className="pl-16 mx-auto px-4 py-2 flex items-center justify-between">
        <div className="flex items-center">
          <AgencyLogo locale={locale} />
        </div>
        <div className="flex items-center space-x-6">
          <button
            className="text-white bg-primary rounded-md p-1 focus relative"
            onClick={() => setShowQuickLinks(!showQuickLinks)}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 4v16m8-8H4"
              ></path>
            </svg>
          </button>
          {showQuickLinks && (
            <div className="absolute top-12 right-56 mt-2 w-48 bg-[#979797] rounded-md shadow-lg py-2">
              <div className="bg-red-600 text-white px-4 py-2 flex justify-between items-center">
                <span>{langL10n.QuickAddText[locale]}</span>
                <button
                  onClick={() => setShowQuickLinks(!showQuickLinks)}
                  className="focus"
                >
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </button>
              </div>
              <a
                href="#"
                className="block px-4 py-2 text-white hover:bg-gray-100"
              >
                {langL10n.AddClientText[locale]}
              </a>
              <a
                href="#"
                className="block px-4 py-2 text-white hover:bg-gray-100"
              >
                {langL10n.AddProspectText[locale]}
              </a>
              <a
                href="#"
                className="block px-4 py-2 text-white hover:bg-gray-100"
              >
                {langL10n.AddNoteText[locale]}
              </a>
            </div>
          )}
          <div className="text-white text-lg">{`${langL10n.WelcomeText[locale]}, ${username}`}</div>
          <div className="relative">
            <button
              className="text-white bg-gray-700 rounded-full w-10 h-10 flex items-center justify-center focus"
              onClick={() => setShowProfileMenu(!showProfileMenu)}
            >
              <span className="text-white">RC</span>
            </button>
            {showProfileMenu && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1">
                <a
                  href="#"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  {langL10n.ProfileText[locale]}
                </a>
                <a
                  href="#"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  {langL10n.MyAccountText[locale]}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
