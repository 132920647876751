import React from "react";
import DashboardWrapper from "layouts/DashboardWrapper";
import langL10n from "./lang-l10n";
import { 
   DashboardServiceCurrencyIcon
 } from "../Icons";

const SalesDashboard = ({ locale = "en-US" }) => {
   return (
      <DashboardWrapper>

         <div className="flex">
            <div className="mr-1">
               <button className="px-2 py-2 rounded-lg text-white font-semibold flex items-center justify-center gap-2 bg-black">
                   <DashboardServiceCurrencyIcon className="w-6 h-6" />
                  {`${langL10n.HeadingText[locale]}`}
               </button>
            </div>
         </div>

      </DashboardWrapper>
   );
};

export default SalesDashboard;
