import React from "react";
import { 
  DashboardServiceCustomerNotesIcon, 
  DashboardServiceUploadedDocumentsIcon 
} from "components/Icons";

const ClientToolFilters = ({
  activeClientToolFilter,
  setActiveClientToolFilter,
}) => {
  const icons = [DashboardServiceCustomerNotesIcon, DashboardServiceUploadedDocumentsIcon];

  const FilterButton = ({ icon: Icon, index }) => {
    const isActive = index === activeClientToolFilter;
    const activeButtonClass = isActive ? "bg-primary" : "bg-white";
    const activeIconClass = isActive ? "text-white" : "text-black";

    return (
      <div
        className={`w-24 h-24 p-2 mr-2 flex items-center justify-center rounded-md ${activeButtonClass} cursor-pointer`}
        onClick={() => setActiveClientToolFilter(index)} // Use the prop
      >
        <Icon className={`w-16 h-16 m-auto ${activeIconClass}`} />
      </div>
    );
  };

  return (
    <div className="flex">
      {icons.map((Icon, index) => (
        <FilterButton key={index} icon={Icon} index={index} />
      ))}
    </div>
  );
};

export default ClientToolFilters;
