import React from 'react';

export default function DashboardServiceHomeIcon({ className, viewBox, fill }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox={viewBox ? `${viewBox}` : "0 0 100 100"}
            className={className ? `${className}` : "w-6 h-6 text-primary mr-2"}
            fill={fill ? `${fill}` : "currentColor"}
            preserveAspectRatio="xMidYMid meet"
        >
            <defs>
                <style>
                </style>
            </defs>
            <path d="M84.1,96.6h-26.4c-.9,0-1.7-.7-1.7-1.6v-25.9h-11.9v25.9c0,.9-.8,1.6-1.7,1.6H15.9c-.9,0-1.7-.7-1.7-1.6v-48.7H2.5c-.7,0-1.3-.4-1.6-1.1-.2-.6,0-1.4.5-1.8l14.7-12.2V13.1c0-.9.8-1.6,1.7-1.6h10.6c.9,0,1.7.7,1.7,1.6v6.5L48.9,4c.6-.5,1.5-.5,2.2,0l47.5,39.4c.5.4.7,1.2.5,1.8-.2.6-.9,1.1-1.6,1.1h-11.8v48.7c0,.9-.8,1.6-1.7,1.6ZM59.3,93.3h23.1v-48.7c0-.9.8-1.6,1.7-1.6h8.9L50,7.4l-20.6,17c-.5.4-1.2.5-1.8.2-.6-.3-1-.8-1-1.5v-8.4h-7.2v17.2c0,.5-.2.9-.6,1.2l-11.8,9.8h8.9c.9,0,1.7.7,1.7,1.6v48.7h23.1v-25.9c0-.9.8-1.6,1.7-1.6h15.3c.9,0,1.7.7,1.7,1.6v25.9Z" />
        </svg>
    );
}