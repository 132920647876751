import React from "react";

import TruncatorText from "components/TruncateText";

import langL10n from "./lang-l10n";

const AccountSummary = ({ customer, locale = "en-US" }) => {
  return (
    <div className="max-w-md mx-auto rounded-xl overflow-hidden md:max-w-2xl bg-white">
      <div className="grid grid-cols-3 gap-2">
        {/* First Column */}
        <div>
          <h3 className="text-primary font-bold">
            {langL10n.ClientTypeText[locale]}
          </h3>
          <p className="text-xs">{customer?.accountSummary.clientType}</p>

          <h3 className="text-primary font-bold mt-4">
            {langL10n.ProducerText[locale]}
          </h3>

          <span className="text-xs">
            <TruncatorText
              fieldName={langL10n.ProducerText[locale]}
              cutOff={20}
            >
              {customer?.accountSummary.producer}
            </TruncatorText>
          </span>

          <h3 className="text-primary font-bold mt-4">
            {langL10n.CSRText[locale]}
          </h3>
          <span className="text-xs">
            <TruncatorText fieldName={langL10n.CSRText[locale]} cutOff={20}>
              {customer?.accountSummary.csr}
            </TruncatorText>
          </span>
        </div>

        {/* Second Column */}
        <div>
          <h3 className="text-primary font-bold">
            {langL10n.StatusText[locale]}
          </h3>
          <p className="text-xs text-confirm">
            {customer?.accountSummary.status}
          </p>

          <h3 className="text-primary font-bold mt-4">
            {langL10n.OfficeText[locale]}
          </h3>
          <p className="text-xs">
            <TruncatorText fieldName={langL10n.OfficeText[locale]} cutOff={12}>
              {customer?.accountSummary.office}
            </TruncatorText>
          </p>
        </div>

        {/* Third Column */}
        <div>
          <h3 className="text-primary font-bold">
            {langL10n.LinesOfBusinessText[locale]}
          </h3>
          {customer?.accountSummary.linesOfBusiness.map((lineOfBusiness) => {
            return (
              <p key={lineOfBusiness} className="text-xs">
                {lineOfBusiness}
              </p>
            );
          })}

          <h3 className="text-primary font-bold mt-4">
            {langL10n.ClientTagsText[locale]}
          </h3>
          <p className="text-xs">
            {customer?.accountSummary.linesOfBusiness.join(", ")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AccountSummary;
