import React from "react";
import { 
  DashboardHomeCalendarIcon,
  DashboardNavigationMenuAddIcon,
  DashboardNavigationMenuAnalyticsIcon, 
  DashboardNavigationMenuEngageIcon, 
  DashboardNavigationMenuHelpIcon, 
  DashboardNavigationMenuInteliforgeIcon, 
  DashboardNavigationMenuNotificationsIcon, 
  DashboardNavigationMenuPhoneIcon, 
  DashboardNavigationMenuSearchIcon, 
  DashboardNavigationMenuSettingsIcon, 
  DashboardServiceCurrencyIcon, 
  DashboardServiceHomeIcon, 
  DashboardServiceQuickSettingsIcon
} from "components/Icons";

const navLinks = [
  { name: "Home", icon: <DashboardServiceHomeIcon className="w-6 h-6" />, href: "#" },
  {
    name: "Search",
    icon: <DashboardNavigationMenuSearchIcon className="w-6 h-6" />,
    href: "/search",
  },
  { name: "Service", icon: <DashboardServiceQuickSettingsIcon className="w-6 h-6 text-darkGray" />, href: "/service" },
  { name: "Sales", icon: <DashboardServiceCurrencyIcon className="w-6 h-6" />, href: "/sales-dashboard" },
  {
    name: "Analytics",
    icon: <DashboardNavigationMenuAnalyticsIcon className="w-6 h-6" />,
    href: "#",
  },
  {
    name: "Engage(CRM)",
    icon: <DashboardNavigationMenuEngageIcon className="w-6 h-6" />,
    href: "#",
  },
];

const coreLinks = [
  { name: "Notifications", icon: <DashboardNavigationMenuNotificationsIcon className="w-6 h-6"/> },
  { name: "Settings", icon: <DashboardNavigationMenuSettingsIcon className="w-6 h-6" /> },
  { name: "Help", icon: <DashboardNavigationMenuHelpIcon className="w-6 h-6" /> },
];

const actionLinks = [
  {
    name: "Add Widget",
    icon: <DashboardNavigationMenuAddIcon className="w-4 h-4 mx-2 cursor-pointer" />,
  },
  {
    name: "Calendar",
    icon: <DashboardHomeCalendarIcon className="w-4 h-4 mx-2 cursor-pointer" />,
  },
  {
    name: "Phone",
    icon: <DashboardNavigationMenuPhoneIcon className="w-4 h-4 mx-2 cursor-pointer" />,
  },
  {
    name: "IntelliForge",
    icon: <DashboardNavigationMenuInteliforgeIcon className="w-4 h-4 mx-2 cursor-pointer" />,
  },
];

export { navLinks, coreLinks, actionLinks };
