import React from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import langL10n from "./lang-l10n";
import { DashboardNavigationMenuSearchIcon } from "components/Icons";

const SearchBar = ({ searchQuery, searchQueryHandler, locale = "en-US" }) => {
  const searchInputHamdler = (event) => {
    searchQueryHandler(event.target.value);
  };

  const resetSearchQuery = () => {
    searchQueryHandler("");
  };

  return (
    <div className="flex flex-1 items-center rounded-lg overflow-hidden">
      <button className="bg-primary flex py-2 px-4 rounded-l-lg">
        <DashboardNavigationMenuSearchIcon className="w-6 h-6 mr-2 text-white" />
        <span className="text-white">{langL10n.SearchText[locale]}</span>
      </button>
      <input
        type="text"
        value={searchQuery}
        onChange={searchInputHamdler}
        placeholder={langL10n.PlaceholderText[locale]}
        className="px-4 py-2 flex-1 rounded-r-lg outline-none border-0 focus:outline-none"
      />
      <button
        className="p-2 py-2 bg-primary rounded-lg ml-2"
        onClick={resetSearchQuery}
      >
        <XMarkIcon className="w-6 h-6 text-white" />
      </button>
    </div>
  );
};

export default SearchBar;
