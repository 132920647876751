import React from "react";

export default function DashboardNavigationMenuInteliforgeIcon({ className, viewBox, fill }) {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox={viewBox ? `${viewBox}` : "0 0 100 100"}
            className={className ? `${className}` : "w-6 h-6 text-primary mr-2"}
            fill={fill ? `${fill}` : "currentColor"}
        >
            <defs>
                <style>
                    {`
                    `}
                </style>
            </defs>
            <path
                d="M75.6,20.7c-3.2,2.3-6.3,4.5-9.4,6.7,1.2,1.8,2.4,3.5,3.7,5.3,3.2-2.3,6.3-4.5,9.4-6.7-1.2-1.8-2.5-3.5-3.7-5.3ZM54.5,32.1c1.2,1.8,2.4,3.4,3.6,5.1,3-2.1,6-4.2,9-6.4-1.2-1.7-2.4-3.4-3.6-5.1-3,2.1-6,4.2-9,6.4ZM77.1,10c-.9-1.2-1.7-2.4-2.5-3.6-2.1,1.6-4.1,3.1-6.2,4.6.9,1.2,1.7,2.4,2.5,3.6,2.1-1.6,4.1-3.1,6.2-4.6ZM75,30.9c-2,1.5-4,3-6.1,4.5.9,1.2,1.7,2.4,2.5,3.6,2.1-1.5,4.2-3,6.1-4.5-.4-.8-2.2-3.4-2.5-3.6ZM76.5,19.8c2.1-1.6,4.1-3.1,6.2-4.6-.9-1.2-1.7-2.4-2.5-3.6-2.1,1.6-4.1,3.1-6.2,4.6.9,1.2,1.7,2.4,2.5,3.6ZM71.4,39s0,0,0,0c0,0,0,0,0,0h.1ZM15.3,56.1c-2.6,3.5-3.9,7.5-4,11.9,0,2.9.4,5.6,1.3,8.3,1.4,4,3.5,7.5,6.2,10.7,0,0,.1,0,.2.2h.2c-.1-.4-.2-.5-.2-.7-.4-1.5-.7-3-.8-4.5-.2-2.8,0-5.4.8-8.1,1-3.2,2.4-6.2,4.2-9,2.6-4.1,5.9-7.8,9.4-11.3,3.3-3.4,7-6.5,10.6-9.6,3-2.6,6.1-5.1,9.1-7.7.2,0,.3-.2.5-.4-1.2-1.6-2.3-3.1-3.4-4.6-2.3,1.9-4.5,3.7-6.8,5.6-1.3-1.8-2.5-3.7-3.8-5.5-.2,0-.3.2-.3.2-2.3,2.2-4.7,4.3-7,6.5-3.1,2.9-6.3,5.9-9.2,9-2.4,2.6-4.6,5.3-6.5,8.3-.2.2-.3.5-.5.7ZM71.8,64.7c.3-2.1-.3-4-2-5.4-.8-.6-1.6-1.1-2.5-1.5-1.9-.8-3.9-1.2-6-1.5-.2,0-.4.1-.6.2-4.6,3.4-9.1,7-13.3,10.8-2.9,2.6-5.7,5.4-8.2,8.4-1.3,1.5-2.4,3.1-3.3,4.8-.4.9-.8,1.8-1,2.8-.2,1.1,0,2,1,2.6.6.4,1.3.7,2,1,1.7.6,3.4.9,5.1,1.1s3.5.3,5.2.4h3.1c.4,0,.7,0,1-.3,1.3-1.1,2.7-2.3,4-3.4,3.3-2.9,6.5-5.9,9.4-9.3,1.8-2,3.4-4.2,4.7-6.6.7-1.3,1.2-2.6,1.4-4.1ZM73.1,20.1c-1.1-1.5-2.1-3-3.2-4.5-2.7,1.9-5.4,3.8-8.1,5.7,1.1,1.5,2.1,3,3.2,4.5,2.7-1.9,5.4-3.8,8.1-5.7ZM97.4,52c-.8-3.1-3.6-5.4-6.8-5.4h-14.6c-.3,0-.6,0-.9.2-3.9,2.2-7.6,4.7-11.2,7.3-.1,0-.2.2-.2.2h.4c2.8-.4,5.7-.3,8.5.2,2.2.4,4.2,1.2,6.1,2.4,2.5,1.6,4.2,3.8,4.9,6.7.6,2.2.5,4.3,0,6.5-.5,2.5-1.5,4.8-2.8,7-1.2,2-2.6,3.9-4.1,5.8-2.9,3.7-6,7.1-9.4,10.3l-.3.3h4c.2,0,.4-.2.5-.3,2.3-2.3,4.5-4.8,6.5-7.
                4,2.1-2.7,3.9-5.6,5.2-8.7,1.2-2.7,1.9-5.6,2.1-8.5.2-2.6,0-5.2-.9-7.8-.1,0-.1-.3-.1-.4h.1c0,0,0,.2,0,.2,2.2,2.6,4,5.4,5.4,8.5,1.7,4,2.5,8.1,2,12.5-.5,3.5-1.6,6.6-3.6,9.5-.4.7-.9,1.3-1.3,2,0,.1-.2.2-.2.4h3.8c.6,0,1.1,0,1.7-.2,2.7-.6,4.8-3,5.2-5.8v-.2h.1v-34.4c-.1-.3-.2-.6-.2-.9ZM36.8,90.2c-1.4-.4-2.6-1-3.7-2-1.3-1.2-1.9-2.9-1.9-4.3s.4-2.7.9-3.9c.8-2,1.9-3.7,3.2-5.4,2.5-3.2,5.3-6.1,8.3-8.9,4.5-4.2,9.2-8,14.1-11.6,4.9-3.7,9.9-7.1,15.1-10.2.1,0,.2-.1.2-.1-1.8-2.5-3.6-5-5.5-7.5-2.7,2-5.4,3.9-8.1,5.8h0c-1.2-1.8-2.3-3.3-3.4-4.9-.8.8-1.6,1.4-2.4,2.1-3.9,3.2-7.7,6.4-11.5,9.6-3.6,3-7,6.1-10.2,9.5-2.6,2.7-4.9,5.6-7.2,8.6-1.3,1.8-2.5,3.6-3.4,5.6-1.2,2.5-2.1,5.1-2.1,7.8,0,3.4,1,6.4,3.5,8.8,1.1,1.1,2.5,2,3.9,2.6,2.5,1.1,5.2,1.6,8,1.6,3.9,0,7.6-.6,11.3-1.6h.2c-1.3-.2-2.6-.3-3.8-.4-1.9-.2-3.8-.6-5.6-1.2ZM16.1,89.7c-2.3-2.8-4.3-5.8-5.8-9.1-2.2-4.9-3.2-9.9-2.5-15.3.4-2.8,1.2-5.5,2.5-8,.6-1.3,1.4-2.4,2.2-3.6,1.5-2.4,3.2-4.6,5-6.7,0,0,.2-.2.3-.4h-8.3c-3.4,0-7.2,3-7.2,6.7v33.6c.1.3.2.5.2.7.6,3.4,3.4,6,6.9,6h10c-.1-.2-.3-.3-.4-.5-1-1.1-1.9-2.3-2.9-3.4ZM60.8,23.5c-1.4-1.9-2.7-3.8-4.1-5.8-3.4,2.5-6.9,4.9-10.3,7.4,1.4,2,2.7,3.9,4.1,5.8,3.4-2.5,6.9-4.9,10.3-7.4Z" />
        </svg>
    );
}