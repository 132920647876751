/* eslint-disable max-len */
const langL10n = {
  TitleText: {
    "en-US": "Title",
    "es-US": "Título",
  },
  NoteText: {
    "en-US": "Note",
    "es-US": "Nota",
  },
  CancelButtonText: {
    "en-US": "Cancel",
    "es-US": "Cancelar",
  },
  SaveButtonText: {
    "en-US": "Save",
    "es-US": "Guardar",
  },
};

export default langL10n;
