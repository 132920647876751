import React, { useEffect } from "react";
import { 
  DashboardServiceAllPoliciesIcon, 
  DashboardServiceAutoIcon, 
  DashboardServiceCommercialAutoIcon, 
  DashboardServiceCommercialPropertyIcon, 
  DashboardServiceHomeIcon,
  DashboardServiceUmbrellaIcon
} from "components/Icons/index";

export default function NotesFilters({
  activeNotesFilters,
  setActiveNotesFilters,
}) {
  const toggleFilter = (filter) => {
    if (filter === "default") {
      setActiveNotesFilters(["default"]);
    } else {
      if (activeNotesFilters.includes("default")) {
        setActiveNotesFilters([filter]);
      } else if (activeNotesFilters.includes(filter)) {
        const newFilters = activeNotesFilters.filter(
          (activeFilter) => activeFilter !== filter
        );
        setActiveNotesFilters(newFilters.length > 0 ? newFilters : ["default"]);
      } else {
        setActiveNotesFilters([...activeNotesFilters, filter]);
      }
    }
  };

  const filters = [
    { icon: DashboardServiceAllPoliciesIcon, filter: "default" },
    { icon: DashboardServiceHomeIcon, filter: "homePolicy" },
    { icon: DashboardServiceAutoIcon, filter: "autoPolicy" },
    // { icon: DashboardServiceCommercialPropertyIcon, filter: "commercialPropertyPolicy" },
    // { icon: DashboardServiceCommercialAutoIcon, filter: "commercialAutoPolicy" },
    { icon: DashboardServiceUmbrellaIcon, filter: "umbrellaPolicy" },
  ];

  return (
    <>
      {filters.map(({ icon: Icon, filter }, index) => {
        const isActive = activeNotesFilters.includes(filter);
        return (
          <button
            key={index}
            className={`w-6 h-6 mr-2 relative right-1 ${
              isActive ? "text-primary" : "text-lightGray"
            } hover:text-primary`}
            onClick={() => toggleFilter(filter)}
          >
            <Icon
              className={`w-6 h-6 ${isActive ? "text-primary" : "text-lightGray"}`}
            />
          </button>
        );
      })}
    </>
  );
}
