import React from 'react';

export default function DashboardServiceNewTabIcon({ className, viewBox, fill }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox={viewBox ? `${viewBox}` : "0 0 100 100"}
            className={className ? `${className}` : "w-6 h-6 text-primary mr-2"}
            fill={fill ? `${fill}` : "currentColor"}
            preserveAspectRatio="xMidYMid meet"
        >
            <defs>
                <style>
                </style>
            </defs>
            <path d="M88,59.4c-.8,0-1.5.7-1.5,1.5v19.6c0,4.6-3.7,8.3-8.3,8.3H19.5c-4.6,0-8.3-3.7-8.3-8.3V21.8c0-4.6,3.7-8.3,8.3-8.3h19.6c.8,0,1.5-.7,1.5-1.5s-.7-1.5-1.5-1.5h-19.6c-6.2,0-11.3,5.1-11.3,11.3v58.7c0,6.2,5.1,11.3,11.3,11.3h58.7c6.2,0,11.3-5.1,11.3-11.3v-19.6c0-.8-.7-1.5-1.5-1.5ZM83.1,5.6h-24.5c-.8,0-1.5.7-1.5,1.5s.7,1.5,1.5,1.5h24.5c2,0,3.9.7,5.4,2-.5-.2-1.1,0-1.5.3l-39.1,39.1c-.6.6-.6,1.5,0,2.1s.7.4,1.1.4.8-.1,1.1-.4L89.1,13.1c.4-.4.5-1,.3-1.5,1.2,1.4,2,3.3,2,5.4v24.5c0,.8.7,1.5,1.5,1.5s1.5-.7,1.5-1.5v-24.5c0-6.2-5.1-11.3-11.3-11.3Z" />
        </svg>
    );
}