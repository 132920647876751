import React from 'react';

export default function DashboardServiceCurrencyIcon({ className, viewBox, fill }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox={viewBox ? `${viewBox}` : "0 0 100 100"}
            className={className ? `${className}` : "w-6 h-6 text-primary mr-2"}
            fill={fill ? `${fill}` : "currentColor"}
            preserveAspectRatio="xMidYMid meet"
        >
            <defs>
                <style>
                </style>
            </defs>
            <path d="M52,83.2v10.8h-3.5v-10.8c-9.2-.4-17.8-3.9-22.3-8.9l2.2-3.6c4.2,4.4,11.9,7.9,20.1,8.2v-27.1c-10.2-2.5-20.5-5.6-20.5-17.7s6.3-16.5,20.5-17.3V6.1h3.5v10.8c7,.2,14.1,2.3,19,5.9l-1.8,3.8c-5.2-3.6-11.5-5.3-17.2-5.5v27.1c10.7,2.6,21.7,5.4,21.7,17.8s-6.8,16.6-21.7,17.3ZM48.6,47.3v-26.2c-10.8.7-15.7,6.4-15.7,13s7.3,11,15.7,13.2ZM68.9,66.2c0-8.7-8-11.2-16.9-13.5v26.3c11.7-.6,16.9-6.1,16.9-12.8Z" />
        </svg>
    );
}