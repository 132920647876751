import React, { useState, useEffect, useRef } from "react";
import {
  UserIcon,
  PencilSquareIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {useLocation, useNavigate } from 'react-router';
import { useReactToPrint } from "react-to-print";
import { connect } from "react-redux";

import { withRouter } from "middleware/withRouter";

import DashboardWrapper from "layouts/DashboardWrapper";
import AgencyLogo from "components/AgencyLogo";
import SearchBar from "components/SearchBar";
import SearchResults from "components/SearchResults";
import Notes from "widgets/Notes";
import Modal from "components/Modal";
import CommonCard from "components/CommonCard";
import DynamicButton from "components/DynamicButton";
import CustomerCard from "components/CustomerCard";
import AccountSummary from "components/AccountSummary";
import PolicyFilters from "components/PolicyFilters";
import HomePolicy from "components/HomePolicy";
import AutoPolicy from "components/AutoPolicy";
import CommercialPropertyPolicy from "components/CommercialPropertyPolicy";
import CommercialAutoPolicy from "components/CommercialAutoPolicy";
import CustomerIntakeForm from "components/CustomerIntakeForm";
import AddNoteForm from "components/AddNoteForm";
import ClientToolFilters from "components/ClientToolFilters";
import NotesFilters from "components/NotesFilters";
import { DashboardNavigationMenuPrintIcon, DashboardNavigationMenuQuickAddIcon, DashboardServiceAutoIcon, DashboardServiceCurrencyIcon, DashboardServiceCustomerNotesIcon, DashboardServiceHomeIcon, DashboardServiceNewTabIcon, DashboardServiceUmbrellaIcon, DashboardServiceUploadedDocumentsIcon } from "../Icons/index";

import {
  selectActiveCustomer,
  selectCustomerAnalytics,
  selectSelectedCustomers,
  setActiveCustomer,
  removeSelectedCustomer,
  setActivePolicyFilter,
  setActiveClientToolFilter,
  setActiveNotesFilters,
  selectActivePolicyFilter,
  selectActiveClientToolFilter,
  selectActiveNotesFilters,
  selectHomePolicies,
  selectAutoPolicies,
  selectCommercialAutoPolicies,
  selectCommercialPropertyPolicies,
  selectFilteredNotes,
} from "../../data/serviceDashboard.slice";

import {
  fetchCustomer,
  fetchCustomerAnalytics,
  fetchHomePolicies,
  fetchAutoPolicies,
  fetchCommercialAutoPolicies,
  fetchCommercialPropertyPolicies,
  fetchCustomerNotes,
} from "../../data/actions";

import langL10n from "./lang-l10n";

const ActiveTabSection = ({
  selectedCustomers = [],
  goToSelectedCustomer,
  activeCustomer,
}) => (
  <div className="flex">
    {selectedCustomers.map((customer) => (
      <div className="mr-1" key={customer.id}>
        <DynamicButton
          isActive={customer.id === activeCustomer?.id}
          onClickHandler={() => goToSelectedCustomer(customer.id)}
        >
          <UserIcon className="w-6 h-6" />
          {customer.nameInsured}
        </DynamicButton>
      </div>
    ))}
  </div>
);

const CustomerCardSection = ({ activeCustomer, locale, toggleModal }) => {
  const activeCustomerId =
    activeCustomer && activeCustomer.id ? activeCustomer.id : "";

  return (
    <div className="flex flex-1 mt-2 mr-2">
      <CommonCard
        icon={<DashboardServiceHomeIcon className="w-6 h-6 text-primary mr-2" />}
        title={langL10n.CustomerIDText[locale] + ": " + activeCustomerId}
        buttons={
          <>
            <button
              className="w-6 h-6 mr-2 relative right-1"
              onClick={toggleModal}
            >
              <PencilSquareIcon className="w-6 h-6 text-lightGray mr-2 hover:text-primary" />
            </button>
          </>
        }
      >
        <CustomerCard customer={activeCustomer} />
      </CommonCard>
    </div>
  );
};

const AccountSummarySection = ({ activeCustomer, locale }) => (
  <div className="flex flex-1 mt-2 mr-2">
    <CommonCard
      icon={<DashboardServiceCustomerNotesIcon className="w-6 h-6 text-primary mr-2" />}
      title={langL10n.AccountSummaryText[locale]}
    >
      <AccountSummary customer={activeCustomer} />
    </CommonCard>
  </div>
);

const AnalyticsSection = ({ customerAnalytics, locale = "en-US" }) => (
  <div className="flex flex-1 mt-2 h-[50%]">
    <div className="flex flex-1">
      <div className="flex flex-1 mr-2">
        <CommonCard
          icon={<DashboardServiceHomeIcon className="w-6 h-6 text-primary" />}
          title={langL10n.ActivePoliciesText[locale]}
        >
          <div className="flex justify-between">
            <div className="flex flex-col items-center">
              <span className="text-4xl font-bold text-primary mb-1">
                {customerAnalytics?.numberOfHomePolicies}
              </span>
              <DashboardServiceHomeIcon className="w-6 h-6 text-primary" />
            </div>
            <div className="flex flex-col items-center">
              <span className="text-4xl font-bold text-primary mb-1">
                {customerAnalytics?.numberOfCommercialAutoPolicies}
              </span>
              <DashboardServiceAutoIcon className="w-6 h-6 text-primary" />
            </div>
            <div className="flex flex-col items-center">
              <span className="text-4xl font-bold text-primary mb-1">
                {customerAnalytics?.numberOfUmbrellaPolicies}
              </span>
              <DashboardServiceUmbrellaIcon className="w-6 h-6 text-primary" />
            </div>
          </div>
        </CommonCard>
      </div>

      <div className="flex flex-1 mr-2">
        <CommonCard
          icon={<DashboardServiceCustomerNotesIcon className="w-6 h-6 text-primary mr-2" />}
          title={langL10n.PremiumPerPolicyText[locale]}
        >
          <div className="text-5xl text-center font-bold text-primary">
            ${customerAnalytics?.premiumPerPolicy}
          </div>
        </CommonCard>
      </div>

      <div className="flex flex-1">
        <CommonCard
          icon={<DashboardServiceCurrencyIcon className="w-6 h-6 text-primary mr-2" />}
          title={langL10n.TotalPremiumText[locale]}
        >
          <div className="text-5xl text-center font-bold text-primary">
            ${customerAnalytics?.totalPremium}
          </div>
        </CommonCard>
      </div>
    </div>
  </div>
);

const ServiceDashboard = ({
  activeCustomer,
  customerAnalytics,
  selectedCustomers,
  locale = "en-US",
  setActiveCustomer,
  fetchCustomer,
  fetchCustomerAnalytics,
  fetchHomePolicies,
  fetchAutoPolicies,
  fetchCommercialAutoPolicies,
  fetchCommercialPropertyPolicies,
  fetchCustomerNotes,
  activePolicyFilter,
  setActivePolicyFilter,
  activeClientToolFilter,
  activeNotesFilters,
  setActiveClientToolFilter,
  setActiveNotesFilters,
  homePolicies,
  autoPolicies,
  commercialAutoPolicies,
  commercialPropertyPolicies,
  filteredNotes,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const notesRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const activeCustomerId = searchParams.get("customer");

  useEffect(() => {
    const loadCustomerData = async () => {
      if (activeCustomerId) {
        // First, fetch the customer using the fetchCustomer action
        await fetchCustomer(activeCustomerId); // Assume this is an async function returning a promise
      
        const customer = selectedCustomers.find(
          (customer) => customer.id === activeCustomerId
        );
        if (customer) {
          setActiveCustomer(customer);
        //  fetchCustomer(customer.id);
          fetchCustomerAnalytics(customer.id);
          fetchHomePolicies(customer.id);
          fetchAutoPolicies(customer.id);
          fetchCommercialAutoPolicies(customer.id);
          fetchCommercialPropertyPolicies(customer.id);
          fetchCustomerNotes(customer.id);
        }
      }
    };  
    // if (activeCustomerId) {
    //   fetchCustomer(activeCustomerId);
     
    // }
    loadCustomerData();
  }, [
    activeCustomerId,
    selectedCustomers,
    setActiveCustomer,
    fetchCustomer,
    fetchCustomerAnalytics,
    fetchHomePolicies,
    fetchAutoPolicies,
    fetchCommercialAutoPolicies,
    fetchCommercialPropertyPolicies,
  ]);

  const searchQueryHandler = (incomingSearchQuery) => {
    setSearchQuery(incomingSearchQuery);
  };

  const goToSelectedCustomer = (customerId) => {
    navigate(`/service?customer=${customerId}`);
  };

  const toggleModal = (content = null) => {
    setModalContent(content);
    setIsModalOpen((prevValue) => !prevValue);
  };

  const renderPolicies = () => {
    switch (activePolicyFilter) {
      case 1:
        return homePolicies && homePolicies.length > 0
          ? homePolicies.map((policy, index) => (
              <HomePolicy key={index} homePolicy={policy} />
            ))
          : null;
      case 2:
        return autoPolicies && autoPolicies.length > 0
          ? autoPolicies.map((policy, index) => (
              <AutoPolicy key={index} autoPolicy={policy} />
            ))
          : null;
      case 3:
        return commercialPropertyPolicies &&
          commercialPropertyPolicies.length > 0
          ? commercialPropertyPolicies.map((policy, index) => (
              <CommercialPropertyPolicy
                key={index}
                commercialPropertyPolicy={policy}
              />
            ))
          : null;
      case 4:
        return commercialAutoPolicies && commercialAutoPolicies.length > 0
          ? commercialAutoPolicies.map((policy, index) => (
              <CommercialAutoPolicy key={index} commercialAutoPolicy={policy} />
            ))
          : null;
      case 5:
        return null; // Add umbrella policy here in the future
      default:
        return (
          <>
            {homePolicies &&
              homePolicies.length > 0 &&
              homePolicies.map((policy, index) => (
                <HomePolicy key={index} homePolicy={policy} />
              ))}
            {autoPolicies &&
              autoPolicies.length > 0 &&
              autoPolicies.map((policy, index) => (
                <AutoPolicy key={index} autoPolicy={policy} />
              ))}
            {commercialPropertyPolicies &&
              commercialPropertyPolicies.length > 0 &&
              commercialPropertyPolicies.map((policy, index) => (
                <CommercialPropertyPolicy
                  key={index}
                  commercialPropertyPolicy={policy}
                />
              ))}
            {commercialAutoPolicies &&
              commercialAutoPolicies.length > 0 &&
              commercialAutoPolicies.map((policy, index) => (
                <CommercialAutoPolicy
                  key={index}
                  commercialAutoPolicy={policy}
                />
              ))}
          </>
        );
    }
  };

  const printNotesHandler = useReactToPrint({
    contentRef: notesRef,
  });

  const openNotesInNewTab = () => {
    const newTab = window.open("", "_blank", "width=800,height=600");

    if (!newTab || newTab.closed || typeof newTab.closed === "undefined") {
      alert("Popup was blocked. Please allow popups for this website.");
      return;
    }

    newTab.document.body.innerHTML = "<div id='notes-root'></div>";
    newTab.document.title = "Notes";

    const container = newTab.document.getElementById("notes-root");

    const inlineStyles = `
      .note {
        display: block;
        margin-bottom: 10px;
        padding: 10px;
        text-decoration: none;
      }
      .note-grid {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: 10px;
      }
      .col-1 {
        grid-column: span 1 / span 1;
      }
      .col-2 {
        grid-column: span 2 / span 2;
      }
      .col-9 {
        grid-column: span 9 / span 9;
      }
      .note-title, .note-date, .note-content {
        font-size: 12px;
      }
      .note-title:hover, .note-date:hover, .note-content:hover {
        text-decoration: underline;
      }
      .note-title {
        font-weight: normal;
      }
    `;

    const styleTag = newTab.document.createElement("style");
    styleTag.textContent = inlineStyles;
    newTab.document.head.appendChild(styleTag);

    filteredNotes.forEach((note) => {
      const noteElement = document.createElement("div");
      noteElement.className = "note";

      noteElement.innerHTML = `
        <div class="note-grid">
          <div class="col-1">
            <span class="note-title">${note.title}</span>
          </div>
          <div class="col-2">
            <span class="note-date">${note.createdAt}</span>
          </div>
          <div class="col-9">
            <span class="note-content">${note.note}</span>
          </div>
        </div>
      `;
      container.appendChild(noteElement);
    });
  };

  return (
    <>
      <DashboardWrapper>
        {selectedCustomers.length === 0 && (
          <>
            <SearchBar
              searchQuery={searchQuery}
              searchQueryHandler={searchQueryHandler}
            />
            <SearchResults searchQuery={searchQuery} />
          </>
        )}

        {selectedCustomers.length > 0 && (
          <div className="">
            <ActiveTabSection
              selectedCustomers={selectedCustomers}
              goToSelectedCustomer={goToSelectedCustomer}
              activeCustomer={activeCustomer}
            />
            <div className="flex">
              <div className="w-[100%] flex flex-col">
                <div className="flex">
                  <div className="flex w-[60%]">
                    <CustomerCardSection
                      activeCustomer={activeCustomer}
                      locale={locale}
                      toggleModal={() =>
                        toggleModal(
                          <div className="mt-4 w-[771px]">
                            <CommonCard
                              title={langL10n.AddEditPersonalInfoText[locale]}
                              buttons={
                                <div className="flex gap-2">
                                  <button
                                    onClick={() => toggleModal(null)}
                                    className="w-5 h-5 rounded-sm bg-primary flex justify-center items-center"
                                  >
                                    <XMarkIcon className="w-4 h-4 text-white" />
                                  </button>
                                </div>
                              }
                            >
                              <CustomerIntakeForm />
                            </CommonCard>
                          </div>
                        )
                      }
                    />
                    <AccountSummarySection
                      activeCustomer={activeCustomer}
                      locale={locale}
                    />
                  </div>
                  <div className="flex flex-1 flex-col h-[50%]">
                    <AnalyticsSection customerAnalytics={customerAnalytics} />
                  </div>
                </div>
                <div className="flex mt-3">
                  <div className="w-[60%]">
                    <PolicyFilters
                      activePolicyFilter={activePolicyFilter}
                      setActivePolicyFilter={setActivePolicyFilter}
                      isHomePolicyAvailable={homePolicies !== null}
                      isAutoPolicyAvailable={autoPolicies !== null}
                      isCommercialPropertyPolicyAvailable={
                        commercialPropertyPolicies  !== null
                      }
                      isCommercialAutoPolicyAvailable={
                        commercialAutoPolicies !== null
                      }
                      isUmbrellaPolicyAvailable={false} // change this to !umbrellaPolicy later
                    />
                  </div>
                  <ClientToolFilters
                    activeClientToolFilter={activeClientToolFilter}
                    setActiveClientToolFilter={setActiveClientToolFilter}
                  />
                </div>
              </div>
            </div>
            <div className="w-[100%] flex">
              <div className="flex w-[60%]">
                <div className="flex flex-col w-full gap-2 mt-2">
                  {renderPolicies()}
                  <Modal isOpen={isModalOpen}>{modalContent}</Modal>
                </div>
              </div>

              <div className="flex w-[40%] mt-2 h-fit">
                <CommonCard
                  icon={<DashboardServiceUploadedDocumentsIcon className="w-6 h-6 text-primary mr-2" />}
                  title={langL10n.NotesText[locale]}
                  buttons={
                    <>
                      <NotesFilters
                        activeNotesFilters={activeNotesFilters}
                        setActiveNotesFilters={setActiveNotesFilters}
                      />
                      <button
                        className="w-6 h-6 mr-2 relative right-1"
                        onClick={printNotesHandler}
                      >
                        <DashboardNavigationMenuPrintIcon className="w-6 h-6 text-lightGray mr-2 hover:text-primary" />
                      </button>

                      <button
                        className="w-6 h-6 mr-2 relative right-1"
                        onClick={openNotesInNewTab}
                      >
                        <DashboardServiceNewTabIcon className="w-6 h-6 text-lightGray mr-2 hover:text-primary" />
                      </button>
                      <button
                        className="w-6 h-6 mr-2 relative right-1"
                        onClick={() =>
                          toggleModal(
                            <div className="mt-4 w-[500px]">
                              <CommonCard
                                icon={
                                  <DashboardServiceCustomerNotesIcon className="w-6 h-6 text-primary mr-2" />
                                }
                                title={langL10n.AddNoteText[locale]}
                                buttons={
                                  <div className="flex gap-2">
                                    <button
                                      onClick={() => toggleModal(null)}
                                      className="w-5 h-5 rounded-sm bg-primary flex justify-center items-center"
                                    >
                                      <XMarkIcon className="w-4 h-4 text-white" />
                                    </button>
                                  </div>
                                }
                              >
                                <AddNoteForm />
                              </CommonCard>
                            </div>
                          )
                        }
                      >
                        <DashboardNavigationMenuQuickAddIcon className="w-6 h-6 text-lightGray mr-2 hover:text-primary" />
                      </button>

                    </>
                  }
                >
                  <div>
                    <Notes notes={filteredNotes} />
                  </div>
                  <div className="hidden">
                    <div className="flex flex-col gap-4 p-4" ref={notesRef}>
                      <AgencyLogo darkMode={false} />
                      <Notes notes={filteredNotes} />
                    </div>
                  </div>
                </CommonCard>
              </div>
            </div>
          </div>
        )}
      </DashboardWrapper>
    </>
  );
};

function mapStateToProps(state) {
  return {
    activeCustomer: selectActiveCustomer(state),
    selectedCustomers: selectSelectedCustomers(state),
    customerAnalytics: selectCustomerAnalytics(state),
    activePolicyFilter: selectActivePolicyFilter(state),
    activeClientToolFilter: selectActiveClientToolFilter(state),
    activeNotesFilters: selectActiveNotesFilters(state),
    homePolicies: selectHomePolicies(state),
    autoPolicies: selectAutoPolicies(state),
    commercialAutoPolicies: selectCommercialAutoPolicies(state),
    commercialPropertyPolicies: selectCommercialPropertyPolicies(state),
    filteredNotes: selectFilteredNotes(state),
  };
}

const mapDispatchToProps = {
  setActiveCustomer,
  removeSelectedCustomer,
  setActivePolicyFilter,
  setActiveClientToolFilter,
  setActiveNotesFilters,
  fetchCustomer,
  fetchCustomerAnalytics,
  fetchHomePolicies,
  fetchAutoPolicies,
  fetchCommercialAutoPolicies,
  fetchCommercialPropertyPolicies,
  fetchCustomerNotes,
};

const connectedRoutes = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceDashboard);

export default withRouter(connectedRoutes);
