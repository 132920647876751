import React, { useEffect, useState } from "react";

import { Link } from 'react-router';

import { connect } from "react-redux";

import { addSelectedCustomer } from "../../data/serviceDashboard.slice";

import tempData from "../../data/tempData.json";
import { DashboardNavigationMenuSearchIcon } from "components/Icons";

const SearchResults = ({ searchQuery, addSelectedCustomer }) => {
  const [searchResults, setSearchResults] = useState({
    customers: [],
    documents: [],
    others: [],
  });
  const [recentSearches, setRecentSearches] = useState([]);
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  useEffect(() => {
    searchQueryHandler();

    if (searchQuery.trim()) {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      const timeout = setTimeout(() => {
        addToRecentSearches(searchQuery);
      }, 500);

      setDebounceTimeout(timeout);
    }
  }, [searchQuery]);

  const customerSelectionHandler = (customerId) => {
    addSelectedCustomer(customerId);
  };

  const searchQueryHandler = () => {
    if (!searchQuery.trim()) {
      setSearchResults({
        customers: [],
        documents: [],
        others: [],
      });
      return;
    }

    const lowercaseQuery = searchQuery.trim().toLowerCase();
    const matchedCustomers = new Map();

    tempData.homePolicies.forEach((policy) => {
      const { customerId, propertyAddress } = policy;
      const addressMatch =
        propertyAddress.addressLine1.toLowerCase().includes(lowercaseQuery) ||
        propertyAddress.cityAndState.toLowerCase().includes(lowercaseQuery) ||
        propertyAddress.zipCode.includes(lowercaseQuery);

      if (addressMatch) {
        const customer = tempData.customers.find(
          (customer) => customer.id === customerId
        );
        if (customer) {
          matchedCustomers.set(customerId, customer.nameInsured);
        }
      }
    });

    tempData.autoPolicies.forEach((policy) => {
      const { customerId, generalPolicyInfo, vehicles, drivers } = policy;

      const garagingAddressMatch =
        generalPolicyInfo.garagingAddress.addressLine1
          .toLowerCase()
          .includes(lowercaseQuery) ||
        generalPolicyInfo.garagingAddress.city
          .toLowerCase()
          .includes(lowercaseQuery) ||
        generalPolicyInfo.garagingAddress.zip.includes(lowercaseQuery);

      const vinMatch = vehicles.some((vehicle) =>
        vehicle.vin.toLowerCase().includes(lowercaseQuery)
      );

      const driverMatch = drivers.some(
        (driver) =>
          driver.name.toLowerCase().includes(lowercaseQuery) ||
          driver.driversLicense.toLowerCase().includes(lowercaseQuery)
      );

      if (garagingAddressMatch || vinMatch || driverMatch) {
        const customer = tempData.customers.find(
          (customer) => customer.id === customerId
        );
        if (customer) {
          matchedCustomers.set(customerId, customer.nameInsured);
        }
      }
    });

    tempData.commercialAutoPolicies.forEach((policy) => {
      const { customerId, generalPolicyInfo, vehicles, drivers } = policy;

      const garagingAddressMatch =
        generalPolicyInfo.garagingAddress.addressLine1
          .toLowerCase()
          .includes(lowercaseQuery) ||
        generalPolicyInfo.garagingAddress.city
          .toLowerCase()
          .includes(lowercaseQuery) ||
        generalPolicyInfo.garagingAddress.zip.includes(lowercaseQuery);

      const vinMatch = vehicles.some((vehicle) =>
        vehicle.vin.toLowerCase().includes(lowercaseQuery)
      );

      const driverMatch = drivers.some(
        (driver) =>
          driver.name.toLowerCase().includes(lowercaseQuery) ||
          driver.driversLicense.toLowerCase().includes(lowercaseQuery)
      );

      if (garagingAddressMatch || vinMatch || driverMatch) {
        const customer = tempData.customers.find(
          (customer) => customer.id === customerId
        );
        if (customer) {
          matchedCustomers.set(customerId, customer.nameInsured);
        }
      }
    });

    tempData.commercialPropertyPolicies.forEach((policy) => {
      const { customerId, propertyAddress } = policy;
      const addressMatch =
        propertyAddress.addressLine1.toLowerCase().includes(lowercaseQuery) ||
        propertyAddress.cityAndState.toLowerCase().includes(lowercaseQuery) ||
        propertyAddress.zipCode.includes(lowercaseQuery);

      if (addressMatch) {
        const customer = tempData.customers.find(
          (customer) => customer.id === customerId
        );
        if (customer) {
          matchedCustomers.set(customerId, customer.nameInsured);
        }
      }
    });

    tempData.customers.forEach((customer) => {
      const nameMatch = customer.nameInsured
        .toLowerCase()
        .includes(lowercaseQuery);

      if (nameMatch) {
        matchedCustomers.set(customer.id, customer.nameInsured);
      }
    });

    const customerResults = Array.from(
      matchedCustomers.entries(),
      ([customerId, name]) => ({ customerId, name })
    );

    setSearchResults((prevResults) => ({
      ...prevResults,
      customers: customerResults,
    }));
  };

  const addToRecentSearches = (searchQuery) => {
    if (!recentSearches.includes(searchQuery)) {
      setRecentSearches((prevSearches) => [
        searchQuery,
        ...prevSearches.slice(0, 4),
      ]);
    }
  };

  const clearSearchResults = () => {
    setSearchResults({
      customers: [],
      documents: [],
      others: [],
    });
  };

  const clearRecentSearches = () => {
    setRecentSearches([]);
  };

  return (
    <>
      <div className="bg-white mt-2 p-4 rounded-lg shadow-md">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-md font-semibold text-orange-600">
            Search results
          </h2>
          <button onClick={clearSearchResults} className="text-lightGray">
            Clear
          </button>
        </div>

        {/* Customer Results */}
        {searchResults.customers.length !== 0 ? (
          <div>
            <h3 className="text-sm font-semibold text-orange-600">
              Customer results
            </h3>
            <ul className="ml-4 mt-2 space-y-2">
              {searchResults.customers.map(({ customerId, name }, index) => (
                <li key={index} className="flex items-center space-x-2">
                  <DashboardNavigationMenuSearchIcon className="h-4 w-4" />
                  <Link
                    to={`/service?customer=${customerId}`}
                    onClick={() => customerSelectionHandler(customerId)}
                  >
                    <span className="text-sm">
                      {name} (ID: {customerId})
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <p className="text-gray-400">No customers found.</p>
        )}
      </div>

      {/* Recent Searches */}
      <div className="bg-white mt-2 p-4 rounded-lg shadow-md">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-md font-semibold text-orange-600">
            Recent searches
          </h2>
          <button onClick={clearRecentSearches} className="text-lightGray">
            Clear
          </button>
        </div>

        {recentSearches.length > 0 ? (
          recentSearches.map((search, index) => (
            <ul className="ml-4 mt-2 space-y-2" key={index}>
              <li className="flex items-center space-x-2">
                <DashboardNavigationMenuSearchIcon className="h-4 w-4" />
                <Link to="#">
                  <span className="text-sm">{search}</span>
                </Link>
              </li>
            </ul>
          ))
        ) : (
          <p className="text-gray-400">No recent searches</p>
        )}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  addSelectedCustomer,
};

const connectedRoutes = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResults);

export default connectedRoutes;
