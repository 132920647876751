import React, { useEffect } from "react";
import {
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { useParams } from "react-router";
import { connect } from "react-redux";

import { withRouter } from "middleware/withRouter";

import DashboardWrapper from "layouts/DashboardWrapper";
import CommonCard from "components/CommonCard";

import langL10n from "./lang-l10n";

import { selectNote } from "../../data/notes.slice";

import { fetchNote } from "../../data/actions";
import { DashboardNavigationMenuPrintIcon, DashboardServiceCustomerNotesIcon } from "components/Icons";

const Notes = ({ note, fetchNote, locale = "en-US" }) => {
  const { noteId } = useParams();

  useEffect(() => {
    if (noteId) {
      fetchNote(noteId);
    }
  }, [noteId, fetchNote]);

  return (
    <DashboardWrapper>
      <div className="w-full h-[400px]">
        <CommonCard
          icon={<DashboardServiceCustomerNotesIcon className="w-6 h-6 text-primary mr-2" />}
          title={langL10n.NotesText[locale]}
          buttons={
            <>
              <button
                className="w-6 h-6 mr-2 relative right-1"
                onClick={() => {}}
              >
                <PencilSquareIcon className="w-6 h-6 text-lightGray mr-2 hover:text-primary" />
              </button>
              <button
                className="w-6 h-6 mr-2 relative right-1"
                onClick={() => {}}
              >
                <DashboardNavigationMenuPrintIcon className="w-6 h-6 text-lightGray mr-2 hover:text-primary" />
              </button>
            </>
          }
        >
          <article className="flex gap-2 flex-col">
            <div className="flex justify-between flex-1">
              <h2 className="text-sm font-bold"> {note?.title} </h2>
              <span className="text-xs"> {note?.createdAt} </span>
            </div>
            <p className="text-xs">{note?.note}</p>
          </article>
        </CommonCard>
      </div>
    </DashboardWrapper>
  );
};

function mapStateToProps(state) {
  return {
    note: selectNote(state),
  };
}

const mapDispatchToProps = {
  fetchNote,
};

const connectedRoutes = connect(mapStateToProps, mapDispatchToProps)(Notes);

export default withRouter(connectedRoutes);
