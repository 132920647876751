import React from "react";

export default function DashboardNavigationMenuNotificationsIcon({ className, viewBox, fill }) {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox={viewBox ? `${viewBox}` : "0 0 100 100"}
            className={className ? `${className}` : "w-6 h-6 text-primary mr-2"}
            fill={fill ? `${fill}` : "currentColor"}
        >
            <defs>
                <style>
                </style>
            </defs>
            <path d="M28.3,24.4c.4.5.9.8,1.6.8s.9,0,1.3-.5c.4-.3.7-.8.8-1.3,0-.6-.1-1.1-.5-1.6l-8.1-9.7c-.3-.4-.8-.7-1.3-.8-.6,0-1.2.1-1.6.5-.4.3-.7.8-.8,1.3,0,.6.1,1.1.5,1.6l8.1,9.7ZM70.1,25.2c.6,0,1.2-.3,1.6-.8l8.1-9.7c.4-.4.6-.9.5-1.4,0-.6-.3-1.1-.7-1.5-.4-.4-.8-.6-1.4-.5-.6,0-1.1.3-1.5.8l-8.1,9.7c-.4.4-.6.9-.5,1.4,0,.6.3,1.1.7,1.5.4.4.8.5,1.4.5ZM19.2,43.7h0c1,0,1.9-.8,2-1.7.1-1.1-.6-2.1-1.7-2.4l-12.5-2.2c-1.1-.1-2.1.6-2.4,1.7-.1,1.1.6,2.1,1.7,2.4l12.6,2.2h.3ZM50,17.9c1.2,0,2.1-.9,2.1-2.1V3.2c0-1.2-.9-2.1-2.1-2.1s-2.1.9-2.1,2.1v12.6c0,1.2.9,2.1,2.1,2.1ZM95.3,39.1c0-.6-.4-1.1-.8-1.4-.5-.3-1-.4-1.6-.3l-12.5,2.2c-.6,0-1.1.4-1.4.8-.3.5-.4,1-.3,1.6.3,1,1,1.6,2,1.7h0s12.8-2.2,12.8-2.2c.6,0,1.1-.4,1.4-.8.3-.5.4-1,.3-1.5ZM89,67.2l-10.9-6.3c-1.1-.5-2.4-.2-2.9.8-.3.5-.3,1.1-.2,1.6.1.6.5,1,1,1.3l10.9,6.3c.4.3.7.3,1.1.3.8,0,1.4-.4,1.8-1.1.6-1.1.2-2.4-.8-2.9ZM21.9,60.9l-10.9,6.3c-1,.6-1.3,1.8-.8,2.9.4.6,1.1,1,1.8,1s.6,0,.8-.2l11.2-6.4c1-.6,1.3-1.8.8-2.9-.6-1-1.8-1.3-2.9-.8ZM65.8,29.2c-5.1-4.6-11.8-6.7-18.7-5.9-11,1.2-19.8,10.1-21,21-.6,5.7.7,11.3,3.9,15.9,5.5,8.2,8.6,17.6,9,27.2v1.2c.2,5.3,4.3,9.6,9.4,10.1l-.2.2h1.2c5.7,0,10.4-4.5,10.6-10.2.3-10,3.6-19.7,9.4-28,2.7-3.9,4.3-6.7,4.3-13.7s-2.9-13.4-8-17.9ZM56,88.6c0,3.4-3,6.1-6.4,6.1s-6.2-2.7-6.4-6.1v-1.2c0-2.2-.3-4.4-.6-6.6,1.7.6,4.1,1.1,7,1.1h.1c2.5,0,4.9-.4,7.1-1.1-.5,2.6-.7,5.2-.8,7.8ZM66.2,58.3c-3.8,5.4-6.5,11.3-8.2,17.5-9,4.2-16.1,0-16.2,0,0,0,0,0-.1,0-1.6-6.3-4.3-12.3-8-17.9-2.6-3.9-3.7-8.4-3.2-13.2,1-9.1,8.3-16.4,17.4-17.4.7,0,1.5,0,2.2,0,4.9,0,9.5,1.8,13.2,5.1,4.3,3.9,6.5,11,6.5,14.7s-1.2,8-3.5,11.2Z" />
        </svg>
    );
}