import { createSlice } from "@reduxjs/toolkit";
import { apiCall } from "../services/api";

const initialState = {
  currentCustomer: null,
};

export const salesDashboardSlice = createSlice({
  name: "salesDashboard",
  initialState,
  reducers: {
    setActiveCustomer: (state, action) => {
      state.currentCustomer = action.payload;
    },
    resetCurrentCustomer: (state) => {
      state.currentCustomer = null;
    },
  },
});

export const { setActiveCustomer, resetCurrentCustomer } = salesDashboardSlice.actions;

// Selectors
export const selectActiveCustomer = (state) => state.customer.currentCustomer;

export default salesDashboardSlice.reducer;
