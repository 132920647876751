import React from "react";
import { Routes, Route } from "react-router";
import { connect } from "react-redux";
import { withRouter } from "middleware/withRouter";

import Dashboard from "components/Dashboard";
import SearchPage from "components/SearchPage";
import ServiceDashboard from "components/ServiceDashboard";
import SalesDashboard from "../components/SalesDashboard";
import Notes from "components/Notes";

import { AdminRoute, AgentRoute } from "middleware/restricted";
import { selectIsAuthenticated, logout, authUser } from "../data/user.slice";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/search" element={<SearchPage />} />
      <Route path="/service" element={<ServiceDashboard />} />
      <Route path="/sales-dashboard" element={<SalesDashboard />} />
      <Route path="/notes/:noteId" element={<Notes />} />
      <Route path="/agent" element={<AgentRoute component={Dashboard} />} />
      <Route path="/admin" element={<AdminRoute component={Dashboard} />} />
    </Routes>
  );
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    errors: state.errors,
  };
}

const connectedRoutes = connect(mapStateToProps, {
  selectIsAuthenticated,
  logout,
  authUser,
})(AppRoutes);

export default withRouter(connectedRoutes);
