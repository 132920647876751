import React from "react";
import langL10n from "./lang-l10n";

const AgencyLogo = ({ darkMode = true, locale = "en-US" }) => {
  return (
    <img
      className={`mx-auto w-32 transition-all duration-300 ${darkMode ? "grayscale invert" : ""}`}
      src="/pcs-logo.webp"
      alt={langL10n.AltText[locale]}
    />
  );
};

export default AgencyLogo;
