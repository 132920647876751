import React from "react";

import langL10n from "./lang-l10n";

export default function CustomerIntakeForm({ locale = "en-US" }) {
  return (
    <div className="p-4 bg-white rounded-lg flex flex-col gap-4">
      <div className="flex border-b pb-4 gap-4">
        <div className="w-[55%]">
          <div className="grid grid-cols-12">
            <div className="col-span-2 flex flex-col gap-1">
              <label className="text-sm text-primary">
                {langL10n.TitleText[locale]}
              </label>
              <select className="rounded-md bg-lightGray text-xs w-18 h-6">
                <option>Mr</option>
                <option>Mrs</option>
              </select>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-4 mt-2">
            <div className="col-span-6 flex flex-col gap-1">
              <label className="text-sm text-primary">
                {langL10n.FirstNameText[locale]}
              </label>
              <input className="rounded-md bg-lightGray text-xs h-6" />
            </div>
            <div className="col-span-6 flex flex-col gap-1">
              <label className="text-sm text-primary">
                {langL10n.MiddleNameText[locale]}
              </label>
              <input className="rounded-md bg-lightGray text-xs h-6" />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-4 mt-2">
            <div className="col-span-12 flex flex-col gap-1">
              <label className="text-sm text-primary">
                {langL10n.LastNameText[locale]}
              </label>
              <input className="rounded-md bg-lightGray text-xs h-6" />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-4 mt-2">
            <div className="col-span-6 flex flex-col gap-1">
              <label className="text-sm text-primary">
                {langL10n.SuffixText[locale]}
              </label>
              <select className="rounded-md bg-lightGray text-xs w-16 h-6">
                <option>Mr</option>
                <option>Mrs</option>
              </select>
            </div>
            <div className="col-span-6 flex flex-col gap-2">
              <label className="text-sm text-primary">
                {langL10n.NameInsuredText[locale]}
              </label>
              <input
                type="checkbox"
                className="form-checkbox rounded-sm text-primary"
                checked={false}
                readOnly
              />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-4 mt-2">
            <div className="col-span-6 flex flex-col gap-1">
              <label className="text-sm text-primary">
                {langL10n.PreferredNameText[locale]}
              </label>
              <input className="rounded-md bg-lightGray text-xs h-6" />
            </div>
            <div className="col-span-6 flex flex-col gap-2">
              <label className="text-sm text-primary">
                {langL10n.DeceasedText[locale]}
              </label>
              <input
                type="checkbox"
                className="form-checkbox rounded-sm text-primary"
                checked={false}
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="w-[45%]">
          <div className="border-b border-secondary pb-4 px-1">
            <div className="grid grid-cols-12">
              <div className="col-span-9 flex flex-col mb-2 gap-1">
                <label className="text-sm text-primary">
                  {langL10n.ContactInfoText[locale]}
                </label>
              </div>
              <div className="col-span-3 flex flex-col gap-1 text-right">
                <label className="text-sm text-primary">
                  {langL10n.PrimaryText[locale]}
                </label>
              </div>
            </div>

            {/* Home Section */}
            <div className="grid grid-cols-12 mb-2 items-center">
              <div className="col-span-9 flex flex-col gap-1">
                <div className="grid grid-cols-12 items-center gap-2 pl-6">
                  <label className="col-span-3 text-xs text-right">
                    {langL10n.HomeText[locale]}
                  </label>
                  <input className="col-span-3 rounded-md bg-lightGray text-xs w-full h-6" />
                  <input className="col-span-6 rounded-md bg-lightGray text-xs w-3/4 h-6" />
                </div>
              </div>
              <div className="col-span-3 flex flex-col gap-1 text-right">
                <input
                  type="checkbox"
                  className="form-checkbox rounded-sm text-primary self-end"
                  checked={false}
                  readOnly
                />
              </div>
            </div>

            {/* Mobile Section */}
            <div className="grid grid-cols-12 mb-2 items-center">
              <div className="col-span-9 flex flex-col gap-1">
                <div className="grid grid-cols-12 items-center gap-2 pl-6">
                  <label className="col-span-3 text-xs text-right">
                    {langL10n.MobileText[locale]}
                  </label>
                  <input className="col-span-3 rounded-md bg-lightGray text-xs w-full h-6" />
                  <input className="col-span-6 rounded-md bg-lightGray text-xs w-3/4 h-6" />
                </div>
              </div>
              <div className="col-span-3 flex flex-col gap-1 text-right items-center">
                <input
                  type="checkbox"
                  className="form-checkbox rounded-sm text-primary self-end"
                  checked={false}
                  readOnly
                />
              </div>
            </div>

            {/* Other Section */}
            <div className="grid grid-cols-12 items-center">
              <div className="col-span-9 flex flex-col gap-1">
                <div className="grid grid-cols-12 items-center gap-2 pl-6">
                  <label className="col-span-3 text-xs text-right">
                    {langL10n.OtherText[locale]}
                  </label>
                  <input className="col-span-3 rounded-md bg-lightGray text-xs w-full h-6" />
                  <input className="col-span-6 rounded-md bg-lightGray text-xs w-3/4 h-6" />
                </div>
              </div>
              <div className="col-span-3 flex flex-col gap-1 text-right">
                <input
                  type="checkbox"
                  className="form-checkbox rounded-sm text-primary self-end"
                  checked={false}
                  readOnly
                />
              </div>
            </div>
          </div>

          <div className="mt-4 px-1 flex flex-col gap-2">
            {/* Home E-mail Section */}
            <div className="grid grid-cols-12 items-center gap-2">
              <label className="col-span-3 text-xs">
                {langL10n.HomeEmailText[locale]}
              </label>
              <div className="col-span-8">
                <input className="rounded-md bg-lightGray text-sm w-full h-6" />
              </div>
              <div className="col-span-1 flex justify-end">
                <input
                  type="checkbox"
                  className="form-checkbox rounded-sm text-primary"
                  checked={false}
                  readOnly
                />
              </div>
            </div>

            {/* Other E-mail Section */}
            <div className="grid grid-cols-12 items-center gap-2">
              <label className="col-span-3 text-xs">
                {langL10n.OtherEmailText[locale]}
              </label>
              <div className="col-span-8">
                <input className="rounded-md bg-lightGray text-sm w-full h-6" />
              </div>
              <div className="col-span-1 flex justify-end">
                <input
                  type="checkbox"
                  className="form-checkbox rounded-sm text-primary"
                  checked={false}
                  readOnly
                />
              </div>
            </div>

            {/* Preferred Method of Contact Section */}
            <div className="mt-4">
              <h3 className="text-primary">
                {langL10n.PreferredMethodOfContactText[locale]}
              </h3>
              <div className="flex items-center mt-2">
                <label className="flex items-center mr-4">
                  <input
                    type="checkbox"
                    className="form-checkbox text-primary rounded-sm"
                    checked={false}
                    readOnly
                  />
                  <span className="ml-2 text-xs">
                    {langL10n.PhoneText[locale]}
                  </span>
                </label>
                <label className="flex items-center mr-4">
                  <input
                    type="checkbox"
                    className="form-checkbox text-primary rounded-sm"
                    checked={false}
                    readOnly
                  />
                  <span className="ml-2 text-xs">
                    {langL10n.SMSText[locale]}
                  </span>
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox text-primary rounded-sm"
                    checked={false}
                    readOnly
                  />
                  <span className="ml-2 text-xs">
                    {langL10n.EmailText[locale]}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="flex">
          {/* Personal Underwriting Section */}
          <div className="w-[40%] pr-4">
            <label className="text-md text-primary mb-2 block">
              {langL10n.PersonalUnderwritingText[locale]}
            </label>

            <div className="flex flex-col gap-1">
              {/* Marital Status */}
              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-4 text-right">
                  <label className="text-xs">
                    {langL10n.MaritalStatusText[locale]}
                  </label>
                </div>
                <div className="col-span-7">
                  <select className="rounded-md bg-lightGray text-xs w-full h-6 py-0 border-0">
                    <option>Married</option>
                    <option>Single</option>
                  </select>
                </div>
              </div>

              {/* Gender */}
              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-4 text-right">
                  <label className="text-xs">
                    {langL10n.GenderText[locale]}
                  </label>
                </div>
                <div className="col-span-7">
                  <select className="rounded-md bg-lightGray text-xs w-full h-6 py-0 border-0">
                    <option>Male</option>
                    <option>Female</option>
                  </select>
                </div>
              </div>

              {/* Relationship */}
              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-4 text-right">
                  <label className="text-xs">
                    {langL10n.RelationshipText[locale]}
                  </label>
                </div>
                <div className="col-span-7">
                  <select className="rounded-md bg-lightGray text-xs w-full h-6 py-0 border-0">
                    <option>Insured</option>
                  </select>
                </div>
              </div>

              {/* Date of Birth */}
              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-4 text-right">
                  <label className="text-xs">
                    {langL10n.DateOfBirthText[locale]}
                  </label>
                </div>
                <div className="col-span-7">
                  <input
                    type="text"
                    className="rounded-md bg-lightGray text-xs w-full h-6 border-0"
                  />
                </div>
              </div>

              {/* SSN */}
              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-4 text-right">
                  <label className="text-xs">{langL10n.SSNText[locale]}</label>
                </div>
                <div className="col-span-7">
                  <input
                    type="text"
                    className="rounded-md bg-lightGray text-xs w-full h-6 border-0"
                    defaultValue=""
                  />
                </div>
              </div>

              {/* Drivers License */}
              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-4 text-right">
                  <label className="text-xs">
                    {langL10n.DriversLicenseText[locale]}
                  </label>
                </div>
                <div className="col-span-7">
                  <input
                    type="text"
                    className="rounded-md bg-lightGray text-xs w-full h-6 border-0"
                    defaultValue=""
                  />
                </div>
              </div>

              {/* License State */}
              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-4 text-right">
                  <label className="text-xs">
                    {langL10n.LicenseStateText[locale]}
                  </label>
                </div>
                <div className="col-span-7">
                  <input
                    type="text"
                    className="rounded-md bg-lightGray text-xs w-full h-6 border-0"
                    defaultValue=""
                  />
                </div>
              </div>

              {/* Occupation */}
              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-4 text-right">
                  <label className="text-xs">
                    {langL10n.OccupationText[locale]}
                  </label>
                </div>
                <div className="col-span-7">
                  <input
                    type="text"
                    className="rounded-md bg-lightGray text-xs w-full h-6 border-0"
                    defaultValue=""
                  />
                </div>
              </div>

              {/* Education */}
              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-4 text-right">
                  <label className="text-xs">
                    {langL10n.EducationText[locale]}
                  </label>
                </div>
                <div className="col-span-7">
                  <input
                    type="text"
                    className="rounded-md bg-lightGray text-xs w-full h-6 border-0"
                    defaultValue=""
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Personal Information Section */}
          <div className="w-[60%] pl-4 flex flex-col">
            <label className="text-md text-primary mb-2 block">
              {langL10n.PersonalInformationText[locale]}
            </label>

            <div className="flex flex-col gap-1">
              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-3 text-right">
                  <label className="text-xs">
                    {langL10n.AnniversaryDateText[locale]}
                  </label>
                </div>
                <div className="col-span-4">
                  <input
                    type="date"
                    className="rounded-md bg-lightGray text-xs w-full h-6 border-0"
                    defaultValue=""
                  />
                </div>
              </div>

              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-3 text-right">
                  <label className="text-xs">
                    {langL10n.HobbiesText[locale]}
                  </label>
                </div>
                <div className="col-span-9">
                  <input
                    type="text"
                    className="rounded-md bg-lightGray text-xs w-full h-6 border-0"
                    defaultValue=""
                  />
                </div>
              </div>

              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-3 text-right">
                  <label className="text-xs">
                    {langL10n.ResidenceText[locale]}
                  </label>
                </div>
                <div className="col-span-9">
                  <select className="rounded-md bg-lightGray text-xs w-full h-6 py-0 border-0">
                    <option>Select</option>
                  </select>
                </div>
              </div>

              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-3 text-right">
                  <label className="text-xs">
                    {langL10n.CommentsText[locale]}
                  </label>
                </div>
                <div className="col-span-9">
                  <textarea className="rounded-md bg-lightGray text-xs w-full h-[5.3rem] border-0 align-top" />
                </div>
              </div>

              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-3 text-right">
                  <label className="text-xs">
                    {langL10n.EmployerText[locale]}
                  </label>
                </div>
                <div className="col-span-9">
                  <input
                    type="text"
                    className="rounded-md bg-lightGray text-xs w-full h-6 border-0"
                  />
                </div>
              </div>

              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-3 text-right">
                  <label className="text-xs">
                    {langL10n.IncomeText[locale]}
                  </label>
                </div>
                <div className="col-span-9">
                  <input
                    type="text"
                    className="rounded-md bg-lightGray text-xs w-full h-6 border-0"
                  />
                </div>
              </div>

              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-3 text-right">
                  <label className="text-xs">
                    {langL10n.OtherNotesText[locale]}
                  </label>
                </div>
                <div className="col-span-9">
                  <input className="rounded-md bg-lightGray text-xs w-full h-6 border-0" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Buttons Section */}
        <div className="mt-8 text-right flex justify-end gap-4">
          <button className="bg-primary text-white w-28 py-1 rounded">
            {langL10n.CancelButtonText[locale]}
          </button>
          <button className="bg-primary text-white w-28 py-1 rounded">
            {langL10n.SaveButtonText[locale]}
          </button>
        </div>
      </div>
    </div>
  );
}
