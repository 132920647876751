import React from "react";

export default function ActionBar({ actionLinks }) {
  return (
    <div
      className={`fixed bottom-0 bg-white left-0 right-0 text-gray-500 flex justify-end shadow-inner transition-all duration-300 h2}`}
    >
      {actionLinks.map((link, index) => (
        <div key={index} className="flex flex-col items-center py-1 border-r-2">
          {link.icon}
        </div>
      ))}
    </div>
  );
}
