/* eslint-disable max-len */
const langL10n = {
  TitleText: {
    "en-US": "Title",
    "es-US": "Título",
  },
  FirstNameText: {
    "en-US": "First Name",
    "es-US": "Nombre",
  },
  MiddleNameText: {
    "en-US": "Middle Name",
    "es-US": "Segundo Nombre",
  },
  LastNameText: {
    "en-US": "Last Name",
    "es-US": "Apellido",
  },
  SuffixText: {
    "en-US": "Suffix",
    "es-US": "Sufijo",
  },
  NameInsuredText: {
    "en-US": "Name Insured",
    "es-US": "Asegurado",
  },
  PreferredNameText: {
    "en-US": "Preferred Name",
    "es-US": "Nombre Preferido",
  },
  DeceasedText: {
    "en-US": "Deceased",
    "es-US": "Fallecido",
  },
  ContactInfoText: {
    "en-US": "Contact Info",
    "es-US": "Información de Contacto",
  },
  PrimaryText: {
    "en-US": "Primary",
    "es-US": "Primario",
  },
  HomeText: {
    "en-US": "Home",
    "es-US": "Casa",
  },
  MobileText: {
    "en-US": "Mobile",
    "es-US": "Móvil",
  },
  OtherText: {
    "en-US": "Other",
    "es-US": "Otro",
  },
  HomeEmailText: {
    "en-US": "Home E-mail",
    "es-US": "Correo Electrónico de Casa",
  },
  OtherEmailText: {
    "en-US": "Other E-mail",
    "es-US": "Otro Correo Electrónico",
  },
  PreferredMethodOfContactText: {
    "en-US": "Preferred Method of Contact",
    "es-US": "Método de Contacto Preferido",
  },
  PhoneText: {
    "en-US": "Phone",
    "es-US": "Teléfono",
  },
  SMSText: {
    "en-US": "SMS",
    "es-US": "SMS",
  },
  EmailText: {
    "en-US": "E-Mail",
    "es-US": "Correo Electrónico",
  },
  PersonalUnderwritingText: {
    "en-US": "Personal Underwriting",
    "es-US": "Suscripción Personal",
  },
  MaritalStatusText: {
    "en-US": "Marital Status",
    "es-US": "Estado Civil",
  },
  GenderText: {
    "en-US": "Gender",
    "es-US": "Género",
  },
  RelationshipText: {
    "en-US": "Relationship",
    "es-US": "Relación",
  },
  DateOfBirthText: {
    "en-US": "Date of Birth",
    "es-US": "Fecha de Nacimiento",
  },
  SSNText: {
    "en-US": "SSN",
    "es-US": "Número de Seguro Social",
  },
  DriversLicenseText: {
    "en-US": "Drivers License",
    "es-US": "Licencia de Conducir",
  },
  LicenseStateText: {
    "en-US": "License: State",
    "es-US": "Licencia: Estado",
  },
  OccupationText: {
    "en-US": "Occupation",
    "es-US": "Ocupación",
  },
  EducationText: {
    "en-US": "Education",
    "es-US": "Educación",
  },
  PersonalInformationText: {
    "en-US": "Personal Information",
    "es-US": "Información Personal",
  },
  AnniversaryDateText: {
    "en-US": "Anniversary Date",
    "es-US": "Fecha de Aniversario",
  },
  HobbiesText: {
    "en-US": "Hobbies",
    "es-US": "Pasatiempos",
  },
  ResidenceText: {
    "en-US": "Residence",
    "es-US": "Residencia",
  },
  CommentsText: {
    "en-US": "Comments",
    "es-US": "Comentarios",
  },
  EmployerText: {
    "en-US": "Employer",
    "es-US": "Empleador",
  },
  IncomeText: {
    "en-US": "Income",
    "es-US": "Ingresos",
  },
  OtherNotesText: {
    "en-US": "Other Notes",
    "es-US": "Otras Notas",
  },
  CancelButtonText: {
    "en-US": "Cancel",
    "es-US": "Cancelar",
  },
  SaveButtonText: {
    "en-US": "Save",
    "es-US": "Guardar",
  },
};

export default langL10n;
