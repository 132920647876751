import { createSlice, current } from "@reduxjs/toolkit";

import tempData from "./tempData.json";

import {
  fetchCustomer,
  fetchCustomerAnalytics,
  fetchHomePolicies,
  fetchAutoPolicies,
  fetchCommercialAutoPolicies,
  fetchCommercialPropertyPolicies,
  fetchCustomerNotes,
} from "./actions";

const initialState = {
  selectedCustomers: [],
  activeCustomer: null,
  customerAnalytics: null,
  activePolicyFilter: 0,
  activeNotesFilters: [],
  homePolicies: [],
  autoPolicies: [],
  commercialAutoPolicies: [],
  commercialPropertyPolicies: [],
  activeClientToolFilter: null,
  notes: [],
  filteredNotes: [],
  loading: false,
  error: null,
};

export const serviceDashboardSlice = createSlice({
  name: "serviceDashboard",
  initialState,
  reducers: {
    addSelectedCustomer: (state, action) => {

      const customer = tempData.customers.find(
        (customer) => customer.id === action.payload
      );

      if (customer) {
        const customerExists = state.selectedCustomers.some(
          (selectedCustomer) => selectedCustomer.id === customer.id
        );

        if (!customerExists) {
          state.activeCustomer = customer;
          state.selectedCustomers.push(customer);
        }
      }
    },
    removeSelectedCustomer: (state, action) => {
      state.selectedCustomers = state.selectedCustomers.filter(
        (customer) => customer.id !== action.payload
      );
      if (state.activeCustomer && state.activeCustomer.id === action.payload) {
        state.activeCustomer = null;
      }
    },
    setActiveCustomer: (state, action) => {
      state.activeCustomer = action.payload;
      if (!state.selectedCustomers.find((u) => u.id === action.payload.id)) {
        state.selectedCustomers.push(action.payload);
      }
    },
    setCustomerAnalytics: (state, action) => {
      state.customerAnalytics = action.payload;
    },
    resetServiceDashboard: (state) => {
      state.activeCustomer = null;
      state.selectedCustomers = [];
    },
    setActivePolicyFilter: (state, action) => {
      state.activePolicyFilter = action.payload;
    },
    setActiveClientToolFilter: (state, action) => {
      state.activeClientToolFilter = action.payload;
    },
    setActiveNotesFilters: (state, action) => {
      const activeFilters = action.payload;
      const availableNotes = current(state).notes;
      state.activeNotesFilters = activeFilters;
      if (activeFilters.includes("default")) {
        state.filteredNotes = availableNotes;
      } else {
        const uniqueNoteIds = new Set();
        const filteredNotes = availableNotes.filter((note) => {
          if (
            activeFilters.includes(note.policy) &&
            !uniqueNoteIds.has(note.id)
          ) {
            uniqueNoteIds.add(note.id);
            return true;
          }
          return false;
        });
        state.filteredNotes = filteredNotes;
      }
    },
    setHomePolicy: (state, action) => {
      state.homePolicies = action.payload;
    },
    setAutoPolicy: (state, action) => {
      state.autoPolicies = action.payload;
    },
    setCommercialAutoPolicy: (state, action) => {
      state.commercialAutoPolicies = action.payload;
    },
    setCommercialPropertyPolicy: (state, action) => {
      state.commercialPropertyPolicies = action.payload;
    },
    setNotes: (state, action) => {
      state.notes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.activeUser = action.payload;
      })
      .addCase(fetchCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchCustomerAnalytics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomerAnalytics.fulfilled, (state, action) => {
        state.loading = false;
        state.customerAnalytics = action.payload;
      })
      .addCase(fetchCustomerAnalytics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchHomePolicies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHomePolicies.fulfilled, (state, action) => {
        state.loading = false;
        state.homePolicies = action.payload;
      })
      .addCase(fetchHomePolicies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchAutoPolicies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAutoPolicies.fulfilled, (state, action) => {
        state.loading = false;
        state.autoPolicies = action.payload;
      })
      .addCase(fetchAutoPolicies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchCommercialAutoPolicies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCommercialAutoPolicies.fulfilled, (state, action) => {
        state.loading = false;
        state.commercialAutoPolicies = action.payload;
      })
      .addCase(fetchCommercialAutoPolicies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchCommercialPropertyPolicies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCommercialPropertyPolicies.fulfilled, (state, action) => {
        state.loading = false;
        state.commercialPropertyPolicies = action.payload;
      })
      .addCase(fetchCommercialPropertyPolicies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchCustomerNotes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomerNotes.fulfilled, (state, action) => {
        state.loading = false;
        state.filteredNotes = action.payload;
        state.notes = action.payload;
      })
      .addCase(fetchCustomerNotes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  addSelectedCustomer,
  setActiveCustomer,
  setCustomerAnalytics,
  removeSelectedCustomer,
  resetServiceDashboard,
  setActivePolicyFilter,
  setActiveClientToolFilter,
  setActiveNotesFilters,
  setHomePolicies,
  setAutoPolicies,
  setCommercialAutoPolicies,
  setCommercialPropertyPolicies,
  setNotes,
} = serviceDashboardSlice.actions;

// Selectors
export const selectActiveCustomer = (state) =>
  state.serviceDashboard.activeCustomer;
export const selectCustomerAnalytics = (state) =>
  state.serviceDashboard.customerAnalytics;
export const selectSelectedCustomers = (state) =>
  state.serviceDashboard.selectedCustomers;
export const selectActivePolicyFilter = (state) =>
  state.serviceDashboard.activePolicyFilter;
export const selectActiveClientToolFilter = (state) =>
  state.serviceDashboard.activeClientToolFilter;
export const selectActiveNotesFilters = (state) =>
  state.serviceDashboard.activeNotesFilters;
export const selectHomePolicies = (state) =>
  state.serviceDashboard.homePolicies;
export const selectAutoPolicies = (state) =>
  state.serviceDashboard.autoPolicies;
export const selectCommercialAutoPolicies = (state) =>
  state.serviceDashboard.commercialAutoPolicies;
export const selectCommercialPropertyPolicies = (state) =>
  state.serviceDashboard.commercialPropertyPolicies;
export const selectFilteredNotes = (state) =>
  state.serviceDashboard.filteredNotes;

export default serviceDashboardSlice.reducer;
