import React from "react";

export default function DashboardNavigationMenuPrintIcon({ className, viewBox, fill }) {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox={viewBox ? `${viewBox}` : "0 0 100 100"}
            className={className ? `${className}` : "w-6 h-6 text-primary mr-2"}
            fill={fill ? `${fill}` : "currentColor"}
        >
            <path d="M85.9,38h-3.4v-18.8c0-6.6-5.4-12-12-12H29.5c-6.6,0-12,5.4-12,12v18.8h-3.4c-6.6,0-12,5.4-12,12v20.5c0,6.6,5.4,12,12,12h3.5c.9,5.8,5.8,10.3,11.9,10.3h41c6,0,11-4.5,11.9-10.3h3.5c6.6,0,12-5.4,12-12v-20.5c0-6.6-5.4-12-12-12ZM21,19.2c0-4.7,3.8-8.5,8.5-8.5h41c4.7,0,8.5,3.8,8.5,8.5v18.8H21v-18.8ZM79,80.8c0,4.7-3.8,8.5-8.5,8.5H29.5c-4.7,0-8.5-3.8-8.5-8.5v-10.3c0-4.7,3.8-8.5,8.5-8.5h41c4.7,0,8.5,3.8,8.5,8.5v10.3ZM94.4,70.5c0,4.7-3.8,8.5-8.5,8.5h-3.4v-8.5c0-6.6-5.4-12-12-12H29.5c-6.6,0-12,5.4-12,12v8.5h-3.4c-4.7,0-8.5-3.8-8.5-8.5v-20.5c0-4.7,3.8-8.5,8.5-8.5h71.8c4.7,0,8.5,3.8,8.5,8.5v20.5Z" />
        </svg>
    );
}