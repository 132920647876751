import React from 'react';

export default function DashboardServiceAllPoliciesIcon({ className, viewBox, fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox={viewBox ? `${viewBox}` : "0 0 100 100"}
      className={className ? `${className}` : "w-6 h-6 text-primary mr-2"}
      fill={fill ? `${fill}` : "currentColor"}
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>
        </style>
      </defs>
      <path
        d="M42.8,57.2h14.4v-14.4h-14.4v14.4ZM2.5,16.9h14.4V2.5H2.5v14.4ZM2.5,57.2h14.4v-14.4H2.5v14.4ZM2.5,97.5h14.4v-14.4H2.5v14.4ZM42.8,97.5h14.4v-14.4h-14.4v14.4ZM83.1,2.5v14.4h14.4V2.5h-14.4ZM42.8,16.9h14.4V2.5h-14.4v14.4ZM83.1,57.2h14.4v-14.4h-14.4v14.4ZM83.1,97.5h14.4v-14.4h-14.4v14.4Z"
      />
    </svg>
  );
}