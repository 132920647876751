import React, { useState } from "react";
import CommonCard from "components/CommonCard";
import DetailsButton from "components/DetailsButton";
import TruncatorText from "components/TruncateText";
import { convertTimestamp } from "lib/convertors";
import langL10n from "./lang-l10n";
import { DashboardServiceCustomerNotesIcon, DashboardServiceHomeIcon } from "../Icons/index";
import HomeUnderwritingModal from "components/HomeUnderwritingModal";
import { PencilSquareIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import {
  saveHomePolicies
} from "../../data/actions";
import { useDispatch } from "react-redux";

const WaterCraft = ({ waterCraft, locale = "en-US" }) => {
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-1 flex justify-start items-center mt-1">
        <span className="text-xs">{langL10n.TypeText[locale]}</span>
      </div>
      <div className="col-span-1 flex justify-start items-center mt-1">
        <span className="text-xs">
          <TruncatorText fieldName={langL10n.TypeText[locale]} cutOff={8}>
            {waterCraft?.type}
          </TruncatorText>
        </span>
      </div>
      <div className="col-span-1 text-right"></div>

      <div className="col-span-1 text-left flex justify-start items-center mt-1">
        <span className="text-xs">HP</span>
      </div>
      <div className="col-span-1 text-left flex justify-start items-center mt-1">
        <span className="text-xs">
          <TruncatorText fieldName="HP" cutOff={5}>
            {waterCraft?.hp}
          </TruncatorText>
        </span>
      </div>
      <div className="col-span-1 text-right"></div>
      <div className="col-span-1 text-right"></div>

      <div className="col-span-1 text-right flex justi-start items-center mt-1">
        <span className="text-xs">{langL10n.SpeedText[locale]}</span>
      </div>

      <div className="col-span-1 text-right flex justify-end items-center mt-1">
        <span className="text-xs">
          <TruncatorText fieldName={langL10n.SpeedText[locale]} cutOff={5}>
            {waterCraft?.speed}
          </TruncatorText>
        </span>
      </div>

      <div className="col-span-1 text-right"></div>

      <div className="col-span-1 text-right">
        <span className="text-xs">{langL10n.LengthText[locale]}</span>
      </div>

      <div className="col-span-1 text-right flex justify-end items-center mt-1">
        <span className="text-xs">
          <TruncatorText fieldName={langL10n.LengthText[locale]} cutOff={5}>
            {waterCraft?.length}
          </TruncatorText>
        </span>
      </div>
    </div>
  );
};

export default function HomePolicy({ homePolicy, locale = "en-US" }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [modalContent, setModalContent] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState(homePolicy?.underwriting || {});


  const dispatch = useDispatch();

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const toggleModal = (content) => {
    setModalContent(content);
  }

  return (
    <div className="flex flex-1 mr-2">
      <CommonCard
        icon={<DashboardServiceHomeIcon className="w-6 h-6 text-primary mr-2" />}
        title={
          <div>
            {langL10n.HomePolicyText[locale]}
            <span className="mr-6"></span>
            {langL10n.PolicyNumberText[locale]}: {homePolicy?.policyNumber}
          </div>
        }
      >
        <div
          className={`flex flex-col pr-2 ${isExpanded ? "border-b border-gray-300" : ""
            }`}
        >
          <div className="flex gap-5">
            <div className="flex gap-2">
              <div className="flex flex-col">
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.CarrierText[locale]}
                </div>
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.AgentCodeText[locale]}
                </div>
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.AccountNumberText[locale]}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.CarrierText[locale]}
                    cutOff={10}
                  >
                    {homePolicy?.carrier}
                  </TruncatorText>
                </div>
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.AgentCodeText[locale]}
                    cutOff={5}
                  >
                    {homePolicy?.agentCode}
                  </TruncatorText>
                </div>
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.AccountNumberText[locale]}
                    cutOff={10}
                  >
                    {homePolicy?.accountNumber}
                  </TruncatorText>
                </div>
              </div>
            </div>
            <div className="flex gap-2">
              <div className="flex flex-col">
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.TermText[locale]}
                </div>
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.EffectiveDateText[locale]}
                </div>
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.ExpirationDateText[locale]}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.TermText[locale]}
                    cutOff={10}
                  >
                    {homePolicy?.term}
                  </TruncatorText>
                </div>
                <div className="text-left mb-2 text-xs">
                  {convertTimestamp(homePolicy?.effectiveDate)}
                </div>
                <div className="text-left mb-2 text-xs">
                  {convertTimestamp(homePolicy?.expirationDate)}
                </div>
              </div>
            </div>
            <div className="flex gap-2">
              <div className="flex flex-col">
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.StatusText[locale]}
                </div>
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.StatusDateText[locale]}
                </div>
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.InceptionDateText[locale]}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.StatusText[locale]}
                    cutOff={6}
                  >
                    {homePolicy?.status}
                  </TruncatorText>
                </div>
                <div className="text-left mb-2 text-xs">
                  {convertTimestamp(homePolicy?.statusDate)}
                </div>
                <div className="text-left mb-2 text-xs">
                  {convertTimestamp(homePolicy?.inceptionDate)}
                </div>
              </div>
            </div>
            <div className="flex gap-2">
              <div className="flex flex-col">
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.CurrentRateText[locale]}
                </div>
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.AnnualizedRateText[locale]}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.CurrentRateText[locale]}
                    cutOff={6}
                  >
                    {homePolicy?.currentRate}
                  </TruncatorText>
                </div>
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.AnnualizedRateText[locale]}
                    cutOff={6}
                  >
                    {homePolicy?.annualizedRate}
                  </TruncatorText>
                </div>
              </div>
            </div>
            <div className="flex gap-2">
              <div className="flex flex-col">
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.PropertyAddressText[locale]}
                </div>
                <div className="text-right mb-2 text-xs">
                  {langL10n.CityStateText[locale]}
                </div>
                <div className="text-right mb-2 text-xs">
                  {langL10n.ZipCodeText[locale]}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.PropertyAddressText[locale]}
                    cutOff={15}
                  >
                    {homePolicy?.propertyAddress?.addressLine1}
                  </TruncatorText>
                </div>
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.CityStateText[locale]}
                    cutOff={15}
                  >
                    {homePolicy?.propertyAddress?.cityAndState}
                  </TruncatorText>
                </div>
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.ZipCodeText[locale]}
                    cutOff={5}
                  >
                    {homePolicy?.propertyAddress?.zipCode}
                  </TruncatorText>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-end py-4">
            <DetailsButton
              locale={locale}
              isExpanded={isExpanded}
              toggleExpand={toggleExpand}
            />
          </div>
        </div>

        {isExpanded && (
          <div className="flex flex-col gap-4 px-2 pb-2">
            <div className="flex border-b gap-10">
              <div className="max-w-[50%]">
                <div className="grid grid-cols-3 border-b py-4">
                  <div className="py-1">
                    <span className="text-md text-primary">
                      {langL10n.CoveragesText[locale]}
                    </span>
                  </div>
                  <div className="py-1 text-right">
                    <span className="text-xs">
                      {langL10n.LimitDeductibleText[locale]}
                    </span>
                  </div>
                  <div className="py-1 text-right">
                    <span className="text-xs">
                      {langL10n.PremiumText[locale]}
                    </span>
                  </div>

                  {[
                    {
                      label: langL10n.DwellingText[locale],
                      coverage: homePolicy?.coverages.dwelling,
                    },
                    {
                      label: langL10n.DwellingReplacementText[locale],
                      coverage: homePolicy?.coverages.dwellingReplacement,
                    },
                    {
                      label: langL10n.OtherStructuresText[locale],
                      coverage: homePolicy?.coverages.otherStructures,
                    },
                    {
                      label: langL10n.PersonalPropertyText[locale],
                      coverage: homePolicy?.coverages.personalProperty,
                    },
                    {
                      label: langL10n.ContentsReplacementText[locale],
                      coverage: homePolicy?.coverages.contentsReplacement,
                    },
                    {
                      label: langL10n.LossOfUseText[locale],
                      coverage: homePolicy?.coverages.lossOfUse,
                    },
                    {
                      label: langL10n.LiabilityText[locale],
                      coverage: homePolicy?.coverages.liability,
                    },
                    {
                      label: langL10n.MedicalText[locale],
                      coverage: homePolicy?.coverages.medical,
                    },
                  ].map((item, index) => (
                    <React.Fragment key={index}>
                      <div className="flex mb-1.5">
                        <span className="text-xs">{item.label}</span>
                      </div>
                      <div className="text-right flex items-center justify-end h-fit">
                        <span className="text-xs flex justify-end">
                          <TruncatorText fieldName={item.label} cutOff={15}>
                            {item.coverage?.limit}
                          </TruncatorText>
                        </span>
                      </div>
                      <div className="text-right flex items-center justify-end h-fit">
                        <span className="text-xs flex justify-end">
                          <TruncatorText fieldName={item.label} cutOff={15}>
                            {item.coverage?.premium}
                          </TruncatorText>
                        </span>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
                <div className="grid grid-cols-3 border-b py-4">
                  <div className="col-span-2 py-1">
                    <span className="text-md text-primary">
                      {langL10n.EndorsementsScheduledPropertyText[locale]}
                    </span>
                  </div>
                  <div className="py-1 text-right">
                    <span className="text-xs">
                      {langL10n.PremiumText[locale]}
                    </span>
                  </div>
                  <div className="flex mb-1.5 mt-1">
                    <span className="text-xs">
                      {langL10n.EndorsementTotalText[locale]}
                    </span>
                  </div>
                  <div className="col-start-3 text-right flex justify-end items-center h-fit mb-1.5 mt-1">
                    <span className="text-xs flex justify-end items-center">
                      <TruncatorText
                        fieldName={langL10n.EndorsementTotalText[locale]}
                        cutOff={15}
                      >
                        {
                          homePolicy?.endorsementsAndScheduledProperty
                            .endorsementsTotal
                        }
                      </TruncatorText>
                    </span>
                  </div>
                  <div className="flex">
                    <span className="text-xs">
                      {langL10n.ScheduledPropertyText[locale]}
                    </span>
                  </div>
                  <div className="col-start-3 text-right flex justify-end items-center">
                    <span className="text-xs flex justify-end">
                      <TruncatorText
                        fieldName={langL10n.ScheduledPropertyText[locale]}
                        cutOff={15}
                      >
                        {
                          homePolicy?.endorsementsAndScheduledProperty
                            .scheduledProperty
                        }
                      </TruncatorText>
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-3 border-b py-4">
                  <div className="py-1">
                    <span className="text-md text-primary">
                      {langL10n.DeductiblesText[locale]}
                    </span>
                  </div>
                  <div className="py-1 text-right">
                    <span className="text-xs">
                      {langL10n.LimitText[locale]}
                    </span>
                  </div>
                  <div className="py-1 text-right">
                    <span className="text-xs">
                      {langL10n.PremiumText[locale]}
                    </span>
                  </div>

                  {[
                    {
                      label: langL10n.DeductibleText[locale],
                      deductible: homePolicy?.deductibles.deductible,
                    },
                    {
                      label: langL10n.WindHailDeductibleText[locale],
                      deductible: homePolicy?.deductibles.windHailDeductible,
                    },
                    {
                      label: langL10n.TheftDeductibleText[locale],
                      deductible: homePolicy?.deductibles.theftDeductible,
                    },
                  ].map((item, index) => (
                    <React.Fragment key={index}>
                      <div className="flex mb-1.5">
                        <span className="text-xs">{item.label}</span>
                      </div>
                      <span className="text-xs text-right flex justify-end items-center h-fit">
                        <TruncatorText fieldName={item.label} cutOff={15}>
                          {item.deductible?.limit}
                        </TruncatorText>
                      </span>
                      <span className="text-xs text-right flex justify-end items-center h-fit">
                        <TruncatorText fieldName={item.label} cutOff={15}>
                          {item.deductible?.premium}
                        </TruncatorText>
                      </span>
                    </React.Fragment>
                  ))}

                  <div className="col-span-3 py-1 mt-4"></div>
                  <div className="col-span-2 text-right flex justify-end items-center">
                    <span className="text-xs">
                      {langL10n.TotalPremiumText[locale]}
                    </span>
                  </div>
                  <span className="text-right text-xs flex items-center justify-end">
                    <TruncatorText
                      fieldName={langL10n.TotalPremiumText[locale]}
                      cutOff={15}
                    >
                      {homePolicy?.deductibles.deductible.premium +
                        homePolicy?.deductibles.windHailDeductible.premium +
                        homePolicy?.deductibles.theftDeductible.premium}
                    </TruncatorText>
                  </span>
                </div>
                <div className="grid grid-cols-3 py-4">
                  <div className="py-1">
                    <span className="text-md text-primary">
                      {langL10n.ScheduledText[locale]}
                    </span>
                  </div>
                  <div className="py-1 text-right flex justify-end items-center">
                    <span className="text-xs">
                      {langL10n.LimitText[locale]}
                    </span>
                  </div>
                  <div className="py-1 text-right flex justify-end items-center">
                    <span className="text-xs">
                      {langL10n.PremiumText[locale]}
                    </span>
                  </div>
                  {[
                    {
                      label: langL10n.JewelryText[locale],
                      scheduled: homePolicy?.scheduled.jewelry,
                    },
                    {
                      label: langL10n.FursText[locale],
                      scheduled: homePolicy?.scheduled.furs,
                    },
                    {
                      label: langL10n.GunsText[locale],
                      scheduled: homePolicy?.scheduled.guns,
                    },
                    {
                      label: langL10n.CamerasText[locale],
                      scheduled: homePolicy?.scheduled.cameras,
                    },
                    {
                      label: langL10n.CoinsText[locale],
                      scheduled: homePolicy?.scheduled.coins,
                    },
                    {
                      label: langL10n.StampsText[locale],
                      scheduled: homePolicy?.scheduled.stamps,
                    },
                    {
                      label: langL10n.SilverText[locale],
                      scheduled: homePolicy?.scheduled.silver,
                    },
                    {
                      label: langL10n.FineArtText[locale],
                      scheduled: homePolicy?.scheduled.fineArt,
                    },
                    {
                      label: langL10n.GolfText[locale],
                      scheduled: homePolicy?.scheduled.golf,
                    },
                    {
                      label: langL10n.MusicalText[locale],
                      scheduled: homePolicy?.scheduled.musical,
                    },
                    {
                      label: langL10n.ElectronicsText[locale],
                      scheduled: homePolicy?.scheduled.electronics,
                    },
                  ].map((item, index) => (
                    <React.Fragment key={index}>
                      <div className="flex mb-1.5">
                        <span className="text-xs">{item.label}</span>
                      </div>
                      <span className="text-xs text-right flex justify-end items-center h-fit">
                        <TruncatorText fieldName={item.label} cutOff={15}>
                          {item.scheduled?.limit}
                        </TruncatorText>
                      </span>
                      <span className="text-xs text-right flex justify-end items-center h-fit">
                        <TruncatorText fieldName={item.label} cutOff={15}>
                          {item.scheduled?.premium}
                        </TruncatorText>
                      </span>
                    </React.Fragment>
                  ))}
                </div>
              </div>
              <div className="max-w-[50%] pb-8">
                <div className="py-4">
                  <div className="col-span-4 py-1">
                    <span className="text-md text-primary">
                      {langL10n.EndorsementsText[locale]}
                    </span>
                  </div>
                  {/* Earthquake Section */}
                  <div className="grid grid-cols-12 py-1">
                    <div className="col-span-8">
                      <span className="text-xs text-primary">
                        {langL10n.EarthquakeText[locale]}
                      </span>
                    </div>
                    <div className="col-span-2 text-right">
                      <span className="text-xs">
                        {langL10n.DeductibleText[locale]}
                      </span>
                    </div>
                    <div className="col-span-2 text-right">
                      <span className="text-xs">
                        {langL10n.PremiumText[locale]}
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-cols-12">
                    <div className="col-span-3">
                      <span className="text-xs">
                        {langL10n.CoverageText[locale]}
                      </span>
                    </div>
                    <div className="col-span-1 text-left">
                      <span className="text-xs">
                        {homePolicy?.endorsements?.coverage?.coverage
                          ? "Yes"
                          : "No"}
                      </span>
                    </div>
                    <div className="col-span-1 text-right"></div>
                    <div className="col-span-1 text-right"></div>
                    <div className="col-span-1 text-right"></div>
                    <div className="col-span-1 text-right"></div>
                    <div className="col-span-1 text-right"></div>

                    <div className="col-span-1 text-right flex justify-end items-center">
                      <span className="text-xs text-right h-fit">
                        <TruncatorText
                          fieldName={langL10n.DeductibleText[locale]}
                          cutOff={10}
                        >
                          {homePolicy?.endorsements?.coverage?.deductible}
                        </TruncatorText>
                      </span>
                      <span className="text-xs"></span>
                    </div>
                    <div className="col-span-1 text-right"></div>
                    <div className="col-span-1 text-right flex justify-end items-center">
                      <span className="text-xs text-right flex justify-end items-center">
                        <TruncatorText
                          fieldName={langL10n.PremiumText[locale]}
                          cutOff={10}
                        >
                          {homePolicy?.endorsements?.coverage?.premium}
                        </TruncatorText>
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center">
                      <span className="text-xs">
                        {langL10n.IncludeMasonryText[locale]}
                      </span>
                    </div>
                    <div className="col-span-1 flex items-center">
                      <span className="text-xs text-right">
                        <TruncatorText
                          fieldName={langL10n.IncludeMasonryText[locale]}
                          cutOff={10}
                        >
                          {
                            homePolicy?.endorsements?.includeMasonry?.includeMasonry
                          }
                        </TruncatorText>
                      </span>
                    </div>
                    <div className="col-span-1 text-right"></div>
                    <div className="col-span-1 text-right"></div>
                    <div className="col-span-1 text-right"></div>
                    <div className="col-span-1 text-right"></div>
                    <div className="col-span-1 text-right"></div>

                    <div className="col-span-1 text-right flex justify-end items-center">
                      <span className="text-xs">
                        <TruncatorText
                          fieldName={langL10n.DeductibleText[locale]}
                          cutOff={10}
                        >
                          {homePolicy?.endorsements?.includeMasonry?.deductible}
                        </TruncatorText>
                      </span>
                    </div>
                    <div className="col-span-1 text-right"></div>

                    <div className="col-span-1 text-right flex justify-end items-center">
                      <span className="text-xs">
                        <TruncatorText
                          fieldName={langL10n.PremiumText[locale]}
                          cutOff={10}
                        >
                          {homePolicy?.endorsements?.includeMasonry?.premium}
                        </TruncatorText>
                      </span>
                    </div>
                  </div>

                  {/* Watercraft Section */}
                  <div className="grid grid-cols-2 mt-4">
                    <div className="col-span-1">
                      <span className="text-xs text-primary">
                        {langL10n.WatercraftText[locale]}
                      </span>
                    </div>
                    <div className="col-span-1 text-right">
                      <span className="text-xs">
                        {langL10n.PremiumText[locale]} 0.00
                      </span>
                    </div>
                  </div>

                  {homePolicy?.endorsements.watercrafts.map((waterCraft) => {
                    return (
                      <WaterCraft
                        key={waterCraft?.type}
                        waterCraft={waterCraft}
                      />
                    );
                  })}

                  <div className="grid grid-cols-6 border-b pt-4 pb-6">
                    <div className="col-span-4 py-1">
                      <span className="text-xs text-primary">
                        {langL10n.MiscEndorsementsText[locale]}
                      </span>
                    </div>
                    <div className="col-span-1 py-1 text-right">
                      <span className="text-xs">
                        {langL10n.LimitText[locale]}
                      </span>
                    </div>
                    <div className="col-span-1 py-1 text-right">
                      <span className="text-xs">
                        {langL10n.PremiumText[locale]}
                      </span>
                    </div>
                    {[
                      {
                        label: "BREM",
                        endorsement: homePolicy?.miscEndorsements.brem,
                      },
                      {
                        label: langL10n.PersonalInjuryText[locale],
                        endorsement:
                          homePolicy?.miscEndorsements.personalInjury,
                      },
                      {
                        label: langL10n.WaterBackupOfSewerText[locale],
                        endorsement:
                          homePolicy?.miscEndorsements.waterBackupOfSewer,
                      },
                      {
                        label:
                          langL10n.IdentifyFraudExpenseCoverageText[locale],
                        endorsement: homePolicy?.miscEndorsements.identityFraud,
                      },
                      {
                        label: langL10n.SRVLNText[locale],
                        endorsement: homePolicy?.miscEndorsements.srvln,
                      },
                      {
                        label: langL10n.WIHALText[locale],
                        endorsement: homePolicy?.miscEndorsements.wihal,
                      },
                      {
                        label: langL10n.BusinessPropertyAtHomeText[locale],
                        endorsement:
                          homePolicy?.miscEndorsements.businessPropertyAtHome,
                      },
                      {
                        label:
                          langL10n.BusinessPropertyAwayFromHomeText[locale],
                        endorsement:
                          homePolicy?.miscEndorsements
                            .businessPropertyAwayFromHome,
                      },
                      {
                        label:
                          langL10n.IncCovCSpecialLiabilityLimitMText[locale],
                        endorsement:
                          homePolicy?.miscEndorsements.specialLiabilityM,
                      },
                      {
                        label:
                          langL10n.IncCovCSpecialLiabilityLimitSText[locale],
                        endorsement:
                          homePolicy?.miscEndorsements.specialLiabilityS1,
                      },
                      {
                        label:
                          langL10n.IncCovCSpecialLiabilityLimitSText[locale],
                        endorsement:
                          homePolicy?.miscEndorsements.specialLiabilityS2,
                      },
                      {
                        label:
                          langL10n.CreditCardForgeryCounterfeitMoneyText[
                          locale
                          ],
                        endorsement:
                          homePolicy?.miscEndorsements.creditCardForgery,
                      },
                    ].map((item, index) => (
                      <React.Fragment key={index}>
                        <div className="col-span-4 flex justify-start items-center mb-1">
                          <span className="text-xs">{item.label}</span>
                        </div>
                        <span className="col-span-1 text-right text-xs flex justify-end items-center">
                          <TruncatorText fieldName={item.label} cutOff={8}>
                            {item.endorsement?.limit}
                          </TruncatorText>
                        </span>
                        <span className="col-span-1 text-right text-xs flex justify-end items-center">
                          <TruncatorText fieldName={item.label} cutOff={8}>
                            {item.endorsement?.premium}
                          </TruncatorText>
                        </span>
                      </React.Fragment>
                    ))}
                  </div>

                  <div className="grid grid-cols-12 pt-4 pb-4">
                    <div className="col-span-12 py-1">
                      <span className="text-md text-primary">
                        {langL10n.AdditionalInterestsText[locale]}
                      </span>
                    </div>
                    <div className="col-span-2">
                      <span className="text-xs font-semibold">
                        {langL10n.TypeText[locale]}
                      </span>
                    </div>
                    <div className="col-span-4">
                      <span className="text-xs font-semibold">
                        {langL10n.NameText[locale]}
                      </span>
                    </div>
                    <div className="col-span-2">
                      <span className="text-xs font-semibold">
                        {langL10n.LOBText[locale]}
                      </span>
                    </div>
                    <div className="col-span-2">
                      <span className="text-xs font-semibold">
                        {langL10n.AppliesToText[locale]}
                      </span>
                    </div>
                    <div className="col-span-1">
                      <span className="text-xs font-semibold">
                        {langL10n.LoanNumberText[locale]}
                      </span>
                    </div>
                    <div className="col-span-2">
                      <span className="text-xs flex items-center justify-start h-full">
                        <TruncatorText
                          fieldName={langL10n.TypeText[locale]}
                          cutOff={8}
                        >
                          {homePolicy?.additionalInterests?.type}
                        </TruncatorText>
                      </span>
                    </div>
                    <div className="col-span-4">
                      <span className="text-xs flex items-center justify-start h-full">
                        <TruncatorText
                          fieldName={langL10n.NameText[locale]}
                          cutOff={16}
                        >
                          {homePolicy?.additionalInterests?.name}
                        </TruncatorText>
                      </span>
                    </div>
                    <div className="col-span-2"></div>
                    <div className="col-span-2">
                      <span className="text-xs flex items-center justify-start h-full">
                        <TruncatorText
                          fieldName={langL10n.AppliesToText[locale]}
                          cutOff={8}
                        >
                          {homePolicy?.additionalInterests?.appliesTo}
                        </TruncatorText>
                      </span>
                    </div>
                    <div className="col-span-1">
                      <span className="text-xs flex items-center justify-start h-full">
                        <TruncatorText
                          fieldName={langL10n.LoanNumberText[locale]}
                          cutOff={8}
                        >
                          {homePolicy?.additionalInterests?.loanNumber}
                        </TruncatorText>
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-cols-12 pb-4">
                    <div className="col-span-2">
                      <span className="text-xs font-semibold">
                        {langL10n.PayorText[locale]}
                      </span>
                    </div>
                    <div className="col-span-3">
                      <span className="text-xs font-semibold">
                        {langL10n.PhoneText[locale]}
                      </span>
                    </div>
                    <div className="col-span-3">
                      <span className="text-xs font-semibold">
                        {langL10n.FAXText[locale]}
                      </span>
                    </div>
                    <div className="col-span-4"></div>
                    <div className="col-span-2">
                      <span className="text-xs flex items-center justify-start h-full">
                        {homePolicy?.additionalInterests?.payor ? "Yes" : "No"}
                      </span>
                    </div>
                    <div className="col-span-3">
                      <span className="text-xs flex items-center justify-start h-full">
                        <TruncatorText
                          fieldName={langL10n.PhoneText[locale]}
                          cutOff={12}
                        >
                          {homePolicy?.additionalInterests?.phone}
                        </TruncatorText>
                      </span>
                    </div>
                    <div className="col-span-3">
                      <span className="text-xs flex items-center justify-start h-full">
                        <TruncatorText
                          fieldName={langL10n.FAXText[locale]}
                          cutOff={12}
                        >
                          {homePolicy?.additionalInterests?.fax}
                        </TruncatorText>
                      </span>
                    </div>
                    <div className="col-span-4"></div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between relative">
                <button
                  className="flex items-center"
                  onClick={toggleModal} // Open the modal when clicked
                >
                  <div className="flex items-center">
                    <DashboardServiceCustomerNotesIcon className="w-6 h-6 text-primary mr-2" />
                    <h2 className="text-md text-primary">
                      {langL10n.UnderwritingText[locale]}
                    </h2>
                  </div>
                </button>

                {/* Modal */}
                {modalContent && (
                  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                    <div>
                      <CommonCard
                        icon={<DashboardServiceCustomerNotesIcon className="w-6 h-6 text-primary mr-2" />}
                        title={<div>{langL10n.UnderwritingText[locale]}</div>}
                        buttons={
                          <div className="flex items-center">
                            <button
                              className="w-6 h-6 mr-2 relative right-1"
                              onClick={() => setEditMode(!isEditMode)}
                            >
                              <PencilSquareIcon className="w-6 h-6 text-lightGray mr-2 hover:text-primary" />
                            </button>
                            <button
                              onClick={() => toggleModal(false)}
                              className="w-5 h-5 rounded-sm bg-primary flex justify-center items-center"
                            >
                              <XMarkIcon className="w-4 h-4 text-white" />
                            </button>
                          </div>
                        }
                      >
                        <HomeUnderwritingModal
                          underwriting={homePolicy?.underwriting}
                          formData={formData}
                          setFormData={setFormData}
                          locale={locale}
                          editMode={isEditMode}
                        />

                        {/* Buttons Section */}
                        <div className="flex gap-4 flex-row-reverse px-4">
                          <button onClick={() =>
                            confirmAlert({
                              title: langL10n.ConfirmToSubmit[locale],
                              message: langL10n.AreYouSureYouWouldLikeToSaveUnderwriting[locale],
                              buttons: [
                                {
                                  label: langL10n.Yes[locale],
                                  onClick: () => dispatch(saveHomePolicies({ customerId: homePolicy?.customerId, data: formData }))

                                },
                                {
                                  label: langL10n.No[locale],
                                  onClick: () => toggleModal(false)
                                }
                              ]
                            })
                          }
                            className={`${!isEditMode ? 'hidden' : 'bg-primary text-white w-32 py-1 rounded'}`}>
                            {langL10n.SaveText[locale]}
                          </button>
                          <button
                            className="bg-primary text-white w-32 py-1 rounded"
                            onClick={() => toggleModal(false)}
                          >
                            {langL10n.CancelText[locale]}
                          </button>
                        </div>
                      </CommonCard>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </CommonCard>
    </div>
  );
}
