import React from 'react';
import langL10n from './lang-l10n';

export default function
    HomeUnderwritingModal({ locale = 'en-US', formData, setFormData, editMode }) {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }
    return (
        <div
            className="
                p-4 bg-white rounded-lg flex flex-col gap-4 h-[634px] overflow-auto
                [&::-webkit-scrollbar]:w-2
                [&::-webkit-scrollbar-track]:rounded-full
                [&::-webkit-scrollbar-track]:bg-gray-100
                [&::-webkit-scrollbar-thumb]:rounded-full
                [&::-webkit-scrollbar-thumb]:bg-gray-300
                dark:[&::-webkit-scrollbar-track]:bg-neutral-700
                dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500
            "
        >
            <div className="flex pb-4 gap-4">
                <div className="flex flex-1 flex-col flex-wrap mr-2 ">
                    <div className="flex flex-col pr-2">
                        <div className="flex gap-24">
                            {/* Left Side Pop Up */}
                            <div className="flex gap-5">
                                {/* Left side */}
                                <div className={`flex flex-col items-baseline ${editMode ? '[&>div]:font-bold [&>div]:text-right [&>div]:mb-4 [&>div]:text-xs' : ''}`}>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.CountyText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.TerritoryText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.PremiumGroupText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.ProtectionClassText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.DwellingLocationText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.DistanceToCoastText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.ResidenceTypeText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.DwellingUseText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs pb-6">
                                        {langL10n.OccupancyTypeText[locale]}
                                    </div>
                                    {/* Content Breaks */}
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.FireDistrictNumberText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.FireDistrictNameText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs pb-6">
                                        {langL10n.FireStationDistanceText[locale]}
                                    </div>
                                    {/* Content Breaks */}
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.BurglarAlarmText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.FireAlarmText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.DeadBoltText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.FireExtinguisherText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.SprinklerText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.FeetFromHydrantText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.WoodStoveInsertsText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.HearthsText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.ChimneysText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.PreFabsText[locale]}
                                    </div>
                                </div>
                                {/* Right side */}
                                <div className='flex flex-col items-baseline'>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='County'
                                                value={formData.County}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.County
                                        )}

                                    </div>

                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='Territory'
                                                value={formData.Territory}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.Territory
                                        )}
                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='PremiumGroup'
                                                value={formData.PremiumGroup}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.PremiumGroup
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='ProtectionClass'
                                                value={formData.ProtectionClass}
                                                onChange={(e) => handleChange(e, formData.ProtectionClass)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.ProtectionClass
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='DwellingLocation'
                                                value={formData.DwellingLocation}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.DwellingLocation
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='DistanceToCoast'
                                                value={formData.DistanceToCoast}
                                                onChange={(e) => handleChange(e, formData.DistanceToCoast)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.DistanceToCoast
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='ResidenceType'
                                                value={formData.ResidenceType}
                                                onChange={(e) => handleChange(e, formData.ResidenceType)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.ResidenceType
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='DwellingUse'
                                                value={formData.DwellingUse}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.DwellingUse
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs pb-6">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='OccupancyType'
                                                value={formData.OccupancyType}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.OccupancyType
                                        )}

                                    </div>
                                    {/* Content Breaks */}
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='FireDistrictNumber'
                                                value={formData.FireDistrictNumber}
                                                onChange={(e) => handleChange(e, formData.FireDistrictNumber)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.FireDistrictNumber
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='FireDistrictName'
                                                value={formData.FireDistrictName}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.FireDistrictName
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs pb-6">

                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='FireStationDistance'
                                                value={formData.FireStationDistance}
                                                onChange={(e) => handleChange(e, formData.FireStationDistance)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.FireStationDistance
                                        )}

                                    </div>
                                    {/* Content Breaks */}
                                    <div className="text-left mb-2 text-xs">

                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='BurglarAlarm'
                                                value={formData.BurglarAlarm}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.BurglarAlarm
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">

                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='FireAlarm'
                                                value={formData.FireAlarm}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.FireAlarm
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='DeadBolt'
                                                value={formData.DeadBolt}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.DeadBolt
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='FireExtinguisher'
                                                value={formData.FireExtinguisher}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.FireExtinguisher
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='Sprinkler'
                                                value={formData.Sprinkler}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.Sprinkler
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='FeetFromHydrant'
                                                value={formData.FeetFromHydrant}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.FeetFromHydrant
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='WoodStoveInserts'
                                                value={formData.WoodStoveInserts}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.WoodStoveInserts
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='Hearths'
                                                value={formData.Hearths}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.Hearths
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='Chimneys'
                                                value={formData.Chimneys}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.Chimneys
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='PreFabs'
                                                value={formData.PreFabs}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.PreFabs
                                        )}

                                    </div>
                                </div>
                            </div>
                            {/* Right Side Pop Up */}
                            <div className="flex gap-5">
                                {/* Left side */}
                                <div className={`flex flex-col items-baseline ${editMode ? '[&>div]:font-bold [&>div]:text-right [&>div]:mb-4 [&>div]:text-xs' : ''}`}>

                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.PurchasePriceText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.PurchaseDateText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.TotalLivingAreaText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.GarageAreaText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs pb-6">
                                        {langL10n.BasementAreaText[locale]}
                                    </div>
                                    {/* Content Breaks - year built */}
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.YearBuiltText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.ConstructionTypeText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.MakeText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.ModelText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.InParkText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs pb-6">
                                        {langL10n.ParkNameText[locale]}
                                    </div>
                                    {/* Content Breakds */}
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.RoofText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.HeatSourceText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.NumberOfUnitsText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.StoriesText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.NumberOfRoomsText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs pb-6">
                                        {langL10n.NumberOfFamiliesText[locale]}
                                    </div>
                                    {/* Content Breaks */}
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.SwimmingPoolText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.TrampolineText[locale]}
                                    </div>

                                </div>
                                {/* Right side */}
                                <div className="flex flex-col items-baseline">
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='PurchasePrice'
                                                value={formData.PurchasePrice}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.PurchasePrice
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='PurchaseDate'
                                                value={formData.PurchaseDate}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.PurchaseDate
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='TotalLivingArea'
                                                value={formData.TotalLivingArea}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.TotalLivingArea
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='GarageArea'
                                                value={formData.GarageArea}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.GarageArea
                                        )}

                                    </div>

                                    {/* Content Breaks */}
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='BasementArea'
                                                value={formData.BasementArea}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.BasementArea
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='ConstructionType'
                                                value={formData.ConstructionType}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.ConstructionType
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='Make'
                                                value={formData.Make}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.Make
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='Model'
                                                value={formData.Model}
                                                onChange={(e) => handleChange(e, formData.Model)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.Model
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='InPark'
                                                value={formData.InPark}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.InPark
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs pb-6">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='ParkName'
                                                value={formData.ParkName}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.ParkName
                                        )}

                                    </div>
                                    {/* Content Breaks */}
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='Roof'
                                                value={formData.Roof}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.Roof
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='HeatSource'
                                                value={formData.HeatSource}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.HeatSource
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='NumberOfUnits'
                                                value={formData.NumberOfUnits}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.NumberOfUnits
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='Stories'
                                                value={formData.Stories}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.Stories
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name="NumberOfRooms"
                                                value={formData.NumberOfRooms}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.NumberOfRooms
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs pb-6">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='NumberOfFamilies'
                                                value={formData.NumberOfFamilies}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.NumberOfFamilies
                                        )}

                                    </div>
                                    {/* Content Breaks */}
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='SwimmingPool'
                                                value={formData.SwimmingPool}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.SwimmingPool
                                        )}

                                    </div>
                                    <div className="text-left mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='Trampoline'
                                                value={formData.Trampoline}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.Trampoline
                                        )}

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Bottom Columns */}
                    <div className="flex flex-col mt-6 pr-2">
                        <div className='flex gap-24'>
                            {/* Left three Columns */}
                            <div className='flex gap-10 mr-4'>
                                {/* Left Column */}
                                <div className="flex flex-col items-baseline">
                                    <div className="font-bold text-right mb-2 text-xs text-primary">
                                        {langL10n.RenovationsText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.WiringText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.PlumbingText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.HeatingText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.RoofText[locale]}
                                    </div>
                                </div>
                                {/* Middle Column */}
                                <div className="flex flex-col items-baseline">
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.YearText[locale]}
                                    </div>
                                    <div className="text-right mb-2 text-xs">
                                        {formData?.WiringYear}
                                    </div>
                                    <div className="text-right mb-2 text-xs">
                                        {formData?.PlumbingYear}
                                    </div>
                                    <div className="text-right mb-2 text-xs">
                                        {formData?.HeatingYear}
                                    </div>
                                    <div className="text-right mb-2 text-xs">
                                        {formData?.RoofYear}
                                    </div>
                                </div>
                                {/* Right Column */}
                                <div className="flex flex-col items-baseline">
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.CodeText[locale]}
                                    </div>
                                    <div className="text-right mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='WiringCode'
                                                value={formData.WiringCode}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.WiringCode
                                        )}

                                    </div>


                                    <div className="text-right mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='PlumbingCode'
                                                value={formData.PlumbingCode}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.PlumbingCode
                                        )}

                                    </div>
                                    <div className="text-right mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='PlumbingCode'
                                                value={formData.PlumbingCode}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.HeatingCode
                                        )}

                                    </div>
                                    <div className="text-right mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='RoofCode'
                                                value={formData.RoofCode}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.RoofCode
                                        )}

                                    </div>
                                </div>
                            </div>
                            {/* Right two Columns */}
                            <div className='flex gap-20'>
                                {/* Left Column */}
                                <div className="flex flex-col items-baseline">
                                    <div className="font-bold text-right mb-2 text-xs text-primary">
                                        {langL10n.AnimalsText[locale]} 01
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.TypeText[locale]}
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs text-primary">
                                        {langL10n.AnimalsText[locale]} 02
                                    </div>
                                    <div className="font-bold text-right mb-2 text-xs">
                                        {langL10n.TypeText[locale]}
                                    </div>
                                </div>
                                {/* Right Column */}
                                <div className="flex flex-col items-baseline">
                                    <div className=" text-right mb-6 text-xs ">

                                    </div>
                                    <div className=" text-right mb-2 text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='AnimalType01'
                                                value={formData.AnimalType01}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.AnimalType01
                                        )}

                                    </div>
                                    <div className=" text-right mb-6 text-xs">

                                    </div>
                                    <div className=" text-right text-xs">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                name='AnimalType02'
                                                value={formData.AnimalType02}
                                                onChange={(e) => handleChange(e)}
                                                className="rounded-md bg-lightGray text-xs h-6"
                                            />
                                        ) : (
                                            formData?.AnimalType02
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}