/* eslint-disable max-len */
export default {
  NameInsuredText: {
    "en-US": "Name Insured",
    "es-US": "Nombre Asegurado",
  },
  SecondInsuredText: {
    "en-US": "2nd Insured",
    "es-US": "2º Asegurado",
  },
  MailAddressText: {
    "en-US": "Mail Address",
    "es-US": "Dirección Postal",
  },
  ContactInfoText: {
    "en-US": "Contact Info",
    "es-US": "Información de Contacto",
  },
  HomePhoneText: {
    "en-US": "Home Phone",
    "es-US": "Teléfono Residencial",
  },
  MobilePhoneText: {
    "en-US": "Mobile Phone",
    "es-US": "tTeléfono Móvil",
  },
  HomeEmailText: {
    "en-US": "Home E-Mail",
    "es-US": "Correo Electrónico Domiciliario",
  },
  OtherEmailText: {
    "en-US": "Other E-Mail",
    "es-US": "Otro Correo Electrónico",
  },
  PreferredContactText: {
    "en-US": "Preferred Method of Contact",
    "es-US": "Método de Contacto Preferido",
  },
  PhoneText: {
    "en-US": "Phone",
    "es-US": "Teléfono",
  },
  SMSText: {
    "en-US": "SMS",
    "es-US": "SMS",
  },
  EmailText: {
    "en-US": "E-Mail",
    "es-US": "Correo Electrónico",
  },
};
