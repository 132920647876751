import { createAsyncThunk } from "@reduxjs/toolkit";

import { apiCall } from "services/api";

export const fetchCustomer = createAsyncThunk(
  "serviceDashboard/fetchCustomer",
  async (customerId, { rejectWithValue }) => {
    try {

      const customer =  await apiCall("get", `/api/customers/${customerId}`);
      if (customer) {
        return customer;
      } else {
        console.log("No customer found.");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchCustomerAnalytics = createAsyncThunk(
  "serviceDashboard/fetchCustomerAnalytics",
  async (customerId, { rejectWithValue }) => {
    try {
     
      const customerAnalytics =   await apiCall("get", `/api/customer-analytics/${customerId}`);
      if (customerAnalytics) {
        return customerAnalytics;
      } else {
        console.log("No customer analytics object found.");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchCustomerNotes = createAsyncThunk(
  "serviceDashboard/fetchCustomerNotes",
  async (customerId, { rejectWithValue }) => {
    try {
      let notes = [];
      const customerNotes =  await apiCall("get", `/api/notes/${customerId}`);
      notes.push(...customerNotes);

      if (notes) {
        return notes;
      } else {
        console.log("No notes found.");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchHomePolicies = createAsyncThunk(
  "serviceDashboard/fetchHomePolicies",
  async (customerId, { rejectWithValue }) => {
    try {
      
      const homePolicies =  await apiCall("get",`/api/home-policies/${customerId}`);
      
      if (homePolicies) {
        return homePolicies;
      } else {
        console.log("No home policies found.");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const patchHomePolicies = createAsyncThunk(
  "serviceDashboard/patchHomePolicies",
  async (customerId, { rejectWithValue }) => {
    try {
      
      const homePolicies =  await apiCall("get",`/api/home-policies/${customerId}`);
      
      if (homePolicies) {
        return homePolicies;
      } else {
        console.log("No home policies found.");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const saveHomePolicies = createAsyncThunk(
  "serviceDashboard/saveHomePolicies",
  async ({customerId,data}, { rejectWithValue }) => {
    try {
      
      const createJson =  JSON.stringify(data);
      const homePolicies =  await apiCall("put",`/api/home-policies/${customerId}`,createJson);
      
      if (homePolicies) {
        return homePolicies;
      } else {
        console.log("Saved home policies.");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const fetchAutoPolicies = createAsyncThunk(
  "serviceDashboard/fetchAutoPolicies",
  async (customerId, { rejectWithValue }) => {
   
    try {
     
     const response = await apiCall("get", `/api/auto-policies/${customerId}`)

      console.log('Data received from server:', response);
    
      if (response) {
        return response;
      } else {
        console.log("No auto policies found.");
      }
    } catch (error) {
      console.error("Error fetching auto policies:", error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const fetchCommercialAutoPolicies = createAsyncThunk(
  "serviceDashboard/fetchCommercialAutoPolicies",
  async (customerId, { rejectWithValue }) => {
    try {

      const commercialAutoPolicies =  await apiCall("get", `/api/commercial-auto-policies/${customerId}`);
   
      if (commercialAutoPolicies) {
        return commercialAutoPolicies;
      } else {
        console.log("No commercial auto policies found.");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchCommercialPropertyPolicies = createAsyncThunk(
  "serviceDashboard/fetchCommercialPropertyPolicies",
  async (customerId, { rejectWithValue }) => {
    try {
     
      const commercialPropertyPolicies =  await apiCall("get", `/api/commercial-property-policies/${customerId}`);
   
      if (commercialPropertyPolicies) {
        return commercialPropertyPolicies;
      } else {
        console.log("No commercial property policies found.");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchNote = createAsyncThunk(
  "notes/fetchNote",
  async (noteId, { rejectWithValue }) => {
    try {
      
      const note =  await apiCall("get", `/api/notes/${noteId}`);
   
      if (note) {
        return note;
      } else {
        console.log("No note found.");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
