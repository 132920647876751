export default {
  AutoPolicyText: {
    "en-US": "Auto",
    "es-US": "Auto",
  },
  PolicyNumberText: {
    "en-US": "Policy Number",
    "es-US": "Número de Póliza",
  },
  CarrierText: {
    "en-US": "Carrier",
    "es-US": "Aseguradora",
  },
  AgentCodeText: {
    "en-US": "Agent Code",
    "es-US": "Código de Agente",
  },
  AccountNumberText: {
    "en-US": "Account #",
    "es-US": "Número de Cuenta",
  },
  TermText: {
    "en-US": "Term",
    "es-US": "Plazo",
  },
  EffectiveDateText: {
    "en-US": "Effective Date",
    "es-US": "Fecha de Inicio",
  },
  ExpirationDateText: {
    "en-US": "Expiration Date",
    "es-US": "Fecha de Expiración",
  },
  StatusText: {
    "en-US": "Status",
    "es-US": "Estado",
  },
  StatusDateText: {
    "en-US": "Status Date",
    "es-US": "Fecha de Estado",
  },
  InceptionDateText: {
    "en-US": "Inception Date",
    "es-US": "Fecha de Inicio",
  },
  CurrentRateText: {
    "en-US": "Current Rate",
    "es-US": "Tarifa Actual",
  },
  AnnualizedRateText: {
    "en-US": "Annualized Rate",
    "es-US": "Tarifa Anualizada",
  },
  GaragingAddressText: {
    "en-US": "Garaging Address",
    "es-US": "Dirección del Garaje",
  },
  CityStateText: {
    "en-US": "City & State",
    "es-US": "Ciudad y Estado",
  },
  ZipCodeText: {
    "en-US": "Zip Code",
    "es-US": "Código Postal",
  },
  VehicleText: {
    "en-US": "Vehicle",
    "es-US": "Vehículo",
  },
  MakeModelYearText: {
    "en-US": "Make/Model/Year",
    "es-US": "Marca/Modelo/Año",
  },
  BodyTypeText: {
    "en-US": "Body Type",
    "es-US": "Tipo de Carrocería",
  },
  DriveText: {
    "en-US": "Drive",
    "es-US": "Tracción",
  },
  SymbolText: {
    "en-US": "Symbol",
    "es-US": "Símbolo",
  },
  VinNumberText: {
    "en-US": "Vin Number",
    "es-US": "Número de VIN",
  },
  CompVehNumberText: {
    "en-US": "Comp Veh #",
    "es-US": "Vehículo Comp. #",
  },
  FleetIdText: {
    "en-US": "Fleet ID",
    "es-US": "ID de Flota",
  },
  AnnualMilesText: {
    "en-US": "Annual Miles",
    "es-US": "Millas Anuales",
  },
  CommuteMilesText: {
    "en-US": "Commute Miles",
    "es-US": "Millas de Conmutación",
  },
  UseText: {
    "en-US": "Use",
    "es-US": "Uso",
  },
  OdometerDateText: {
    "en-US": "Odometer Date",
    "es-US": "Fecha del Odómetro",
  },
  ZipText: {
    "en-US": "Zip",
    "es-US": "Código Postal",
  },
  AssignedDriverText: {
    "en-US": "Assigned Driver",
    "es-US": "Conductor Asignado",
  },
  DiscountsText: {
    "en-US": "Discounts",
    "es-US": "Descuentos",
  },
  AdditionalInterestsText: {
    "en-US": "Additional Interests",
    "es-US": "Intereses Adicionales",
  },
  DescriptionText: {
    "en-US": "Description",
    "es-US": "Descripción",
  },
  BodilyInjuryText: {
    "en-US": "Bodily Injury",
    "es-US": "Lesiones Corporales",
  },
  PropertyDamageText: {
    "en-US": "Property Damage",
    "es-US": "Daños a la Propiedad",
  },
  UninsuredMotoristLiabilityText: {
    "en-US": "Uninsured Motorist Liability",
    "es-US": "Responsabilidad del Conductor Sin Seguro",
  },
  UnderInsuredMotoristLiabilityText: {
    "en-US": "Under-insured Motorist Liability",
    "es-US": "Responsabilidad del Conductor con Seguro Insuficiente",
  },
  PersonalInjuryProtectionText: {
    "en-US": "Personal Injury Protection",
    "es-US": "Protección contra Lesiones Personales",
  },
  ComprehensiveFullGlassText: {
    "en-US": "Comprehensive - Full Glass...",
    "es-US": "Cobertura Total - Cristal Completo...",
  },
  CollisionText: {
    "en-US": "Collision",
    "es-US": "Colisión",
  },
  RentalReimbursementText: {
    "en-US": "Rental Reimbursement",
    "es-US": "Reembolso de Alquiler",
  },
  GlassText: {
    "en-US": "Glass",
    "es-US": "Cristal",
  },
  RoadsideAssistanceCoverageText: {
    "en-US": "Roadside Assistance Coverage",
    "es-US": "Cobertura de Asistencia en Carretera",
  },
  MultiCarDiscountText: {
    "en-US": "Multi-car Discount",
    "es-US": "Descuento por Múltiples Autos",
  },
  LimitsText: {
    "en-US": "Limits",
    "es-US": "Límites",
  },
  DeductibleText: {
    "en-US": "Deductible",
    "es-US": "Deducible",
  },
  PremiumText: {
    "en-US": "Premium",
    "es-US": "Prima",
  },
  TotalText: {
    "en-US": "Total",
    "es-US": "Total",
  },
  GeneralPolicyInformationText: {
    "en-US": "General Policy Information",
    "es-US": "Información General de la Póliza",
  },
  CompanyText: {
    "en-US": "Company",
    "es-US": "Compañía",
  },
  ProgramText: {
    "en-US": "Program",
    "es-US": "Programa",
  },
  GaragingAddressIfDifferentText: {
    "en-US": "Garaging Address (if Different)",
    "es-US": "Dirección de Garaje (si es diferente)",
  },
  StreetAddressText: {
    "en-US": "Street Address",
    "es-US": "Dirección",
  },
  Address2Text: {
    "en-US": "Address 2",
    "es-US": "Dirección 2",
  },
  CityText: {
    "en-US": "City",
    "es-US": "Ciudad",
  },
  StateText: {
    "en-US": "State",
    "es-US": "Estado",
  },
  DriverText: {
    "en-US": "Driver",
    "es-US": "Conductor",
  },
  CompanyDriverText: {
    "en-US": "Company Driver =",
    "es-US": "Conductor de la Compañía =",
  },
  MaritalStatusText: {
    "en-US": "Marital Status",
    "es-US": "Estado Civil",
  },
  GenderText: {
    "en-US": "Gender",
    "es-US": "Género",
  },
  RelationshipStatusText: {
    "en-US": "Relationship",
    "es-US": "Estado de Relación",
  },
  DateOfBirthText: {
    "en-US": "Date of Birth",
    "es-US": "Fecha de Nacimiento",
  },
  AgeText: {
    "en-US": "Age",
    "es-US": "Edad",
  },
  SSNText: {
    "en-US": "SSN",
    "es-US": "SSN",
  },
  DriversLicenseText: {
    "en-US": "Driver's License",
    "es-US": "Licencia de Conducir",
  },
  LicensedStateText: {
    "en-US": "Licensed State",
    "es-US": "Estado de Licencia",
  },
  OccupationText: {
    "en-US": "Occupation",
    "es-US": "Ocupación",
  },
  EducationText: {
    "en-US": "Education",
    "es-US": "Educación",
  },
  DriverTrainingText: {
    "en-US": "Driver Training",
    "es-US": "Entrenamiento de Conductor",
  },
  DefensiveDriversCourseText: {
    "en-US": "Defensive Drivers Course",
    "es-US": "Curso de Conducción Defensiva",
  },
  GoodDriverText: {
    "en-US": "Good Driver",
    "es-US": "Buen Conductor",
  },
  GoodStudentText: {
    "en-US": "Good Student",
    "es-US": "Buen Estudiante",
  },
  StudentOver100MilesText: {
    "en-US": "Student > 100 Miles",
    "es-US": "Estudiante > 100 Millas",
  },
  OperatorStatusText: {
    "en-US": "Operator Status",
    "es-US": "Estado del Operador",
  },
  SR22Text: {
    "en-US": "SR-22",
    "es-US": "SR-22",
  },
  ProofRequiredUntilText: {
    "en-US": "Proof Required Until",
    "es-US": "Prueba Requerida Hasta",
  },
  ReasonText: {
    "en-US": "Reason",
    "es-US": "Razón",
  },
  AddDriverText: {
    "en-US": "Add Driver",
    "es-US": "Agregar Conductor",
  },
  TypeText: {
    "en-US": "Type",
    "es-US": "Tipo",
  },
  NameText: {
    "en-US": "Name",
    "es-US": "Nombre",
  },
  LoanNumberText: {
    "en-US": "Loan #",
    "es-US": "Número de Préstamo",
  },
};
