import React, { useState } from "react";

import Sidebar from "components/Sidebar";
import ActionBar from "components/ActionBar";
import AppBar from "components/AppBar";
import AgencyLogo from "components/AgencyLogo";

import { navLinks, coreLinks, actionLinks } from "../constants";

export default function DashboardWrapper({ children }) {
  const [isSideBarExpanded, setIsSideBarExpanded] = useState(false);

  const toggleSidebar = () => {
    setIsSideBarExpanded((prevValue) => !prevValue);
  };

  return (
    <div className="flex w-full bg-background relative">
      <AppBar agencyLogo={AgencyLogo} />
      <div className="flex w-full">
        <div onClick={toggleSidebar} className="relative z-10">
          <Sidebar
            isExpanded={isSideBarExpanded}
            navLinks={navLinks}
            coreLinks={coreLinks}
          />
        </div>
        <div className="content w-full mt-14 mr-2 p-4 ml-16 relative z-0">
          {children}
        </div>
      </div>
      <ActionBar actionLinks={actionLinks} />
    </div>
  );
}
