import React from "react";

export default function DashboardNavigationMenuQuickAddIcon({ className, viewBox, fill }) {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox={viewBox ? `${viewBox}` : "0 0 100 100"}
            className={className ? `${className}` : "w-6 h-6 text-primary mr-2"}
            fill={fill ? `${fill}` : "currentColor"}
        >
            <defs>
                <style>
                </style>
            </defs>
            <g>
                <rect x="2.5" y="2.5" width="14.4" height="14.4" />
                <rect x="42.8" y="2.5" width="14.4" height="14.4" />
                <rect x="83.1" y="2.5" width="14.4" height="14.4" />
                <rect x="2.5" y="83.1" width="14.4" height="14.4" />
                <rect x="42.8" y="83.1" width="14.4" height="14.4" />
                <rect x="83.1" y="83.1" width="14.4" height="14.4" />
                <rect x="2.5" y="42.8" width="14.4" height="14.4" />
                <rect x="83.1" y="42.8" width="14.4" height="14.4" />
                <path d="M52.7,31.9v16.4h16.6v5.5h-16.6v16.4h-5.4v-16.4h-16.7v-5.5h16.7v-16.4s5.4,0,5.4,0Z" />
            </g>
        </svg>
    );
}