import React from 'react';

export default function DashboardServiceUmbrellaIcon({ className, viewBox, fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox={viewBox ? `${viewBox}` : "0 0 100 100"}
      className={className ? `${className}` : "w-6 h-6"}
      fill={fill ? `${fill}` : "currentColor"}
      preserveAspectRatio="xMidYMid meet"
    >
        <defs>
            <style>
            </style>
        </defs>
        <path d="M89.3,29.2c-2.8-4.3-6.3-8-10.3-11.2-4.1-3.2-8.6-5.7-13.4-7.4-4.5-1.6-9.2-2.5-14-2.6v-3.9c0-.9-.7-1.6-1.6-1.6s-1.6.7-1.6,1.6v3.9c-4.8.2-9.5,1-14,2.6-4.8,1.7-9.4,4.2-13.4,7.4-4,3.2-7.5,6.9-10.3,11.2-2.9,4.3-5,9.1-6.3,14.1-.2.8.2,1.7,1,2.1.8.4,1.7.1,2.3-.6.9-1.3,2.2-2.3,3.7-3,1.6-.8,3.4-1.2,5.2-1.2,3.9,0,7.5,1.8,9.3,4.7.4.6,1.1,1,1.9,1s1.5-.4,1.9-1c1.8-2.9,5.4-4.7,9.3-4.7s7.5,1.8,9.3,4.7c0,.1.1.2.2.3v44.3c0,4.3,3.5,7.7,7.7,7.7s7.7-3.5,7.7-7.7v-3.9c0-.9-.7-1.6-1.6-1.6s-1.6.7-1.6,1.6v3.9c0,2.5-2,4.4-4.4,4.4s-4.4-2-4.4-4.4v-44.3c0,0,.2-.2.2-.3,1.8-2.9,5.4-4.7,9.3-4.7s7.5,1.8,9.3,4.7c.4.6,1.1,1,1.9,1s1.5-.4,1.9-1c1.8-2.9,5.4-4.7,9.3-4.7s3.7.4,5.2,1.2c1.5.7,2.8,1.8,3.7,3,.5.7,1.5,1,2.3.6.8-.4,1.2-1.3,1-2.1-1.3-5-3.4-9.7-6.3-14.1ZM16.6,37.3c-2.3,0-4.6.5-6.7,1.5-.3.1-.5.3-.8.4,1.1-2.9,2.5-5.6,4.3-8.2,2.6-4,5.8-7.5,9.6-10.4,3.8-3,8-5.3,12.5-6.9,2.6-.9,5.2-1.6,7.9-2-1.2,1-2.4,2-3.5,3.1-2.5,2.5-4.8,5.3-6.7,8.3-1.9,3-3.5,6.2-4.7,9.6-.9,2.6-1.7,5.4-2.1,8.1-2.5-2.2-6-3.5-9.7-3.5ZM38.9,37.3c-3.4,0-6.7,1.1-9.2,3.1.4-2.2,1-4.4,1.8-6.6,1.1-3.1,2.6-6.1,4.4-8.9,1.8-2.8,3.9-5.4,6.3-7.7,1.9-1.9,4-3.6,6.2-5v28.5c-2.5-2.1-5.9-3.3-9.5-3.3ZM61.1,37.3c-3.6,0-7,1.2-9.5,3.3V12.1c2.2,1.5,4.3,3.2,6.2,5,2.4,2.3,4.5,4.9,6.3,7.7,1.8,2.8,3.3,5.8,4.4,8.9.8,2.1,1.4,4.3,1.8,6.6-2.5-1.9-5.7-3.1-9.2-3.1ZM90.1,38.8c-2-1-4.3-1.5-6.7-1.5-3.7,0-7.2,1.3-9.7,3.5-.5-2.8-1.2-5.5-2.1-8.1-1.2-3.4-2.8-6.6-4.7-9.6-1.9-3-4.2-5.8-6.7-8.3-1.1-1.1-2.3-2.1-3.5-3.1,2.7.4,5.3,1.1,7.9,2,4.5,1.6,8.7,3.9,12.5,6.9,3.7,2.9,7,6.4,9.6,10.4,1.7,2.6,3.1,5.4,4.3,8.2-.3-.1-.5-.3-.8-.4Z"/>
    </svg>
  );
}