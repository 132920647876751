/* eslint-disable max-len */
export default {
  QuickAddText: {
    "en-US": "Quick add",
    "es-US": "Añadir rápido",
  },
  AddClientText: {
    "en-US": "+ Client",
    "es-US": "+ Cliente",
  },
  AddProspectText: {
    "en-US": "+ Prospect",
    "es-US": "+ Prospecto",
  },
  AddNoteText: {
    "en-US": "+ Note",
    "es-US": "+ Nota",
  },
  WelcomeText: {
    "en-US": "Welcome",
    "es-US": "Bienvenido",
  },
  ProfileText: {
    "en-US": "Profile",
    "es-US": "Perfil",
  },
  MyAccountText: {
    "en-US": "My account",
    "es-US": "Mi cuenta",
  },
};
