import React, { useState } from "react";

import CommonCard from "components/CommonCard";
import DetailsButton from "components/DetailsButton";
import TruncatorText from "components/TruncateText";

import { convertTimestamp } from "lib/convertors";

import langL10n from "./lang-l10n";
import { DashboardServiceCommercialPropertyIcon, DashboardServiceCustomerNotesIcon } from "../Icons/index";

const WaterCraft = ({ waterCraft, locale = "en-US" }) => {
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-1 flex justify-start items-center mt-1">
        <span className="text-xs">{langL10n.TypeText[locale]}</span>
      </div>
      <div className="col-span-1 flex justify-start items-center mt-1">
        <span className="text-xs">
          <TruncatorText fieldName={langL10n.TypeText[locale]} cutOff={8}>
            {waterCraft?.type}
          </TruncatorText>
        </span>
      </div>
      <div className="col-span-1 text-right"></div>

      <div className="col-span-1 text-left flex justify-start items-center mt-1">
        <span className="text-xs">HP</span>
      </div>
      <div className="col-span-1 text-left flex justify-start items-center mt-1">
        <span className="text-xs">
          <TruncatorText fieldName="HP" cutOff={5}>
            {waterCraft?.hp}
          </TruncatorText>
        </span>
      </div>
      <div className="col-span-1 text-right"></div>
      <div className="col-span-1 text-right"></div>

      <div className="col-span-1 text-right flex justi-start items-center mt-1">
        <span className="text-xs">{langL10n.SpeedText[locale]}</span>
      </div>

      <div className="col-span-1 text-right flex justify-end items-center mt-1">
        <span className="text-xs">
          <TruncatorText fieldName={langL10n.SpeedText[locale]} cutOff={5}>
            {waterCraft?.speed}
          </TruncatorText>
        </span>
      </div>

      <div className="col-span-1 text-right"></div>

      <div className="col-span-1 text-right">
        <span className="text-xs">{langL10n.LengthText[locale]}</span>
      </div>

      <div className="col-span-1 text-right flex justify-end items-center mt-1">
        <span className="text-xs">
          <TruncatorText fieldName={langL10n.LengthText[locale]} cutOff={5}>
            {waterCraft?.length}
          </TruncatorText>
        </span>
      </div>
    </div>
  );
};

export default function CommercialPropertyPolicy({
  commercialPropertyPolicy,
  locale = "en-US",
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className="flex flex-1 mr-2">
      <CommonCard
        icon={<DashboardServiceCommercialPropertyIcon className="w-6 h-6 text-primary mr-2" />}
        title={
          <div>
            {langL10n.CommercialPropertyText[locale]}
            <span className="mr-6"></span>
            {langL10n.PolicyNumberText[locale]}:{" "}
            {commercialPropertyPolicy?.policyNumber}
          </div>
        }
      >
        <div
          className={`flex flex-col pr-2 ${isExpanded ? "border-b border-gray-300" : ""}`}
        >
          <div className="flex gap-5">
            <div className="flex gap-2">
              <div className="flex flex-col">
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.CarrierText[locale]}
                </div>
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.AgentCodeText[locale]}
                </div>
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.AccountNumberText[locale]}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.CarrierText[locale]}
                    cutOff={10}
                  >
                    {commercialPropertyPolicy?.carrier}
                  </TruncatorText>
                </div>
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.AgentCodeText[locale]}
                    cutOff={5}
                  >
                    {commercialPropertyPolicy?.agentCode}
                  </TruncatorText>
                </div>
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.AccountNumberText[locale]}
                    cutOff={10}
                  >
                    {commercialPropertyPolicy?.accountNumber}
                  </TruncatorText>
                </div>
              </div>
            </div>
            <div className="flex gap-2">
              <div className="flex flex-col">
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.TermText[locale]}
                </div>
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.EffectiveDateText[locale]}
                </div>
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.ExpirationDateText[locale]}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.TermText[locale]}
                    cutOff={10}
                  >
                    {commercialPropertyPolicy?.term}
                  </TruncatorText>
                </div>
                <div className="text-left mb-2 text-xs">
                  {convertTimestamp(commercialPropertyPolicy?.effectiveDate)}
                </div>
                <div className="text-left mb-2 text-xs">
                  {convertTimestamp(commercialPropertyPolicy?.expirationDate)}
                </div>
              </div>
            </div>
            <div className="flex gap-2">
              <div className="flex flex-col">
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.StatusText[locale]}
                </div>
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.StatusDateText[locale]}
                </div>
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.InceptionDateText[locale]}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.StatusText[locale]}
                    cutOff={6}
                  >
                    {commercialPropertyPolicy?.status}
                  </TruncatorText>
                </div>
                <div className="text-left mb-2 text-xs">
                  {convertTimestamp(commercialPropertyPolicy?.statusDate)}
                </div>
                <div className="text-left mb-2 text-xs">
                  {convertTimestamp(commercialPropertyPolicy?.inceptionDate)}
                </div>
              </div>
            </div>
            <div className="flex gap-2">
              <div className="flex flex-col">
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.CurrentRateText[locale]}
                </div>
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.AnnualizedRateText[locale]}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.CurrentRateText[locale]}
                    cutOff={6}
                  >
                    {commercialPropertyPolicy?.currentRate}
                  </TruncatorText>
                </div>
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.AnnualizedRateText[locale]}
                    cutOff={6}
                  >
                    {commercialPropertyPolicy?.annualizedRate}
                  </TruncatorText>
                </div>
              </div>
            </div>
            <div className="flex gap-2">
              <div className="flex flex-col">
                <div className="font-bold text-right mb-2 text-xs">
                  {langL10n.PropertyAddressText[locale]}
                </div>
                <div className="text-right mb-2 text-xs">
                  {langL10n.CityStateText[locale]}
                </div>
                <div className="text-right mb-2 text-xs">
                  {langL10n.ZipCodeText[locale]}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.PropertyAddressText[locale]}
                    cutOff={15}
                  >
                    {commercialPropertyPolicy?.propertyAddress?.addressLine1}
                  </TruncatorText>
                </div>
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.CityStateText[locale]}
                    cutOff={15}
                  >
                    {commercialPropertyPolicy?.propertyAddress?.cityAndState}
                  </TruncatorText>
                </div>
                <div className="text-left mb-2 text-xs">
                  <TruncatorText
                    fieldName={langL10n.ZipCodeText[locale]}
                    cutOff={5}
                  >
                    {commercialPropertyPolicy?.propertyAddress.zipCode}
                  </TruncatorText>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-end py-4">
            <DetailsButton
              locale={locale}
              isExpanded={isExpanded}
              toggleExpand={toggleExpand}
            />
          </div>
        </div>

        {isExpanded && (
          <div className="flex flex-col gap-4 px-2 pb-2">
            <div className="flex border-b gap-10">
              <div className="max-w-[50%]">
                <div className="grid grid-cols-3 border-b py-4">
                  <div className="py-1">
                    <span className="text-md text-primary">
                      {langL10n.CoveragesText[locale]}
                    </span>
                  </div>
                  <div className="py-1 text-right">
                    <span className="text-xs">
                      {langL10n.LimitDeductibleText[locale]}
                    </span>
                  </div>
                  <div className="py-1 text-right">
                    <span className="text-xs">
                      {langL10n.PremiumText[locale]}
                    </span>
                  </div>

                  {[
                    {
                      label: langL10n.DwellingText[locale],
                      coverage: commercialPropertyPolicy?.coverages.dwelling,
                    },
                    {
                      label: langL10n.DwellingReplacementText[locale],
                      coverage:
                        commercialPropertyPolicy?.coverages.dwellingReplacement,
                    },
                    {
                      label: langL10n.OtherStructuresText[locale],
                      coverage:
                        commercialPropertyPolicy?.coverages.otherStructures,
                    },
                    {
                      label: langL10n.PersonalPropertyText[locale],
                      coverage:
                        commercialPropertyPolicy?.coverages.personalProperty,
                    },
                    {
                      label: langL10n.ContentsReplacementText[locale],
                      coverage:
                        commercialPropertyPolicy?.coverages.contentsReplacement,
                    },
                    {
                      label: langL10n.LossOfUseText[locale],
                      coverage: commercialPropertyPolicy?.coverages.lossOfUse,
                    },
                    {
                      label: langL10n.LiabilityText[locale],
                      coverage: commercialPropertyPolicy?.coverages.liability,
                    },
                    {
                      label: langL10n.MedicalText[locale],
                      coverage: commercialPropertyPolicy?.coverages.medical,
                    },
                  ].map((item, index) => (
                    <React.Fragment key={index}>
                      <div className="flex mb-1.5">
                        <span className="text-xs">{item.label}</span>
                      </div>
                      <div className="text-right flex items-center justify-end h-fit">
                        <span className="text-xs flex justify-end">
                          <TruncatorText fieldName={item.label} cutOff={15}>
                            {item.coverage?.limit}
                          </TruncatorText>
                        </span>
                      </div>
                      <div className="text-right flex items-center justify-end h-fit">
                        <span className="text-xs flex justify-end">
                          <TruncatorText fieldName={item.label} cutOff={15}>
                            {item.coverage?.premium}
                          </TruncatorText>
                        </span>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
                <div className="grid grid-cols-3 border-b py-4">
                  <div className="col-span-2 py-1">
                    <span className="text-md text-primary">
                      {langL10n.EndorsementsScheduledPropertyText[locale]}
                    </span>
                  </div>
                  <div className="py-1 text-right">
                    <span className="text-xs">
                      {langL10n.PremiumText[locale]}
                    </span>
                  </div>
                  <div className="flex mb-1.5 mt-1">
                    <span className="text-xs">
                      {langL10n.EndorsementTotalText[locale]}
                    </span>
                  </div>
                  <div className="col-start-3 text-right flex justify-end items-center h-fit mb-1.5 mt-1">
                    <span className="text-xs flex justify-end items-center">
                      <TruncatorText
                        fieldName={langL10n.EndorsementTotalText[locale]}
                        cutOff={15}
                      >
                        {
                          commercialPropertyPolicy
                            ?.endorsementsAndScheduledProperty.endorsementsTotal
                        }
                      </TruncatorText>
                    </span>
                  </div>
                  <div className="flex">
                    <span className="text-xs">
                      {langL10n.ScheduledPropertyText[locale]}
                    </span>
                  </div>
                  <div className="col-start-3 text-right flex justify-end items-center">
                    <span className="text-xs flex justify-end">
                      <TruncatorText
                        fieldName={langL10n.ScheduledPropertyText[locale]}
                        cutOff={15}
                      >
                        {
                          commercialPropertyPolicy
                            ?.endorsementsAndScheduledProperty.scheduledProperty
                        }
                      </TruncatorText>
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-3 border-b py-4">
                  <div className="py-1">
                    <span className="text-md text-primary">
                      {langL10n.DeductiblesText[locale]}
                    </span>
                  </div>
                  <div className="py-1 text-right">
                    <span className="text-xs">
                      {langL10n.LimitText[locale]}
                    </span>
                  </div>
                  <div className="py-1 text-right">
                    <span className="text-xs">
                      {langL10n.PremiumText[locale]}
                    </span>
                  </div>

                  {[
                    {
                      label: langL10n.DeductibleText[locale],
                      deductible:
                        commercialPropertyPolicy?.deductibles.deductible,
                    },
                    {
                      label: langL10n.WindHailDeductibleText[locale],
                      deductible:
                        commercialPropertyPolicy?.deductibles
                          .windHailDeductible,
                    },
                    {
                      label: langL10n.TheftDeductibleText[locale],
                      deductible:
                        commercialPropertyPolicy?.deductibles.theftDeductible,
                    },
                  ].map((item, index) => (
                    <React.Fragment key={index}>
                      <div className="flex mb-1.5">
                        <span className="text-xs">{item.label}</span>
                      </div>
                      <span className="text-xs text-right flex justify-end items-center h-fit">
                        <TruncatorText fieldName={item.label} cutOff={15}>
                          {item.deductible?.limit}
                        </TruncatorText>
                      </span>
                      <span className="text-xs text-right flex justify-end items-center h-fit">
                        <TruncatorText fieldName={item.label} cutOff={15}>
                          {item.deductible?.premium}
                        </TruncatorText>
                      </span>
                    </React.Fragment>
                  ))}

                  <div className="col-span-3 py-1 mt-4"></div>
                  <div className="col-span-2 text-right flex justify-end items-center">
                    <span className="text-xs">
                      {langL10n.TotalPremiumText[locale]}
                    </span>
                  </div>
                  <span className="text-right text-xs flex items-center justify-end">
                    <TruncatorText
                      fieldName={langL10n.TotalPremiumText[locale]}
                      cutOff={15}
                    >
                      {commercialPropertyPolicy?.deductibles.deductible
                        .premium +
                        commercialPropertyPolicy?.deductibles.windHailDeductible
                          .premium +
                        commercialPropertyPolicy?.deductibles.theftDeductible
                          .premium}
                    </TruncatorText>
                  </span>
                </div>
                <div className="grid grid-cols-3 py-4">
                  <div className="py-1">
                    <span className="text-md text-primary">
                      {langL10n.ScheduledText[locale]}
                    </span>
                  </div>
                  <div className="py-1 text-right flex justify-end items-center">
                    <span className="text-xs">
                      {langL10n.LimitText[locale]}
                    </span>
                  </div>
                  <div className="py-1 text-right flex justify-end items-center">
                    <span className="text-xs">
                      {langL10n.PremiumText[locale]}
                    </span>
                  </div>
                  {[
                    {
                      label: langL10n.JewelryText[locale],
                      scheduled: commercialPropertyPolicy?.scheduled.jewelry,
                    },
                    {
                      label: langL10n.FursText[locale],
                      scheduled: commercialPropertyPolicy?.scheduled.furs,
                    },
                    {
                      label: langL10n.GunsText[locale],
                      scheduled: commercialPropertyPolicy?.scheduled.guns,
                    },
                    {
                      label: langL10n.CamerasText[locale],
                      scheduled: commercialPropertyPolicy?.scheduled.cameras,
                    },
                    {
                      label: langL10n.CoinsText[locale],
                      scheduled: commercialPropertyPolicy?.scheduled.coins,
                    },
                    {
                      label: langL10n.StampsText[locale],
                      scheduled: commercialPropertyPolicy?.scheduled.stamps,
                    },
                    {
                      label: langL10n.SilverText[locale],
                      scheduled: commercialPropertyPolicy?.scheduled.silver,
                    },
                    {
                      label: langL10n.FineArtText[locale],
                      scheduled: commercialPropertyPolicy?.scheduled.fineArt,
                    },
                    {
                      label: langL10n.GolfText[locale],
                      scheduled: commercialPropertyPolicy?.scheduled.golf,
                    },
                    {
                      label: langL10n.MusicalText[locale],
                      scheduled: commercialPropertyPolicy?.scheduled.musical,
                    },
                    {
                      label: langL10n.ElectronicsText[locale],
                      scheduled:
                        commercialPropertyPolicy?.scheduled.electronics,
                    },
                  ].map((item, index) => (
                    <React.Fragment key={index}>
                      <div className="flex mb-1.5">
                        <span className="text-xs">{item.label}</span>
                      </div>
                      <span className="text-xs text-right flex justify-end items-center h-fit">
                        <TruncatorText fieldName={item.label} cutOff={15}>
                          {item.scheduled?.limit}
                        </TruncatorText>
                      </span>
                      <span className="text-xs text-right flex justify-end items-center h-fit">
                        <TruncatorText fieldName={item.label} cutOff={15}>
                          {item.scheduled?.premium}
                        </TruncatorText>
                      </span>
                    </React.Fragment>
                  ))}
                </div>
              </div>
              <div className="max-w-[50%] pb-8">
                <div className="py-4">
                  <div className="col-span-4 py-1">
                    <span className="text-md text-primary">
                      {langL10n.EndorsementsText[locale]}
                    </span>
                  </div>
                  {/* Earthquake Section */}
                  <div className="grid grid-cols-12 py-1">
                    <div className="col-span-8">
                      <span className="text-xs text-primary">
                        {langL10n.EarthquakeText[locale]}
                      </span>
                    </div>
                    <div className="col-span-2 text-right">
                      <span className="text-xs">
                        {langL10n.DeductibleText[locale]}
                      </span>
                    </div>
                    <div className="col-span-2 text-right">
                      <span className="text-xs">
                        {langL10n.PremiumText[locale]}
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-cols-12">
                    <div className="col-span-3">
                      <span className="text-xs">
                        {langL10n.CoverageText[locale]}
                      </span>
                    </div>
                    <div className="col-span-1 text-left">
                      <span className="text-xs">
                        {commercialPropertyPolicy?.endorsements.coverage
                          .coverage
                          ? "Yes"
                          : "No"}
                      </span>
                    </div>
                    <div className="col-span-1 text-right"></div>
                    <div className="col-span-1 text-right"></div>
                    <div className="col-span-1 text-right"></div>
                    <div className="col-span-1 text-right"></div>
                    <div className="col-span-1 text-right"></div>

                    <div className="col-span-1 text-right flex justify-end items-center">
                      <span className="text-xs text-right h-fit">
                        <TruncatorText
                          fieldName={langL10n.DeductibleText[locale]}
                          cutOff={10}
                        >
                          {
                            commercialPropertyPolicy?.endorsements.coverage
                              .deductible
                          }
                        </TruncatorText>
                      </span>
                      <span className="text-xs"></span>
                    </div>
                    <div className="col-span-1 text-right"></div>
                    <div className="col-span-1 text-right flex justify-end items-center">
                      <span className="text-xs text-right flex justify-end items-center">
                        <TruncatorText
                          fieldName={langL10n.PremiumText[locale]}
                          cutOff={10}
                        >
                          {
                            commercialPropertyPolicy?.endorsements.coverage
                              .premium
                          }
                        </TruncatorText>
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center">
                      <span className="text-xs">
                        {langL10n.IncludeMasonryText[locale]}
                      </span>
                    </div>
                    <div className="col-span-1 flex items-center">
                      <span className="text-xs text-right">
                        <TruncatorText
                          fieldName={langL10n.IncludeMasonryText[locale]}
                          cutOff={10}
                        >
                          {
                            commercialPropertyPolicy?.endorsements
                              .includeMasonry.includeMasonry
                          }
                        </TruncatorText>
                      </span>
                    </div>
                    <div className="col-span-1 text-right"></div>
                    <div className="col-span-1 text-right"></div>
                    <div className="col-span-1 text-right"></div>
                    <div className="col-span-1 text-right"></div>
                    <div className="col-span-1 text-right"></div>

                    <div className="col-span-1 text-right flex justify-end items-center">
                      <span className="text-xs">
                        <TruncatorText
                          fieldName={langL10n.DeductibleText[locale]}
                          cutOff={10}
                        >
                          {
                            commercialPropertyPolicy?.endorsements
                              .includeMasonry.deductible
                          }
                        </TruncatorText>
                      </span>
                    </div>
                    <div className="col-span-1 text-right"></div>

                    <div className="col-span-1 text-right flex justify-end items-center">
                      <span className="text-xs">
                        <TruncatorText
                          fieldName={langL10n.PremiumText[locale]}
                          cutOff={10}
                        >
                          {
                            commercialPropertyPolicy?.endorsements
                              .includeMasonry.premium
                          }
                        </TruncatorText>
                      </span>
                    </div>
                  </div>

                  {/* Watercraft Section */}
                  <div className="grid grid-cols-2 mt-4">
                    <div className="col-span-1">
                      <span className="text-xs text-primary">
                        {langL10n.WatercraftText[locale]}
                      </span>
                    </div>
                    <div className="col-span-1 text-right">
                      <span className="text-xs">
                        {langL10n.PremiumText[locale]} 0.00
                      </span>
                    </div>
                  </div>

                  {commercialPropertyPolicy?.endorsements.watercrafts.map(
                    (waterCraft) => {
                      return (
                        <WaterCraft
                          key={waterCraft.type}
                          waterCraft={waterCraft}
                        />
                      );
                    }
                  )}

                  <div className="grid grid-cols-6 border-b pt-4 pb-6">
                    <div className="col-span-4 py-1">
                      <span className="text-xs text-primary">
                        {langL10n.MiscEndorsementsText[locale]}
                      </span>
                    </div>
                    <div className="col-span-1 py-1 text-right">
                      <span className="text-xs">
                        {langL10n.LimitText[locale]}
                      </span>
                    </div>
                    <div className="col-span-1 py-1 text-right">
                      <span className="text-xs">
                        {langL10n.PremiumText[locale]}
                      </span>
                    </div>
                    {[
                      {
                        label: "BREM",
                        endorsement:
                          commercialPropertyPolicy?.miscEndorsements.brem,
                      },
                      {
                        label: langL10n.PersonalInjuryText[locale],
                        endorsement:
                          commercialPropertyPolicy?.miscEndorsements
                            .personalInjury,
                      },
                      {
                        label: langL10n.WaterBackupOfSewerText[locale],
                        endorsement:
                          commercialPropertyPolicy?.miscEndorsements
                            .waterBackupOfSewer,
                      },
                      {
                        label:
                          langL10n.IdentifyFraudExpenseCoverageText[locale],
                        endorsement:
                          commercialPropertyPolicy?.miscEndorsements
                            .identityFraud,
                      },
                      {
                        label: langL10n.SRVLNText[locale],
                        endorsement:
                          commercialPropertyPolicy?.miscEndorsements.srvln,
                      },
                      {
                        label: langL10n.WIHALText[locale],
                        endorsement:
                          commercialPropertyPolicy?.miscEndorsements.wihal,
                      },
                      {
                        label: langL10n.BusinessPropertyAtHomeText[locale],
                        endorsement:
                          commercialPropertyPolicy?.miscEndorsements
                            .businessPropertyAtHome,
                      },
                      {
                        label:
                          langL10n.BusinessPropertyAwayFromHomeText[locale],
                        endorsement:
                          commercialPropertyPolicy?.miscEndorsements
                            .businessPropertyAwayFromHome,
                      },
                      {
                        label:
                          langL10n.IncCovCSpecialLiabilityLimitMText[locale],
                        endorsement:
                          commercialPropertyPolicy?.miscEndorsements
                            .specialLiabilityM,
                      },
                      {
                        label:
                          langL10n.IncCovCSpecialLiabilityLimitSText[locale],
                        endorsement:
                          commercialPropertyPolicy?.miscEndorsements
                            .specialLiabilityS1,
                      },
                      {
                        label:
                          langL10n.IncCovCSpecialLiabilityLimitSText[locale],
                        endorsement:
                          commercialPropertyPolicy?.miscEndorsements
                            .specialLiabilityS2,
                      },
                      {
                        label:
                          langL10n.CreditCardForgeryCounterfeitMoneyText[
                            locale
                          ],
                        endorsement:
                          commercialPropertyPolicy?.miscEndorsements
                            .creditCardForgery,
                      },
                    ].map((item, index) => (
                      <React.Fragment key={index}>
                        <div className="col-span-4 flex justify-start items-center mb-1">
                          <span className="text-xs">{item.label}</span>
                        </div>
                        <span className="col-span-1 text-right text-xs flex justify-end items-center">
                          <TruncatorText fieldName={item.label} cutOff={8}>
                            {item.endorsement?.limit}
                          </TruncatorText>
                        </span>
                        <span className="col-span-1 text-right text-xs flex justify-end items-center">
                          <TruncatorText fieldName={item.label} cutOff={8}>
                            {item.endorsement?.premium}
                          </TruncatorText>
                        </span>
                      </React.Fragment>
                    ))}
                  </div>

                  <div className="grid grid-cols-12 pt-4 pb-4">
                    <div className="col-span-12 py-1">
                      <span className="text-md text-primary">
                        {langL10n.AdditionalInterestsText[locale]}
                      </span>
                    </div>
                    <div className="col-span-2">
                      <span className="text-xs font-semibold">
                        {langL10n.TypeText[locale]}
                      </span>
                    </div>
                    <div className="col-span-4">
                      <span className="text-xs font-semibold">
                        {langL10n.NameText[locale]}
                      </span>
                    </div>
                    <div className="col-span-2">
                      <span className="text-xs font-semibold">
                        {langL10n.LOBText[locale]}
                      </span>
                    </div>
                    <div className="col-span-2">
                      <span className="text-xs font-semibold">
                        {langL10n.AppliesToText[locale]}
                      </span>
                    </div>
                    <div className="col-span-1">
                      <span className="text-xs font-semibold">
                        {langL10n.LoanNumberText[locale]}
                      </span>
                    </div>
                    <div className="col-span-2">
                      <span className="text-xs flex items-center justify-start h-full">
                        <TruncatorText
                          fieldName={langL10n.TypeText[locale]}
                          cutOff={8}
                        >
                          {commercialPropertyPolicy?.additionalInterests.type}
                        </TruncatorText>
                      </span>
                    </div>
                    <div className="col-span-4">
                      <span className="text-xs flex items-center justify-start h-full">
                        <TruncatorText
                          fieldName={langL10n.NameText[locale]}
                          cutOff={16}
                        >
                          {commercialPropertyPolicy?.additionalInterests.name}
                        </TruncatorText>
                      </span>
                    </div>
                    <div className="col-span-2"></div>
                    <div className="col-span-2">
                      <span className="text-xs flex items-center justify-start h-full">
                        <TruncatorText
                          fieldName={langL10n.AppliesToText[locale]}
                          cutOff={8}
                        >
                          {
                            commercialPropertyPolicy?.additionalInterests
                              .appliesTo
                          }
                        </TruncatorText>
                      </span>
                    </div>
                    <div className="col-span-1">
                      <span className="text-xs flex items-center justify-start h-full">
                        <TruncatorText
                          fieldName={langL10n.LoanNumberText[locale]}
                          cutOff={8}
                        >
                          {
                            commercialPropertyPolicy?.additionalInterests
                              .loanNumber
                          }
                        </TruncatorText>
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-cols-12 pb-4">
                    <div className="col-span-2">
                      <span className="text-xs font-semibold">
                        {langL10n.PayorText[locale]}
                      </span>
                    </div>
                    <div className="col-span-3">
                      <span className="text-xs font-semibold">
                        {langL10n.PhoneText[locale]}
                      </span>
                    </div>
                    <div className="col-span-3">
                      <span className="text-xs font-semibold">
                        {langL10n.FAXText[locale]}
                      </span>
                    </div>
                    <div className="col-span-4"></div>
                    <div className="col-span-2">
                      <span className="text-xs flex items-center justify-start h-full">
                        {commercialPropertyPolicy?.additionalInterests.payor
                          ? "Yes"
                          : "No"}
                      </span>
                    </div>
                    <div className="col-span-3">
                      <span className="text-xs flex items-center justify-start h-full">
                        <TruncatorText
                          fieldName={langL10n.PhoneText[locale]}
                          cutOff={12}
                        >
                          {commercialPropertyPolicy?.additionalInterests.phone}
                        </TruncatorText>
                      </span>
                    </div>
                    <div className="col-span-3">
                      <span className="text-xs flex items-center justify-start h-full">
                        <TruncatorText
                          fieldName={langL10n.FAXText[locale]}
                          cutOff={12}
                        >
                          {commercialPropertyPolicy?.additionalInterests.fax}
                        </TruncatorText>
                      </span>
                    </div>
                    <div className="col-span-4"></div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between relative">
                <div className="flex items-center">
                  <DashboardServiceCustomerNotesIcon className="w-6 h-6 text-primary mr-2" />
                  <h2 className="text-md text-primary">
                    {langL10n.UnderwritingText[locale]}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        )}
      </CommonCard>
    </div>
  );
}
