import React from "react";

export default function CommonCard({ icon, buttons, title, children }) {
  return (
    <div className="bg-white shadow-md rounded-lg p-2 w-full h-full">
      <div className="flex items-center justify-between border-b border-gray-300 pb-1 mb-2 relative">
        <div className="flex items-center">
          {icon}
          <h2 className="text-md text-primary">{title}</h2>
        </div>

        <div className="flex">{buttons}</div>
      </div>
      <div>{children}</div>
    </div>
  );
}
