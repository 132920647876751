/* eslint-disable max-len */
export default {
    CountyText: {
        "en-US": "Country",
        "es-US": "País",
    },
    TerritoryText: {
        "en-US": "Territory",
        "es-US": "Territorio",
    },
    PremiumGroupText: {
        "en-US": "Premium Group",
        "es-US": "Grupo Premium",
    },
    ProtectionClassText: {
        "en-US": "Protection Class",
        "es-US": "Clase de Protección",
    },
    DwellingLocationText: {
        "en-US": "Dwelling Location",
        "es-US": "Ubicación de la Vivienda",
    },
    DistanceToCoastText: {
        "en-US": "Distance to Coast",
        "es-US": "Distancia a la Costa",
    },
    ResidenceTypeText: {
        "en-US": "Residence Type",
        "es-US": "Tipo de Residencia",
    },
    DwellingUseText: {
        "en-US": "Dwelling Use",
        "es-US": "Uso de Vivienda",
    },
    OccupancyTypeText: {
        "en-US": "Occupancy Type",
        "es-US": "Tipo de Ocupación",
    },
    FireDistrictNumberText: {
        "en-US": "Fire District Number",
        "es-US": "Número del Distrito de Bomberos",
    },
    FireDistrictNameText: {
        "en-US": "Fire Station Name",
        "es-US": "Nombre de la Estación de Bomberos",
    },
    FireStationDistanceText: {
        "en-US": "Fire Station Distance",
        "es-US": "Distancia de la Estación de Bomberos",
    },
    BurglarAlarmText: {
        "en-US": "Burglar Alarm",
        "es-US": "Alarma Antirrobo",
    },
    FireAlarmText: {
        "en-US": "Fire Alarm",
        "es-US": "Alarma de Incendios",
    },
    DeadBoltText: {
        "en-US": "Dead Bolt",
        "es-US": "Cerrojo",
    },
    FireExtinguisherText: {
        "en-US": "Fire Extinguisher",
        "es-US": "Extintor de Incendios",
    },
    SprinklerText: {
        "en-US": "Sprinkler",
        "es-US": "Aspersor",
    },
    FeetFromHydrantText: {
        "en-US": "Feet from Hydrant",
        "es-US": "Pies de Hidrante",
    },
    WoodStoveInsertsText: {
        "en-US": "Wood Stove Inserts",
        "es-US": "Insertos Para Estufas de Leña",
    },
    HearthsText: {
        "en-US": "Hearths",
        "es-US": "Hogares",
    },
    ChimneysText: {
        "en-US": "Chimneys",
        "es-US": "Chimeneas",
    },
    PreFabsText: {
        "en-US": "Pre-Fabs",
        "es-US": "Prefabricados",
    },
    RenovationsText: {
        "en-US": "Renovations",
        "es-US": "Renovaciones",
    },
    WiringText: {
        "en-US": "Wiring",
        "es-US": "Alambrado",
    },
    PlumbingText: {
        "en-US": "Plumbing",
        "es-US": "Plomería",
    },
    HeatingText: {
        "en-US": "Heating",
        "es-US": "Calefacción",
    },
    RoofText: {
        "en-US": "Roof",
        "es-US": "Techo",
    },
    YearText: {
        "en-US": "Year",
        "es-US": "Año",
    },
    CodeText: {
        "en-US": "Code",
        "es-US": "Código",
    },
    PurchasePriceText: {
        "en-US": "Purchase Price",
        "es-US": "Precio de Compra",
    },
    PurchaseDateText: {
        "en-US": "Purchase Date",
        "es-US": "Fecha de Compra",
    },
    TotalLivingAreaText: {
        "en-US": "Total Living Area",
        "es-US": "Área Habitable Total",
    },
    GarageAreaText: {
        "en-US": "Garage Area",
        "es-US": "Área de Garaje",
    },
    BasementAreaText: {
        "en-US": "Basement Area",
        "es-US": "Área del Sótano",
    },
    YearBuiltText: {
        "en-US": "Year Built",
        "es-US": "Año de Construcción",
    },
    ConstructionTypeText: {
        "en-US": "Construction Type",
        "es-US": "Tipo de Construcción",
    },
    MakeText: {
        "en-US": "Make",
        "es-US": "Hacer",
    },
    ModelText: {
        "en-US": "Model",
        "es-US": "Modelo",
    },
    InParkText: {
        "en-US": "In Park",
        "es-US": "En el Parque",
    },
    ParkNameText: {
        "en-US": "Park Name",
        "es-US": "Nombre del Parque",
    },
    RoofTypeText: {
        "en-US": "Roof Type",
        "es-US": "Tipo de Techo",
    },
    HeatSourceText: {
        "en-US": "Heat Source",
        "es-US": "Fuente de Calor",
    },
    NumberOfUnitsText: {
        "en-US": "Number of Units",
        "es-US": "Número de Unidades",
    },
    StoriesText: {
        "en-US": "Stories",
        "es-US": "Plantas",
    },
    NumberOfRoomsText: {
        "en-US": "Number of Rooms",
        "es-US": "Número de Habitaciones",
    },
    NumberOfFamiliesText: {
        "en-US": "Number of Families",
        "es-US": "Número de Familias",
    },
    SwimmingPoolText: {
        "en-US": "Swimming Pool",
        "es-US": "Piscina",
    },
    TrampolineText: {
        "en-US": "Trampoline",
        "es-US": "Trampolín",
    },
    AnimalsText: {
        "en-US": "Animals",
        "es-US": "Animales",
    },
    TypeText: {
        "en-US": "Type",
        "es-US": "Tipo",
    },
    UnderwritingText: {
        "en-US": "Underwriting",
        "es-US": "Suscripción",
      },
};