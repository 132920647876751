import React from 'react';

export default function DashboardServiceCustomerNotesIcon({ className, viewBox, fill }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox={viewBox ? `${viewBox}` : "0 0 100 100"}
            className={className ? `${className}` : "w-6 h-6 text-primary mr-2"}
            fill={fill ? `${fill}` : "currentColor"}
            preserveAspectRatio="xMidYMid meet"
        >
            <defs>
                <style>
                </style>
            </defs>
            <path d="M12.4,49h47.3c.7,0,1.3-.6,1.3-1.3s-.6-1.3-1.3-1.3H12.4c-.7,0-1.3.6-1.3,1.3s.6,1.3,1.3,1.3ZM22.2,21.7h27.8c.7,0,1.3-.6,1.3-1.3s-.6-1.3-1.3-1.3h-27.8c-.7,0-1.3.6-1.3,1.3s.6,1.3,1.3,1.3ZM12.4,30.8h47.3c.7,0,1.3-.6,1.3-1.3s-.6-1.3-1.3-1.3H12.4c-.7,0-1.3.6-1.3,1.3s.6,1.3,1.3,1.3ZM12.4,39.9h47.3c.7,0,1.3-.6,1.3-1.3s-.6-1.3-1.3-1.3H12.4c-.7,0-1.3.6-1.3,1.3s.6,1.3,1.3,1.3ZM97.1,41.7l-5.3-5.3c-.5-.5-1.4-.5-1.9,0l-20.2,20.2V8.3c0-.7-.6-1.3-1.3-1.3H3.8c-.7,0-1.3.6-1.3,1.3v83.4c0,.7.6,1.3,1.3,1.3h64.5c.7,0,1.3-.6,1.3-1.3v-20.3c0-.1,0-.2,0-.3l24.4-24.4s0,0,0,0l3.1-3.1c.5-.5.5-1.4,0-1.9ZM67,90.3H5.1V9.7h61.8v49.6l-10.3,10.3c-.1.1-.2.3-.3.4,0,0,0,0,0,0,0,0,0,0,0,0l-2.4,7.7c-.2.5,0,1,.3,1.4.3.3.6.4.9.4s.3,0,.4,0l7.7-2.4s0,0,0,0c0,0,0,0,0,0,.1,0,.3-.1.4-.3l3.1-3.1v16.6ZM58.3,73l2.2,2.2-3.2,1,1-3.2ZM63,73.9l-3.4-3.4,28.2-28.2,3.4,3.4-28.2,28.2ZM93,43.8l-3.4-3.4,1.2-1.2,3.4,3.4-1.2,1.2ZM60.3,82.4h-11.8c-.7,0-1.3.6-1.3,1.3s.6,1.3,1.3,1.3h11.8c.7,0,1.3-.6,1.3-1.3s-.6-1.3-1.3-1.3ZM12.4,58.1h47.3c.7,0,1.3-.6,1.3-1.3s-.6-1.3-1.3-1.3H12.4c-.7,0-1.3.6-1.3,1.3s.6,1.3,1.3,1.3ZM28.4,64.6H12.4c-.7,0-1.3.6-1.3,1.3s.6,1.3,1.3,1.3h16c.7,0,1.3-.6,1.3-1.3s-.6-1.3-1.3-1.3Z" />
        </svg>
    );
}