import React from "react";
import { 
  DashboardServiceAllPoliciesIcon, 
  DashboardServiceAutoIcon, 
  DashboardServiceCommercialAutoIcon, 
  DashboardServiceCommercialPropertyIcon, 
  DashboardServiceHomeIcon,
  DashboardServiceUmbrellaIcon
} from "components/Icons/index";

const PolicyFilters = ({
  activePolicyFilter,
  setActivePolicyFilter,
  isHomePolicyAvailable,
  isAutoPolicyAvailable,
  isCommercialPropertyPolicyAvailable,
  isCommercialAutoPolicyAvailable,
  isUmbrellaPolicyAvailable,
}) => {
  const FilterButton = ({
    isActive,
    icon: Icon,
    onClickHandler,
    isDisabled,
  }) => {
    const activeButtonClass = isActive ? "bg-primary" : "bg-white";
    const activeIconClass = isActive ? "text-white" : "text-black";
    const disabledButtonClass = isDisabled
      ? "opacity-50 cursor-not-allowed"
      : "";

    return (
      <div
        className={`w-24 h-24 p-2 mr-2 flex items-center justify-center rounded-md ${activeButtonClass} ${disabledButtonClass} cursor-pointer`}
        onClick={!isDisabled ? onClickHandler : undefined}
      >
        <Icon className={`w-16 h-16 m-auto ${activeIconClass}`} />
      </div>
    );
  };

  const filters = [
    { icon: DashboardServiceAllPoliciesIcon, isDisabled: false },
    { icon: DashboardServiceHomeIcon, isDisabled: !isHomePolicyAvailable },
    { icon: DashboardServiceAutoIcon, isDisabled: !isAutoPolicyAvailable },
    { icon: DashboardServiceUmbrellaIcon, isDisabled: !isUmbrellaPolicyAvailable },
    {
      icon: DashboardServiceCommercialPropertyIcon,
      isDisabled: !isCommercialPropertyPolicyAvailable,
    },
    { icon: DashboardServiceCommercialAutoIcon, isDisabled: !isCommercialAutoPolicyAvailable },
  ];

  return (
    <div className="flex">
      {filters.map((filter, index) => (
        <FilterButton
          key={index}
          icon={filter.icon}
          isActive={index === activePolicyFilter}
          onClickHandler={() => setActivePolicyFilter(index)}
          isDisabled={filter.isDisabled}
        />
      ))}
    </div>
  );
};

export default PolicyFilters;
