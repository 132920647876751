import React from "react";


import { Link } from 'react-router';

const SideBarItem = (props) => {
  const { link, isExpanded } = props;

  return (
    <Link
      key={link.name}
      to={link.href}
      className="text-white flex items-center py-4 px-4 hover:bg-gray-900 transition-colors duration-200"
    >
      <div className="text-2xl">{link.icon}</div>
      {isExpanded && <span className="ml-4 text-lg">{link.name}</span>}
    </Link>
  );
};

export default function SideBar(props) {
  const { isExpanded, navLinks, coreLinks } = props;

  return (
    <div
      className={`bg-secondary ${
        isExpanded ? "w-52" : "w-16"
      } transition-all duration-300 flex flex-col justify-between mt-14 fixed`}
      style={{
        height: `calc(100vh - 5rem)`,
      }}
    >
      <div className="flex flex-col">
        {navLinks.map((link) => (
          <SideBarItem key={link.name} isExpanded={isExpanded} link={link} />
        ))}
      </div>
      <div className="flex flex-col">
        {coreLinks.map((link) => (
          <SideBarItem key={link.name} isExpanded={isExpanded} link={link} />
        ))}
      </div>
    </div>
  );
}
