import React from "react";
import { Link } from "react-router";

export default function Notes({ notes = [] }) {
  return (
    <div>
      {notes.map((note) => (
        <Link key={note.id} to={`/notes/${note.id}`} className="block">
          <div className="grid grid-cols-12 group">
            <div className="col-span-1">
              <span className="text-xs group-hover:underline">
                {note.title}
              </span>
            </div>
            <div className="col-span-2">
              <span className="text-xs group-hover:underline">
                {note.createdAt}
              </span>
            </div>
            <div className="col-span-9">
              <span className="text-xs group-hover:underline">{note.note}</span>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}
