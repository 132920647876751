/* eslint-disable max-len */
export default {
  HeadingText: {
    "en-US": "Service Dashboard",
    "es-US": "Tablero de Servicio",
  },
  CustomerIDText: {
    "en-US": "Customer ID",
    "es-US": "ID del Cliente",
  },
  AccountSummaryText: {
    "en-US": "Account Summary",
    "es-US": "Resumen de la Cuenta",
  },
  ActionsText: {
    "en-US": "Actions",
    "es-US": "Acciones",
  },
  AccordFormsText: {
    "en-US": "Accord Forms",
    "es-US": "Formularios Accord",
  },
  AddLogNoteText: {
    "en-US": "Add log note",
    "es-US": "Agregar nota de registro",
  },
  AddTagText: {
    "en-US": "Add tag",
    "es-US": "Agregar etiqueta",
  },
  AddPolicyText: {
    "en-US": "Add policy",
    "es-US": "Agregar política",
  },
  CompletedTasksText: {
    "en-US": "Completed Tasks",
    "es-US": "Tareas Completadas",
  },
  TodaysTasksText: {
    "en-US": "Today's Tasks",
    "es-US": "Tareas de Hoy",
  },
  CalendarText: {
    "en-US": "Calendar",
    "es-US": "Calendario",
  },
  ActivePoliciesText: {
    "en-US": "Active Policies",
    "es-US": "Pólizas Activas",
  },
  PremiumPerPolicyText: {
    "en-US": "Premium per Policy",
    "es-US": "Prima por Póliza",
  },
  TotalPremiumText: {
    "en-US": "Total Premium",
    "es-US": "Prima Total",
  },
  HomePolicyText: {
    "en-US": "Home",
  },
  PolicyNumberText: {
    "en-US": "Policy Number",
  },
  MoreDetailsButtonText: {
    "en-US": "More Details",
  },
  CloseButtonText: {
    "en-US": "Close",
  },
  NotesText: {
    "en-US": "Notes",
  },
  AddEditPersonalInfoText: {
    "en-US": "Add/Edit Personal Info",
    "es-US": "Agregar/Editar Información Personal",
  },
  AddNoteText: {
    "en-US": "Add Note",
    "es-US": "Agregar Nota",
  },
};
