import React from "react";

export default function TruncatorText({ fieldName, children, cutOff }) {
  const text =
    children && typeof children !== "string" ? children.toString() : children;
  const shouldTruncate = text && text.length > cutOff;

  return (
    <span
      className={`block ${shouldTruncate ? "truncate" : ""} max-w-full`}
      style={{ maxWidth: shouldTruncate ? `${cutOff}ch` : "none" }}
      title={shouldTruncate ? `${fieldName}: ${text}` : ""}
    >
      {shouldTruncate ? `${text.slice(0, cutOff)}...` : text}
    </span>
  );
}
