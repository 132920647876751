import axios from "axios";

export function setTokenHeader(token) {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
}

export async function apiCall(method, url, data = null) {
  try {

    const token = axios.defaults.headers.common["Authorization"];
    const response = await axios.request({
      method,
      url,
      baseURL: process.env.REACT_APP_API_URL || "http://localhost:5000",
      data,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...(token ? { Authorization: `Bearer ${token.replace("Bearer ", "")}` } : {}),
      },
    });

    return response.data;
  } catch (error) {
    throw error.response?.data || error.message || "Unknown error occurred";
  }
}