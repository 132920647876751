import React from 'react';

export default function DashboardServiceAutoIcon({ className, viewBox, fill }) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox={viewBox ? `${viewBox}` : "0 0 100 100"}
      className={className ? `${className}` : "w-6 h-6 text-primary mr-2"}
      fill={fill ? `${fill}` : "currentColor"}
    >
      <defs>
        <style>
        </style>
      </defs>
        <path d="M99,73s0,0,0,0l-1.4-19.4c0-.2-.5-3.8-2.8-6.5.9-.4,1.7-1.1,2.4-1.9,1.4-1.7,2-3.9,1.5-6l-.2-.9c-.7-3.4-3.7-5.9-7.2-5.9h-7.2l-4.1-11.7c-.9-2.7-3.5-4.4-6.3-4.4H26.2c-2.8,0-5.3,1.8-6.3,4.4l-4.1,11.7h-7.2c-3.5,0-6.5,2.5-7.2,5.9l-.2.9c-.4,2.1.2,4.3,1.5,6,.7.8,1.5,1.4,2.4,1.9-2.4,2.7-2.8,6.3-2.8,6.6l-1.4,19.3s0,0,0,0c0,0,0,0,0,0v7c0,2.1,1.7,3.8,3.8,3.8h10.6c2.1,0,3.8-1.7,3.8-3.8v-5.5h61.8v5.5c0,2.1,1.7,3.8,3.8,3.8h10.6c2.1,0,3.8-1.7,3.8-3.8v-7s0,0,0,0ZM22.8,21.6c.5-1.5,1.9-2.4,3.4-2.4h47.6c1.5,0,2.9,1,3.4,2.4l4.2,11.9-8.3,9.4H26.9l-8.3-9.4,4.2-11.9ZM16.1,80.1c0,.4-.3.8-.8.8H4.8c-.4,0-.8-.3-.8-.8v-5.5h12.1v5.5ZM17.6,71.6H4.1l1.2-17.6s.6-4.6,3.8-6.3c.6-.3.9-1,.8-1.7-.2-.7-.8-1.1-1.5-1.1-1.3,0-2.5-.6-3.3-1.6-.8-1-1.2-2.3-.9-3.5l.2-.9c.4-2,2.2-3.5,4.2-3.5h7.6l8.9,10.1c.3.3.7.5,1.1.5h47.5c.4,0,.8-.2,1.1-.5l8.9-10.1h7.6c2.1,0,3.8,1.5,4.2,3.5l.2.9c.2,1.3,0,2.6-.9,3.5-.8,1-2,1.6-3.3,1.6s-1.3.5-1.5,1.1c-.2.7.2,1.4.8,1.7,3.2,1.7,3.8,6.2,3.8,6.2l1.2,17.7H17.6ZM96,80.1c0,.4-.3.8-.8.8h-10.6c-.4,0-.8-.3-.8-.8v-5.5h12.1v5.5ZM21.3,53.3c-2.3-.7-4.3-.7-5.7,0-1.2.6-2.1,1.6-2.5,3.1-.5,1.7-.2,3.5.8,5,.9,1.4,2.4,2.5,4,2.9.7.2,1.4.3,2.1.3,3,0,5.5-1.8,6.3-4.4.5-1.9,0-3.7-1.3-5-.8-.8-2-1.4-3.7-1.9ZM23.4,59.4c-.4,1.4-1.8,2.3-3.4,2.3s-.8,0-1.2-.2c0,0,0,0,0,0-1-.3-1.8-.9-2.4-1.7-.5-.8-.7-1.7-.4-2.6.2-.8.6-1,.9-1.2.3-.1.7-.2,1.3-.2s1.4.1,2.4.4c1.2.3,2,.7,2.4,1.2.6.5.7,1.2.5,2ZM84.5,53.3c-1.5-.7-3.4-.7-5.7,0-1.7.5-2.8,1.1-3.7,1.9-1.4,1.3-1.9,3.1-1.3,5,.8,2.7,3.3,4.4,6.3,4.4s1.4-.1,2.1-.3c1.7-.5,3.1-1.5,4-2.9,1-1.5,1.3-3.3.8-5-.4-1.4-1.3-2.5-2.5-3.1ZM83.7,59.8h0c-.5.8-1.4,1.4-2.4,1.7-.4.1-.8.2-1.2.2-1.6,0-3-.9-3.4-2.3-.2-.8,0-1.5.5-2,.5-.5,1.3-.8,2.4-1.2,1-.3,1.8-.4,2.4-.4s1,.1,1.3.2c.3.1.7.4.9,1.2.2.8,0,1.8-.4,2.6ZM70.4,63.1H29.6c-.8,0-1.5.7-1.5,1.5s.7,1.5,1.5,1.5h40.7c.8,0,1.5-.7,1.5-1.5s-.7-1.5-1.5-1.5Z" />
    </svg>
  );
}
