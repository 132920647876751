import React from "react";
import DashboardWrapper from "layouts/DashboardWrapper";

export default function Dashboard() {
  return (
    <DashboardWrapper>
      <h2>Hello World!</h2>
     
    </DashboardWrapper>
  );
}
