import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./user.slice";
import serviceDashboardReducer from "./serviceDashboard.slice";
import notesReducer from "./notes.slice";
import salesDashboardReducer from "./salesDashboard.slice"

export const store = configureStore({
  reducer: {
    user: userReducer,
    serviceDashboard: serviceDashboardReducer,
    notes: notesReducer,
    salesDashboard: salesDashboardReducer
  },
});
