/* eslint-disable max-len */
export default {
  SearchText: {
    "en-US": "Search",
    "es-US": "Buscar",
  },
  PlaceholderText: {
    "en-US": "Search by name, keyword, number, and more",
    "es-US": "Buscar por nombre, palabra clave, número y más",
  },
};
