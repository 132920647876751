import React from "react";
import langL10n from "./lang-l10n";

import TruncatorText from "components/TruncateText";

const NameInsuredSection = ({ customer, locale }) => (
  <div className="mb-4">
    <h3 className="text-primary font-bold">
      {langL10n.NameInsuredText[locale]}
    </h3>
    <span className="text-xs">
      <TruncatorText fieldName={langL10n.NameInsuredText[locale]} cutOff={20}>
        {customer?.nameInsured}
      </TruncatorText>
    </span>
  </div>
);

const SecondInsuredSection = ({ customer, locale }) => (
  <div className="mb-4">
    <h3 className="text-primary font-bold">
      {langL10n.SecondInsuredText[locale]}
    </h3>
    <span className="text-xs">
      <TruncatorText fieldName={langL10n.SecondInsuredText[locale]} cutOff={20}>
        {customer?.secondInsured}
      </TruncatorText>
    </span>
  </div>
);

const MailAddressSection = ({ customer, locale }) => (
  <div className="mb-4">
    <h3 className="text-primary font-bold">
      {langL10n.MailAddressText[locale]}
    </h3>
    <span className="text-xs">
      <TruncatorText fieldName={langL10n.MailAddressText[locale]} cutOff={30}>
        {customer?.mailingAddress}
      </TruncatorText>
    </span>
  </div>
);

const ContactInfoSection = ({ customer, locale }) => (
  <div className="mb-4">
    <h3 className="text-primary font-bold">
      {langL10n.ContactInfoText[locale]}
    </h3>
    <div className="w-[80%] flex justify-between text-xs mb-1">
      <span>{langL10n.HomePhoneText[locale]}:</span>
      <span>
        <TruncatorText fieldName={langL10n.HomePhoneText[locale]} cutOff={12}>
          {customer?.homePhone}
        </TruncatorText>
      </span>
    </div>
    <div className="w-[80%] flex justify-between text-xs mb-1">
      <span>{langL10n.MobilePhoneText[locale]}:</span>
      <span>
        <TruncatorText fieldName={langL10n.MobilePhoneText[locale]} cutOff={12}>
          {customer?.mobilePhone}
        </TruncatorText>
      </span>
    </div>
    <div className="w-[80%] text-xs mb-1 flex justify-between">
      <span>{langL10n.HomeEmailText[locale]}: </span>
      <TruncatorText fieldName={langL10n.HomeEmailText[locale]} cutOff={14}>
        {customer?.homeEmail}
      </TruncatorText>
    </div>
    <div className="w-[80%] text-xs mb-1 flex justify-between">
      <span>{langL10n.OtherEmailText[locale]}: </span>
      <TruncatorText fieldName={langL10n.OtherEmailText[locale]} cutOff={14}>
        {customer?.otherEmail}
      </TruncatorText>
    </div>
  </div>
);

const PreferredContactSection = ({ customer, locale }) => (
  <div>
    <h3 className="text-primary font-bold mt-4">
      {langL10n.PreferredContactText[locale]}
    </h3>
    <div className="flex items-center">
      <label className="flex items-center mr-4">
        <input
          type="checkbox"
          className="form-checkbox text-primary"
          checked={customer?.preferredMethodOfContact === "phone"}
          readOnly
        />
        <span className="ml-2 text-xs">{langL10n.PhoneText[locale]}</span>
      </label>
      <label className="flex items-center mr-4">
        <input
          type="checkbox"
          className="form-checkbox text-primary"
          checked={customer?.preferredMethodOfContact === "sms"}
          readOnly
        />
        <span className="ml-2 text-xs">{langL10n.SMSText[locale]}</span>
      </label>
      <label className="flex items-center">
        <input
          type="checkbox"
          className="form-checkbox text-primary"
          checked={customer?.preferredMethodOfContact === "email"}
          readOnly
        />
        <span className="ml-2 text-xs">{langL10n.EmailText[locale]}</span>
      </label>
    </div>
  </div>
);

const CustomerCard = ({ customer, locale = "en-US" }) => (
  <div className="max-w-md mx-auto rounded-xl overflow-hidden md:max-w-2xl bg-white">
    <div className="flex">
      <div className="flex-1 mb-4">
        <NameInsuredSection customer={customer} locale={locale} />
        <SecondInsuredSection customer={customer} locale={locale} />
        <MailAddressSection customer={customer} locale={locale} />
      </div>
      <div className="flex-1">
        <ContactInfoSection customer={customer} locale={locale} />
        <PreferredContactSection customer={customer} locale={locale} />
      </div>
    </div>
  </div>
);

export default CustomerCard;
