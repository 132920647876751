import React from "react";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/outline";

import langL10n from "./lang-l10n";

export default function DetailsButton({
  locale = "en-US",
  isExpanded,
  toggleExpand,
}) {
  return (
    <button className="flex items-center gap-1" onClick={toggleExpand}>
      <span
        className={`text-xs ${isExpanded ? "text-black" : "text-secondary"} hover:text-primary`}
      >
        {isExpanded
          ? langL10n.CloseButtonText[locale]
          : langL10n.MoreDetailsButtonText[locale]}
      </span>
      {isExpanded ? (
        <ChevronUpIcon className="w-4 h-4 font-bold text-black " />
      ) : (
        <ChevronDownIcon className="w-4 h-4 font-bold text-secondary" />
      )}
    </button>
  );
}
