import React, { useState } from "react";

import DashboardWrapper from "layouts/DashboardWrapper";
import SearchBar from "components/SearchBar";
import SearchResults from "components/SearchResults";

export default function SearchPage() {
  const [searchQuery, setSearchQuery] = useState("");

  const searchQueryHandler = (incomingSearchQuery) => {
    setSearchQuery(incomingSearchQuery);
  };

  return (
    <DashboardWrapper>
      <SearchBar
        searchQuery={searchQuery}
        searchQueryHandler={searchQueryHandler}
      />
      <SearchResults searchQuery={searchQuery} />
    </DashboardWrapper>
  );
}
