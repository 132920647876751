/* eslint-disable max-len */
export default {
  CommercialPropertyText: {
    "en-US": "Commercial Property",
    "es-US": "Propiedad Comercial",
  },
  PolicyNumberText: {
    "en-US": "Policy Number",
    "es-US": "Número de Póliza",
  },
  CarrierText: {
    "en-US": "Carrier",
    "es-US": "Aseguradora",
  },
  AgentCodeText: {
    "en-US": "Agent Code",
    "es-US": "Código de Agente",
  },
  AccountNumberText: {
    "en-US": "Account #",
    "es-US": "Número de Cuenta",
  },
  TermText: {
    "en-US": "Term",
    "es-US": "Plazo",
  },
  EffectiveDateText: {
    "en-US": "Effective Date",
    "es-US": "Fecha de Inicio",
  },
  ExpirationDateText: {
    "en-US": "Expiration Date",
    "es-US": "Fecha de Expiración",
  },
  StatusText: {
    "en-US": "Status",
    "es-US": "Estado",
  },
  StatusDateText: {
    "en-US": "Status Date",
    "es-US": "Fecha de Estado",
  },
  InceptionDateText: {
    "en-US": "Inception Date",
    "es-US": "Fecha de Inicio",
  },
  CurrentRateText: {
    "en-US": "Current Rate",
    "es-US": "Tarifa Actual",
  },
  AnnualizedRateText: {
    "en-US": "Annualized Rate",
    "es-US": "Tarifa Anualizada",
  },
  PropertyAddressText: {
    "en-US": "Property Address",
    "es-US": "Dirección de la Propiedad",
  },
  CityStateText: {
    "en-US": "City & State",
    "es-US": "Ciudad y Estado",
  },
  ZipCodeText: {
    "en-US": "Zip Code",
    "es-US": "Código Postal",
  },
  CoveragesText: {
    "en-US": "Coverages",
    "es-US": "Coberturas",
  },
  LimitDeductibleText: {
    "en-US": "Limit/Deductible",
    "es-US": "Límite/Deducible",
  },
  PremiumText: {
    "en-US": "Premium",
    "es-US": "Prima",
  },
  DwellingText: {
    "en-US": "Dwelling",
    "es-US": "Vivienda",
  },
  DwellingReplacementText: {
    "en-US": "Dwelling Replacement",
    "es-US": "Reemplazo de Vivienda",
  },
  OtherStructuresText: {
    "en-US": "Other Structures",
    "es-US": "Otras Estructuras",
  },
  PersonalPropertyText: {
    "en-US": "Personal Property",
    "es-US": "Propiedad Personal",
  },
  ContentsReplacementText: {
    "en-US": "Contents Replacement",
    "es-US": "Reemplazo de Contenidos",
  },
  LossOfUseText: {
    "en-US": "Loss of use",
    "es-US": "Pérdida de Uso",
  },
  LiabilityText: {
    "en-US": "Liability",
    "es-US": "Responsabilidad",
  },
  MedicalText: {
    "en-US": "Medical",
    "es-US": "Médico",
  },
  EndorsementsText: {
    "en-US": "Endorsements",
    "es-US": "Endosos",
  },
  EarthquakeText: {
    "en-US": "Earthquake",
    "es-US": "Terremoto",
  },
  DeductibleText: {
    "en-US": "Deductible",
    "es-US": "Deducible",
  },
  CoverageText: {
    "en-US": "Coverage",
    "es-US": "Cobertura",
  },
  IncludeMasonryText: {
    "en-US": "Include Masonry",
    "es-US": "Incluir Albañilería",
  },
  WatercraftText: {
    "en-US": "Watercraft",
    "es-US": "Embarcación",
  },
  SpeedText: {
    "en-US": "Speed",
    "es-US": "Velocidad",
  },
  LengthText: {
    "en-US": "Length",
    "es-US": "Longitud",
  },
  TypeText: {
    "en-US": "Type",
    "es-US": "Tipo",
  },
  EndorsementsScheduledPropertyText: {
    "en-US": "Endorsements & Scheduled Property",
    "es-US": "Endosos y Propiedad Programada",
  },
  DeductiblesText: {
    "en-US": "Deductibles",
    "es-US": "Deducibles",
  },
  LimitText: {
    "en-US": "Limit",
    "es-US": "Límite",
  },
  EndorsementTotalText: {
    "en-US": "Endorsement Total",
    "es-US": "Total de Endosos",
  },
  ScheduledPropertyText: {
    "en-US": "Scheduled Property",
    "es-US": "Propiedad Programada",
  },
  MiscEndorsementsText: {
    "en-US": "Misc. Endorsements",
    "es-US": "Endosos Misceláneos",
  },
  JewelryText: {
    "en-US": "Jewelry",
    "es-US": "Joyas",
  },
  FursText: {
    "en-US": "Furs",
    "es-US": "Pieles",
  },
  GunsText: {
    "en-US": "Guns",
    "es-US": "Armas",
  },
  CamerasText: {
    "en-US": "Cameras",
    "es-US": "Cámaras",
  },
  CoinsText: {
    "en-US": "Coins",
    "es-US": "Monedas",
  },
  StampsText: {
    "en-US": "Stamps",
    "es-US": "Sellos",
  },
  SilverText: {
    "en-US": "Silver",
    "es-US": "Plata",
  },
  FineArtText: {
    "en-US": "Fine Art",
    "es-US": "Arte Fino",
  },
  GolfText: {
    "en-US": "Golf",
    "es-US": "Golf",
  },
  MusicalText: {
    "en-US": "Musical",
    "es-US": "Musical",
  },
  ElectronicsText: {
    "en-US": "Electronics",
    "es-US": "Electrónica",
  },
  WindHailDeductibleText: {
    "en-US": "Wind/Hail Deductible",
    "es-US": "Deducible por Viento/Granizo",
  },
  TheftDeductibleText: {
    "en-US": "Theft Deductible",
    "es-US": "Deducible por Robo",
  },
  TotalPremiumText: {
    "en-US": "Total Premium",
    "es-US": "Prima Total",
  },
  ScheduledText: {
    "en-US": "Scheduled",
    "es-US": "Programado",
  },
  BREMText: {
    "en-US": "BREM",
    "es-US": "BREM",
  },
  PersonalInjuryText: {
    "en-US": "Personal Injury",
    "es-US": "Lesiones Personales",
  },
  WaterBackupOfSewerText: {
    "en-US": "Water Backup of Sewer",
    "es-US": "Respaldo de Agua del Alcantarillado",
  },
  IdentifyFraudExpenseCoverageText: {
    "en-US": "Identify Fraud Expense Coverage",
    "es-US": "Cobertura de Gastos por Fraude de Identidad",
  },
  SRVLNText: {
    "en-US": "SRVLN (Deductible: $500)",
    "es-US": "SRVLN (Deducible: $500)",
  },
  WIHALText: {
    "en-US": "WIHAL (Deductible: $500)",
    "es-US": "WIHAL (Deducible: $500)",
  },
  BusinessPropertyAtHomeText: {
    "en-US": "Business Property At Home",
    "es-US": "Propiedad Comercial en Casa",
  },
  BusinessPropertyAwayFromHomeText: {
    "en-US": "Business Property Away From Home",
    "es-US": "Propiedad Comercial Fuera de Casa",
  },
  IncCovCSpecialLiabilityLimitMText: {
    "en-US": "Inc. Cov. C Special Liability Limit - M",
    "es-US": "Inc. Cob. C Límite de Responsabilidad Especial - M",
  },
  IncCovCSpecialLiabilityLimitSText: {
    "en-US": "Inc. Cov. C Special Liability Limit - S",
    "es-US": "Inc. Cob. C Límite de Responsabilidad Especial - S",
  },
  CreditCardForgeryCounterfeitMoneyText: {
    "en-US": "Credit Card Forgery & Counterfeit Money",
    "es-US": "Falsificación de Tarjeta de Crédito y Dinero Falso",
  },
  AdditionalInterestsText: {
    "en-US": "Additional Interests",
    "es-US": "Intereses Adicionales",
  },
  NameText: {
    "en-US": "Name",
    "es-US": "Nombre",
  },
  LOBText: {
    "en-US": "LOB",
    "es-US": "Línea de Negocio",
  },
  AppliesToText: {
    "en-US": "Applies To",
    "es-US": "Aplica a",
  },
  LoanNumberText: {
    "en-US": "Loan #",
    "es-US": "Número de Préstamo",
  },
  PayorText: {
    "en-US": "Payor",
    "es-US": "Pagador",
  },
  PhoneText: {
    "en-US": "Phone",
    "es-US": "Teléfono",
  },
  FAXText: {
    "en-US": "FAX",
    "es-US": "FAX",
  },
  UnderwritingText: {
    "en-US": "Underwriting",
    "es-US": "Suscripción",
  },
};
