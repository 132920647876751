import React from 'react';

export default function DashboardServiceCommercialAutoIcon({ className, viewBox, fill }) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox={viewBox ? `${viewBox}` : "0 0 100 100"}
      className={className ? `${className}` : "w-6 h-6 text-primary mr-2"}
      fill={fill ? `${fill}` : "currentColor"}
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>
        </style>
      </defs>
      <path d="M89.1,56.2c.6-.6.6-1.5,0-2.1-.6-.6-1.5-.6-2.1,0l-2,1.8V6.7c0-2.7-2.2-4.9-4.9-4.9H19.8c-2.7,0-4.9,2.2-4.9,4.9v49.1l-2-1.8c-.6-.6-1.6-.5-2.1,0-.6.6-.5,1.6,0,2.1l4,3.8v15.8c0,4.4,2.6,8.1,6.3,9.9v8.2c0,2.4,1.9,4.3,4.3,4.3h8.3c2.4,0,4.3-1.9,4.3-4.3v-7h23.7v7c0,2.4,1.9,4.3,4.3,4.3h8.3c2.4,0,4.3-1.9,4.3-4.3v-8.1c3.8-1.8,6.5-5.6,6.5-10v-15.2c0-.1,0-.2,0-.4v-.2c0,0,0,0,0,0l4-3.8ZM17.9,6.7c0-1.1.8-1.9,1.9-1.9h60.4c1.1,0,1.9.8,1.9,1.9v41.5l-4.6-18c-1-3.7-4.2-6.1-7.9-6.1H30.5c-3.7,0-7,2.5-8,6.1l-4.6,18.3V6.7ZM81.8,59.1H18.3l7.1-28.1c.6-2.3,2.7-3.9,5.1-3.9h39.1c2.4,0,4.4,1.5,5,3.9l7.1,28.1ZM35.1,93.9c0,.6-.5,1.3-1.3,1.3h-8.3c-.6,0-1.3-.5-1.3-1.3v-7.2c.7.1,1.3.2,2,.2h8.9v7ZM75.7,93.9c0,.6-.5,1.3-1.3,1.3h-8.3c-.6,0-1.3-.5-1.3-1.3v-7h9.1c.6,0,1.2,0,1.8-.2v7.2ZM82.2,75.8c0,4.5-3.7,8.1-8.3,8.1H26.2c-4.6,0-8.3-3.6-8.3-8.1v-13.7h64.2v3.8c0,.2,0,.4.1.5v9.4ZM73.3,70.1h-7c-.8,0-1.5.7-1.5,1.5s.7,1.5,1.5,1.5h7c.8,0,1.5-.7,1.5-1.5s-.7-1.5-1.5-1.5ZM33.7,70.1h-7c-.8,0-1.5.7-1.5,1.5s.7,1.5,1.5,1.5h7c.8,0,1.5-.7,1.5-1.5s-.7-1.5-1.5-1.5Z"/>
    </svg>
  );
}
