import React from "react";

export default function DashboardNavigationMenuHelpIcon({ className, viewBox, fill }) {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox={viewBox ? `${viewBox}` : "0 0 100 100"}
            className={className ? `${className}` : "w-6 h-6 text-primary mr-2"}
            fill={fill ? `${fill}` : "currentColor"}
        >
            <defs>
                <style>
                </style>
            </defs>
            <path
                d="M52,6.7c1.2,0,2.2-1,2.2-2.2s-1-2.2-2.2-2.2h-3.9c-7.7,0-16.2,3.7-22.3,9.7s-7.9,10.6-9.2,16.8c-8.2.9-14.6,8.4-14.6,17.2s6.6,16.6,15.1,17.2h.1c.4,0,.8-.1,1.3-.4,1-.2,1.8-1.1,1.8-2.2v-26.3c0-6.6,3.2-13.9,8.5-19.1,5.2-5.2,12.6-8.5,19.1-8.5h4ZM16.1,58.5c-5.5-1.2-9.6-6.5-9.6-12.6s4.1-11.5,9.6-12.6v25.2ZM68.9,38h0c0,.1.8.1.8.1,6.1,0,11.1-4.8,11.3-11,0-3.1-1-5.8-3-8-2.1-2.2-4.8-3.5-7.9-3.6-3.1,0-5.8,1-8,3-2.2,2.1-3.5,4.8-3.6,7.8-.1,3,1,5.8,3,8,2,2.1,4.5,3.3,7.4,3.5ZM65,21.9h0c1.3-1.2,3-1.9,4.7-1.9h.2c1.8,0,3.5.8,4.7,2.1,1.3,1.4,2,3.1,1.9,4.8,0,1.8-.8,3.6-2.2,4.9-1.3,1.2-3,1.8-4.8,1.8-1.8,0-3.5-.8-4.7-2.1-1.3-1.3-1.9-3.1-1.9-4.9,0-1.8.8-3.5,2.1-4.7ZM50.6,13c-.7.8-.5,2,0,2.7l.9,1.4v-.3l1,1.3c-.3.5-.5,1.1-.7,1.6l-3.1.2c-1,0-1.8.7-2.1,1.7-.4,1.5-.5,3.1-.6,4.6h0c0,1.5,0,3.1.3,4.5.1,1,.9,1.7,1.9,1.8l3.1.4c.2.5.4,1.1.6,1.6l-2.1,2.3c-.7.8-.7,1.8-.2,2.6,1.7,2.6,3.7,4.8,6.1,6.6.8.7,2,.5,2.7,0l2.4-1.9c.5.3,1.1.5,1.6.7l.2,3.1c0,.9.7,1.8,1.7,2.1,1.6.4,3.1.6,4.7.6h.7c1.2,0,2.5,0,3.7-.3,1-.1,1.7-.9,1.8-1.9l.4-3.1c.5-.2,1.1-.4,1.6-.6l2.3,2.1c.8.7,1.8.7,2.6.2,2.6-1.7,4.8-3.7,6.6-6.1.5-.8.5-1.8,0-2.6l-1.9-2.4c.3-.5.5-1.1.7-1.6l3.1-.2c1,0,1.8-.7,2.1-1.7.4-1.5.5-3.1.6-4.6h0c0-1.5,0-3.1-.3-4.5-.1-.9-.9-1.7-1.9-1.8l-3.1-.4c-.2-.5-.4-1.1-.6-1.6l2.1-2.3c.7-.8.7-1.8.2-2.6-1.7-2.6-3.7-4.8-6.1-6.6-.8-.7-2-.5-2.7,0l-2.4,1.9c-.5-.3-1-.5-1.6-.7l-.2-3.1c0-1-.7-1.8-1.7-2.1-1.6-.3-3.1-.5-4.7-.6-1.4,0-3,0-4.4.3-1,.1-1.7.9-1.8,1.9l-.4,3.1c-.5.2-1.1.4-1.6.6l-2.3-2.1c-.8-.7-1.8-.7-2.6-.2-2.6,1.7-4.8,3.7-6.6,6.1ZM56.
                9,16.5l-1.7-2.2c1-1.2,2-2.1,3-2.8l2.1,1.8c.7.7,1.7.8,2.5.3.9-.5,2-.9,3.5-1.3.9-.3,1.5-.9,1.7-1.8l.3-2.8h2c.7,0,1.5,0,2.2.2l.2,2.8c0,.9.6,1.7,1.5,2,1.4.5,2.5,1,3.4,1.5.7.5,1.8.4,2.5-.1l2.2-1.7c1.2,1,2.1,2,2.8,3l-1.9,2.1c-.7.7-.8,1.7-.3,2.5.5,1,.9,2.1,1.3,3.5.3.9.9,1.5,1.8,1.7l2.8.3v2c0,.7,0,1.5-.2,2.2l-2.8.2c-.9,0-1.7.6-2,1.5-.4,1.3-.9,2.5-1.5,3.5-.5.7-.4,1.8.1,2.5l1.8,2.3c-1,1.2-2,2.1-3,2.8l-2.1-1.8c-.7-.7-1.7-.8-2.5-.3-1,.5-2.1.9-3.5,1.3-.9.3-1.5.9-1.7,1.8l-.3,2.8h-2c-.7,0-1.5,0-2.2-.2l-.2-2.9c0-.9-.6-1.7-1.5-2-1.4-.5-2.4-.9-3.4-1.5-.7-.5-1.8-.4-2.5.1l-2.2,1.7c-1.2-1-2.1-2-2.8-3l1.8-2.1c.7-.7.8-1.7.3-2.5-.5-1-.9-2.1-1.3-3.5-.3-.9-.9-1.5-1.8-1.7l-2.8-.3v-2c0-.7,0-1.5.2-2.2l2.8-.2c.9,0,1.6-.6,1.9-1.4h0c0,0,0-.2,0-.2.5-1.4.9-2.5,1.5-3.4.5-.7.4-1.8-.1-2.5ZM96.1,39.8c-.5-.3-1.1-.4-1.7-.2-.6.1-1,.5-1.3,1-.3.5-.4,1.1-.2,1.7.3,1.3.5,2.5.5,3.7,0,6.1-4,11.4-9.5,12.6v-5.5c0-1.2-1-2.2-2.2-2.2s-2.2,1-2.2,2.2v7.6c0,6.8-3.5,15-8.5,19.9s-7.2,4.9-12.9,6.2c-1.1-3.5-4.4-5.9-8.2-5.9s-8.6,3.8-8.6,8.6,3.9,8.6,8.6,8.6,7.5-2.6,8.4-6.8c6.8-1.3,12.3-3.8,15.8-7.5,5.2-5.2,8.9-13.2,9.6-20.5,8-1.2,14-8.5,14-17s-1.3-6.3-1.8-6.5ZM50,93.3c-2.3,0-4.2-1.9-4.2-4.2s1.9-4.2,4.2-4.2,4.2,1.9,4.2,4.2-1.9,4.2-4.2,4.2Z" />
        </svg>
    );
}