import React from "react";

export default function DashboardNavigationMenuPhoneIcon({ className, viewBox, fill }) {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox={viewBox ? `${viewBox}` : "0 0 100 100"}
            className={className ? `${className}` : "w-6 h-6 text-primary mr-2"}
            fill={fill ? `${fill}` : "currentColor"}
        >
            <defs>
                <style>
                </style>
            </defs>
            <path d="M97.1,76.4h0c-.3-1.8-1.4-3.3-3.2-4.4l-18.7-11.1c-.1-.2-.3-.2-.4-.2-.9-.5-1.2-.7-3.2-.7s-3.7.7-5.2,2l-5.4,5.5-.9-.2c-.5,0-1.4-.4-3.1-1.3-1.5-.7-3.3-1.9-5.5-3.5s-4.7-3.9-7.6-6.8c-2.9-2.8-5.1-5.3-6.7-7.5-1.6-2.2-2.8-4-3.5-5.5s-1.2-2.9-1.5-4h0c0-.1,4.7-4.8,4.7-4.8,1.1-1,1.8-2.4,2.1-3.9.4-1.6.2-3.1-.6-4.5L28,5.8h0c-.6-1-1.3-1.7-2.1-2.3-.9-.7-1.6-1.1-3.2-1.1s-3.1.7-4.3,1.9l-12.9,12.9c-.8.7-1.5,1.7-2,2.8s-.8,2.2-1,3.3v2.1c0,1.3,0,3,.4,5.2.3,2.1,1,4.6,1.9,7.5.9,2.9,2.4,6.2,4.2,9.7s4.3,7.4,7.3,11.5c3,4.1,6.8,8.5,11.4,13,5.8,5.8,11.3,10.5,16.4,13.8,5.1,3.4,9.7,5.9,13.8,7.6,4.2,1.7,7.6,2.8,10.5,3.2,2.8.4,4.9.6,6.2.6h1.8c1.1,0,2.2-.5,3.3-1,1.1-.6,2-1.3,2.7-2l12.9-12.8c1.4-1.5,2.1-3.3,1.8-5.3ZM93.5,79.2l-13,12.9c-.5.5-1.1.9-1.8,1.3-.7.4-1.5.6-2.2.7h-1.6c-1.1,0-3.1-.2-5.7-.6-2.6-.4-5.9-1.4-9.7-3-3.9-1.6-8.4-4.1-13.3-7.3-5-3.2-10.3-7.7-15.9-13.4-4.5-4.4-8.2-8.6-11.1-12.6-3-4-5.3-7.7-7.1-11.1-1.8-3.4-3.1-6.5-4-9.2-.9-2.7-1.5-5.1-1.8-7-.4-1.9-.4-3.4-.4-4.4v-1.9h0c0-.7.3-1.4.7-2.2.3-.7.8-1.3,1.3-1.8l12.9-12.9c.6-.6,1.2-.9,1.9-.9s.8.1,1.2.4c.5.3.9.8,1.2,1.3l10.6,19.8c.3.7.5,1.4.3,2.2-.2.9-.6,1.7-1.2,2.3l-4.8,4.8c-.3.3-.5.8-.7,1.3-.2.5-.2.9-.2,1.3v.3c.3,1.5.9,3.2,1.9,5.1.8,1.6,2,3.6,3.7,5.9s4.1,5,7.1,7.9c3,3,5.7,5.4,8,7.1,2.3,1.7,4.3,3,5.9,3.8,1.6.9,2.9,1.4,3.9,1.6l1.2.2c.4.1.8,0,1.5-.2.6-.2,1-.5,1.3-.8l5.5-5.6c.8-.7,1.9-1.1,2.9-1.1s1.5,0,1.8.3c0,0,.2,0,.3,0l18.5,10.9c1,.6,1.5,1.3,1.7,2.1,0,.9-.2,1.7-.9,2.4Z" />
        </svg>
    );
}