import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { store } from './data'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from "react-router";
import Routes from './config/routes';
import { setAuthorizationToken, setCurrentUser } from 'data/user.slice';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
const root = ReactDOM.createRoot(document.getElementById('InteliForge'));

const cookies = Cookies.get("cas")
console.log(cookies,'cookies');
if (cookies !== undefined) {

  setAuthorizationToken(cookies);
  try {
    store.dispatch(setCurrentUser(jwtDecode(cookies)));
  } catch (e) {
    store.dispatch(setCurrentUser({}));
  }
}

const createRoutes = () => (
  <Router>
      <Routes />
  </Router>
);

root.render(
  <Provider store={store}>
    {createRoutes()}
  </Provider>,
);

reportWebVitals();
