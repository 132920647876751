import React from "react";

export default function DashboardHomeTasksCompletedIcon({ className, viewBox, fill }) {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox={viewBox ? `${viewBox}` : "0 0 100 100"}
            className={className ? `${className}` : "w-6 h-6 text-primary mr-2"}
            fill={fill ? `${fill}` : "currentColor"}
        >
            <defs>
                <style>
                </style>
            </defs>
            <path d="M85.4,7.7h-5.3v-3.4c0-2.7-3-4.5-7.9-4.5s-7.9,1.7-7.9,4.5v3.4h-30.1v-3.4c0-2.7-3-4.5-7.9-4.5s-7.9,1.7-7.9,4.5v3.4h-5.2C6.3,7.7.7,13.4.7,20.4v65.8c0,7,5.6,12.7,12.5,12.7h72c6.9,0,12.5-5.7,12.5-12.7V20.4c0-7-5.6-12.7-12.5-12.7h.2ZM72.3,3.7c2.4,0,3.9.7,4.1.6v12.6c-.2.3-1.7.9-4.1.9s-3.6-.5-4.1-.8V4.6c.2-.3,1.7-.9,4.1-.9ZM26.5,3.7c2.4,0,3.8.7,4.1.6v12.6c-.2.3-1.7.9-4.1.9s-3.6-.5-4.1-.8V4.6c.2-.3,1.7-.9,4.1-.9ZM94.1,86.2c0,4.9-3.9,8.8-8.7,8.8H13.4c-4.8,0-8.7-4-8.7-8.8v-51.1h89.3v51.1h0ZM94.1,31.2H4.7v-10.8c0-4.9,3.9-8.8,8.7-8.8h5.2v5.7c0,2.7,3,4.5,7.9,4.5s7.9-1.7,7.9-4.5v-5.7h30.1v5.7c0,2.7,3,4.5,7.9,4.5s7.9-1.7,7.9-4.5v-5.7h5.3c4.8,0,8.7,4,8.7,8.8v10.8h-.2ZM26.4,74.1c.3,1.4.6,2.6,1.1,3.8.5,1.2,1.2,2.3,2,3.2.9.9,2,1.6,3.3,2.2,1.3.5,2.9.8,4.7.8s3.4-.3,4.7-.8,2.4-1.2,3.2-2.2c.9-.9,1.5-2,2-3.2s.9-2.5,1.1-3.8c.3-1.4.4-2.7.5-4.2v-8.2c0-1.4-.2-2.8-.5-4.2s-.6-2.6-1.1-3.9c-.5-1.2-1.2-2.3-2-3.2-.9-.9-2-1.6-3.3-2.2-1.3-.5-2.9-.8-4.7-.8s-3.4.3-4.7.8-2.4,1.3-3.3,2.2-1.5,2-2,3.2-.9,2.5-1.1,3.9c-.3,1.4-.4,2.7-.5,4.2v8.2c0,1.4.2,2.8.5,4.2h0ZM30.3,62.8c0-1.1,0-2.1.2-3.2.1-1.1.4-2.1.6-3.1.3-1,.7-1.9,1.2-2.7.5-.8,1.2-1.4,2.1-1.9s1.8-.7,3-.7,2.2.2,3,.7,1.5,1.1,2.1,1.9c.5.8,1,1.7,1.2,2.7.3,1,.5,2.1.6,3.1,0,1.1.2,2.1.2,3.2v7.4c0,1.7-.3,3.3-.8,4.7-.4,1.5-1.2,2.7-2.2,3.7s-2.4,1.5-4.2,1.5-3.2-.5-4.2-1.5-1.7-2.3-2.2-3.7c-.4-1.5-.7-3-.8-4.7,0-1.7-.1-3.2-.1-4.5v-2.9h.4,0ZM65,83.5h4.2v-36h-3.2c-.2,1.4-.7,2.5-1.3,3.4-.6.9-1.4,1.6-2.3,2.1-.9.5-1.9.9-3.1,1-1.1.2-2.3.3-3.5.3v3.5h9.2v25.8h0Z" />
        </svg>
    );
}