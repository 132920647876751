import { createSlice } from "@reduxjs/toolkit";

import { fetchNote } from "./actions";

const initialState = {
  note: null,
  loading: false,
  error: null,
};

export const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    setNote: (state, action) => {
      state.note = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNote.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNote.fulfilled, (state, action) => {
        console.log(action.payload);
        state.loading = false;
        state.note = action.payload;
      })
      .addCase(fetchNote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setNote } = notesSlice.actions;
// Selectors
export const selectNote = (state) => state.notes.note;

export default notesSlice.reducer;
