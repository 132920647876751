import React from "react";

export default function DashboardNavigationMenuSearchIcon({ className, viewBox, fill }) {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox={viewBox ? `${viewBox}` : "0 0 100 100"}
            className={className ? `${className}` : "w-6 h-6 text-primary mr-2"}
            fill={fill ? `${fill}` : "currentColor"}
        >
            <defs>
                <style>
                </style>
            </defs>
            <path d="M39.7,15.1c-13.6,0-24.7,11.1-24.7,24.7s1,2.2,2.2,2.3c.2.3.5,1.4.6,2,.3,1.2.5,1.8,1,1.8s0,0,0,0c.5,0,.7,0,.7-6.1,0-11.1,9-20.1,20.1-20.1s2.2-.9,2.3-2.1c.4-.2,1.7-.5,2.5-.7,1.7-.4,2.2-.5,2.1-1.1s0-.7-6.9-.7h.1ZM95.4,84.4l-24.4-24.4c3.8-5.8,6-12.8,6-20.3,0-20.6-16.7-37.3-37.3-37.3S2.4,19.2,2.4,39.8s16.7,37.3,37.3,37.3c16.1-.2,20.9-5.8,20.9-5.8l23.9,23.9c1.4,1.5,3.3,2.3,5.4,2.3h.3c1.9-.2,3.8-1,5.1-2.3,1.4-1.4,2.2-3.2,2.3-5.3,0-2.1-.8-4-2.2-5.5ZM39.7,72.5c-18,0-32.7-14.7-32.7-32.7S21.7,7.1,39.7,7.1s32.7,14.7,32.7,32.7-14.7,32.7-32.7,32.7ZM91.9,91.9c-1.1,1.1-3.1,1.1-4.2,0l-23.3-23.3,4.2-4.2,23.3,23.3c1.1,1.2,1.1,3.1,0,4.2Z" />
        </svg>
    );
}