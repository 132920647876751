import React from "react";

export default function DashboardNavigationMenuAddIcon({ className, viewBox, fill }) {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox={viewBox ? `${viewBox}` : "0 0 100 100"}
            className={className ? `${className}` : "w-6 h-6 text-primary mr-2"}
            fill={fill ? `${fill}` : "currentColor"}
        >
            <defs>
                <style>
                </style>
            </defs>
            <path d="M2.5,16.9h14.4V2.5H2.5v14.4ZM2.5,57.2h14.4v-14.4H2.5v14.4ZM57.2,2.5h-14.4v14.4h14.4V2.5ZM2.5,97.5h14.4v-14.4H2.5v14.4ZM52.7,31.9h-5.4v16.4h-16.7v5.5h16.7v16.4h5.4v-16.4h16.6v-5.5h-16.6v-16.4ZM42.8,97.5h14.4v-14.4h-14.4v14.4ZM83.1,2.5v14.4h14.4V2.5h-14.4ZM83.1,57.2h14.4v-14.4h-14.4v14.4ZM83.1,97.5h14.4v-14.4h-14.4v14.4Z" />
        </svg>
    );
}