import React from 'react';

export default function DashboardServiceCommercialPropertyIcon({ className, viewBox, fill }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox={viewBox ? `${viewBox}` : "0 0 100 100"}
            className={className ? `${className}` : "w-6 h-6 text-primary mr-2"}
            fill={fill ? `${fill}` : "currentColor"}
            preserveAspectRatio="xMidYMid meet"
        >
            <defs>
                <style>
                </style>
            </defs>
            <path d="M91.6,92.3v-43.2c-.9.3-1.8.6-2.8.7v42.5h-6.9v-35.4h-26.3v35.4H11.2v-42.5c-1-.1-1.9-.4-2.8-.7v43.2H2.5v2.8h95v-2.8h-5.9ZM79.2,92.3h-20.7v-32.6h20.7v32.6ZM17.9,7.7h64.3v12.2h2.8V4.8H15.1v15h2.8V7.7ZM70.3,16.9v-2.8H29.7v2.8h40.6ZM64.8,78.3c1.2,0,2.2-1,2.2-2.2s-1-2.2-2.2-2.2-2.2,1-2.2,2.2,1,2.2,2.2,2.2ZM31.3,62.2l-5.1,5.1,2,2,5.1-5.1-2-2ZM35.7,74.8l2,2,3.7-3.7-2-2-3.7,3.7ZM38,64l-9.4,9.4,2,2,9.4-9.4-2-2ZM18,82h32.1v-25H18v25ZM20.8,59.8h26.5v19.4h-26.5v-19.4ZM13.2,46.5c3.9,0,7.4-2.2,9.2-5.4,1.8,3.2,5.3,5.4,9.2,5.4s7.4-2.2,9.2-5.4c1.8,3.2,5.3,5.4,9.2,5.4s7.4-2.2,9.2-5.4c1.8,3.2,5.3,5.4,9.2,5.4s7.4-2.2,9.2-5.4c1.8,3.2,5.3,5.4,9.2,5.4,5.9,0,10.6-4.8,10.6-10.6v-12.6H2.6v12.6c0,5.9,4.8,10.6,10.6,10.6ZM79,26.2h15.6v9.8c0,4.3-3.5,7.8-7.8,7.8s-7.8-3.5-7.8-7.8v-9.8ZM60.6,26.2h15.6v9.8c0,4.3-3.5,7.8-7.8,7.8s-7.8-3.5-7.8-7.8v-9.8ZM42.2,26.2h15.6v9.8c0,4.3-3.5,7.8-7.8,7.8s-7.8-3.5-7.8-7.8v-9.8ZM23.8,26.2h15.6v9.8c0,4.3-3.5,7.8-7.8,7.8s-7.8-3.5-7.8-7.8v-9.8ZM5.4,26.2h15.6v9.8c0,4.3-3.5,7.8-7.8,7.8s-7.8-3.5-7.8-7.8v-9.8Z" />
        </svg>
    );
}